@charset "UTF-8";
html{
	margin-top: 0!important;
}
/* =========================================================
  header
========================================================= */
body{
	padding-top: 110px;
	@include sp{
		padding-top: 61px;
	}
}
#header{
	position: relative;
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
&.active .head-wrap .logo a::before{ opacity: 1; }
&.active .head-wrap .logo a img{ opacity: 0; }
.head-wrap{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: #fff;
	padding: 0 30px;
	position: relative;
	@include sp{
		padding: 8px 20px 15px 20px;
	}
	.logo{
		width: 270px;
		transition: 0.4s;
		position: relative;
		z-index: 1;
		padding: 15px 0 40px 0;
		@media screen and (max-width:1140px) {
			width: 230px;
		}
		@media screen and (max-width:960px) {
			width: 170px;
		}
		@include sp{
			width: 170px;
			padding: 0;
		}
		a{
			display: block;
			transition: 0.4s;
			position: relative;
			&::after{
				content: " ";
				position: absolute;
				bottom: -10px;
				left: 0;
				right: 0;
				margin: 0 auto;
				height: 3px;
				width: 0;
				transition: 0.4s;
				background: #02A848;
			}
			&::before{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url('../images/common/logo_ft.png') no-repeat center;
				background-size: cover;
				opacity: 0;
				transition: 0.4s;
			}
			&:hover{ opacity: 1; }
			&:hover::after{
				width: 100%;
			}
		}
	}
	.nav{
		width: calc(100% - 300px);
		text-align: right;
		transition: 0.4s;
		@media screen and (max-width:1140px) {
			width: calc(100% - 230px);
		}
		@media screen and (max-width:960px) {
			width: calc(100% - 170px);
		}
		@include sp{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background: #007130;
			z-index: 0;
			display: none;
			transition: inherit;
			padding: 70px 0 30px 0;
			text-align: center;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
		ul{
			@include sp{
				padding: 0 20px;
			}
			li{
				display: inline-block;
				vertical-align: middle;
				position: relative;
				@include sp{
					display: block;
				}
				& > a{
					display: block;
					color: #007130;
					padding: 30px 20px;
					font-weight: bold;
					position: relative;
					transition: 0.4s;
					@media screen and (max-width:1140px) {
						padding: 30px 15px;
						font-size: 14px;
					}
					@media screen and (max-width:960px) {
						font-size: 12px;
						padding: 30px 10px;
					}
					@include sp{
						color: #fff;
						padding: 20px 10px;
						font-size: 18px;
					}
					&::after{
						content: " ";
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						margin: 0 auto;
						height: 3px;
						width: 0;
						transition: 0.4s;
						background: #02A848;
						@include sp{display: none;}
					}
					&:hover{
						color: #02A848;
						opacity: 1;
						@include sp{ color: #fff; }
					}
					&:hover::after{
						width: 100%;
					}
					&.barNone:hover::after{ width: 0; }
					&.barNone{
						@include sp{
							padding-bottom: 10px;
						}
					}
				}
				&.current > a{
					color: #007130!important;
					@include sp{ color: #fff!important; }
					&::after{
						content: " ";
						position: absolute;
						bottom: 15px;
						left: 0;
						right: 0;
						margin: 0 auto;
						height: 8px;
						width: 8px!important;
						border-radius: 100%;
						background: #007130;
						@include sp{ display: none; }
					}
				}
				&:hover .child{
					animation: fadeIn 0.2s linear forwards;
					display: block;
				}
				.child{
				    position: absolute;
				    top: 100%;
				    left: 50%;
				    transform: translateY(0%) translateX(-50%);
					min-width: 170px;
					width: calc(100% + 50px);
					background: #02A848;
					text-align: center;
					transition: 0.4s;
					opacity: 0;
					display: none;
					@include sp{
						display: block;
						opacity: 1;
						position: inherit;
						top: inherit;
						left: inherit;
						transform: inherit;
						width: 100%;
						min-width: inherit;
						background: none;
					}
					&::after{
						content: " ";
						position: absolute;
						bottom: 100%;
						left: 0;
						right: 0;
						margin: auto;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 7.5px 10px 7.5px;
						border-color: transparent transparent #02A848 transparent;
						@include sp{
							display: none;
						}
					}
					a{
						display: block;
						color: #fff;
						padding: 10px;
						font-size: 16px;
						transition: 0.4s;
						position: relative;
						span{
							font-size: 12px;
						}
						@include sp{
							display: inline-block;
							font-size: 14px;
							&::after{
								content: " ";
								position: absolute;
								top: 0;
								right: -3px;
								bottom: 0;
								margin: auto;
								height: 50%;
								width: 1px;
								background: #fff;
								transform: rotate( 10deg );
							}
							&:last-child::after{ display: none; }
						}
					}
				}
				&.contact{
					@include sp{margin-top: 10px;}
					a{
						color: #fff!important;
						background: #007130;
						border-radius: 4px;
						padding: 10px 20px;
						&.current{
							background: #02A848!important;
						}
						@media screen and (max-width:960px) {
							padding: 10px;
						}
						&::after{ display: none; }
						&:hover{
							background: #02A848;
						}
						@include sp{
							background: #fff!important;
							color: #007130!important;
						}
					}
				}
			}
		}
	}
	.sp-navBtn{
		@include sp{
			display: block;
			width: 32px;
			height: 32px;
			margin-top: 6px;
			position: relative;
			cursor: pointer;
			&.active{
				span{ background: #fff; }
			}
		    span{
		      background: #007130;
		      width: 30px;
		      height: 2px;
		      display: block;
		      border-radius: 100px;
		      transition: 0.4s;

		     &:first-child{
		      position: absolute;
		      top: -20px;
		      right: 0;
		      left: 0;
		      bottom: 0;
		      margin: auto;
		     }
		     &:nth-child(2){
		      position: absolute;
		      top: 0;
		      bottom: 0;
		      right: 0;
		      left: 0;
		      margin: auto;
		     }
		     &:nth-child(3){
		      position: absolute;
		      bottom: -20px;
		      right: 0;
		      top: 0;
		      left: 0;
		      margin: auto;
		     }
		 	}
		 	&.active span{
		 		&:last-child{ opacity: 0; }
		 		&:first-child{
		 			top: 0;
		 			bottom: 0;
		 			transform: rotate(45deg);
		 		}
		 		&:nth-child(3){
		 			top: 0;
		 			right: 6px;
		 			bottom: 0;
		 			transform: rotate(45deg);
		 			background: #fff;
		 		}
		 		&:nth-child(2){
		 			transform: rotate(-45deg);
		 		}
		 	}
		}
	}
}



} // header

/* =========================================================
  footer
========================================================= */
footer{
background: #007130;
padding: 60px 20px 40px 20px;
overflow: hidden;
@include sp{
	padding: 40px 20px 25px 20px;
}

.footer-wrap{
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	text-align: left;
	@include sp{
		display: block;
	}
	.logo{
		max-width: 275px;
		width: 20%;
		transition: 0.4s;
		@media screen and (max-width:1140px) {
			max-width: 170px;
		}
		@include sp{
			max-width: 315px;
			width: 100%;
			margin: 0 auto 20px auto;
		}
		a{
			display: block;
			transition: 0.4s;
			opacity: 1!important;
		}
	}
	.ft-nav{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 57%;
	    text-align: left;
	    padding-top: 10px;
	    @media screen and (max-width:1140px) {
	    	padding-top: 0px;
	    }
	    @include sp{
	    	max-width: 315px;
	    	width: 100%;
	    	display: block;
	    	margin: 0 auto 15px auto;
	    }
	    li{
	    	width: 25%;
	    	@include sp{
	    		width: 100%;
	    		margin-bottom: 0;
	    	}
	    	a{
	    		display: block;
	    		color: #fff;
	    		font-weight: bold;
	    		font-size: 14px;
	    		transition: 0.4s;
	    		padding: 5px 0;
	    		&:hover{
	    			text-decoration: underline;
	    			opacity: 1;
	    		}
	    	}
	    	&.second{
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 50%;
			    text-align: left;
		    	@include sp{
		    		width: 100%;
			    	justify-content: flex-start;
			    	margin-bottom: 10px;
		    	}
			    .left,.right{
			    	width: 50%;
			    	@include sp{
			    		width: 40%;
			    	}
			    	a{
			    		padding: 5px 0 5px 18px;
			    		position: relative;
			    		&::after{
			    			content: " ";
			    			position: absolute;
			    			top: 16px;
			    			left: 0;
			    			width: 8px;
			    			height: 2px;
			    			background: #fff;
			    		}
			    	}
			    }
			    .right{
			    	@include sp{ width: 55%; }
			    }
	    	}
	    	&.third{
	    		@include sp{
	    			text-align: center;
	    		}
	    		a{
	    			display: inline-block;
	    			width: 24px;
	    			margin: 0 8px;
	    			transition: 0.4s;
	    			&:hover{
	    				text-decoration: none;
	    			}
	    		}
	    	}
	    }
	}
	.contact-btn{
		max-width: 160px;
		width: 17%;
		@include sp{
			margin: 0 auto;
			max-width: 315px;
			width: 100%;
		}
		a{
			display: block;
			transition: 0.4s;
			background: #fff;
			border-radius: 4px;
			text-align: center;
			color: #007130;
			font-weight: bold;
			padding: 10px 0;
			&:hover{
				background: #EDFFAF;
				opacity: 1;
			}
		}
	}

	.copyright{
		width: 100%;
		text-align: left;
		color: #fff;
		margin-top: 20px;
		font-size: 12px;
		line-height: 24px;
		font-weight: 400;
		@include sp{ margin-top: 25px; }
		a{
			color: #fff;
			opacity: 1!important;
		}
	}

}


} // footer
#pagetop{
	display: block;
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 56px;
	height: 56px;
	@include sp{
		width: 40px;
		height: 40px;
		bottom: 10px;
		right: 10px;
	}
	a{
		background: #007130;
		border-radius: 100%;
		display: block;
		position: relative;
		text-indent:100%;
		white-space:nowrap;
		overflow:hidden;
		height: 100%;
		border: 2px solid #fff;
		transition: 0.4s;
		&:hover{
			opacity: 1;
			background: #02A848;
		}
		&::after{
			content: " ";
			position: absolute;
			top: 5px;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			width: 11px;
			height: 11px;
			border-radius: 3px;
			transform: rotate(-45deg);
			@include sp{
				width: 13px;
				height: 13px;
				top: 5px;
			}
		}
	}
}
/* =========================================================
  common
========================================================= */
.conts-wrap{
	overflow: hidden;
}
.inner1080{
	max-width: 1080px;
	width: 100%;
	margin: 0 auto;
}
.inner920{
	max-width: 920px;
	width: 100%;
	margin: 0 auto;
}
.subttl{
	color: #007130;
	font-size: 26px;
	line-height: 34px;
	padding-bottom: 20px;
	margin-bottom: 50px;
	position: relative;
	@include sp{
		margin-bottom: 30px;
	}
	&::after{
		content: " ";
		position: absolute;
		width: 40px;
		height: 4px;
		background: #007130;
		border-radius: 50px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}

.btn{
	max-width: 325px;
	width: 100%;
	margin: 0 auto;
	&.btn02{
		a{
			background: #007130;
			color: #fff;
			&:hover{
				background: #02A848;
				color: #fff;
			}
		}
	}
	a{
		display: block;
		border: 2px solid #007130;
		border-radius: 8px;
		text-align: center;
		font-size: 20px;
		line-height: 32px;
		padding: 10px;
		font-weight: bold;
		color: #007130;
		transition: 0.4s;
		&:hover{
			opacity: 1;
			color: #02A848;
			border: 2px solid #02A848;
		}
	}
}
.ac-btn{
	text-align: center;
	a{
		display: inline-block;
		position: relative;
		padding: 0 10px 0 0;
		transition: 0.4s;
		color: #007130;
		font-weight: bold;
		&:hover{
			color: #02A848;
			&::after{
				border-color: #02A848;
			}
		}
		&::after{
			content: " ";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			display: block;
    			width: 10px;
    			height: 10px;
    			transition: 0.4s;
    			border-right: 2px solid #007130;
    			border-bottom: 2px solid #007130;
    			transform: rotate(-45deg);
    		}
		}
	}

.icon-svg {
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}
.icon-ajaile,
.icon-engineer,
.icon-onestop{
	stroke: #007130;
	transition: 0.4s;
}
#loading {
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
 //  &::after{
 //  	content: " ";
 //  	position: absolute;
 //  	top: 0;
 //  	left: 0;
	// width: 100%;
	// height: 100%;
	// background: #006CBA;
	// transition: 1s ease-in-out;
	// @include sp{
	// 	transition: 0.6s ease-in-out;
	// }
 //  }
  .loading-animation{
  	overflow: hidden;
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    @include sp{
      width: 800px;
      top: 50%;
    }
	&::after{
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: #fff;
		transition: 1.7s ease-in-out;
		transition-delay: 2.5s;
		z-index: 1000;
	}
	&::before{
		content: " ";
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		background: #fff;
		transition: 1.7s ease-in-out;
		transition-delay: 2.5s;
		z-index: 1000;
	}
    .white{
    	position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translateY(-50%) translateX(-50%);
	    width: 100%;
	    height: 100%;
	    background: #fff;
	    z-index: 100;
	    transition: 2s ease-in-out;
	    transition-delay: 0.5s;
    }
    .loading-img, .loading-img-hands{
    	position: absolute;
	    top: 0;
	    left: 0;
    }
    .loading-img-hands{
    	opacity: 0;
	    transition: 1s;
	    transition-delay: 2.2s;
	    z-index: 10000;
    }
  }
}
#loading.move{
	.loading-animation{
		animation: key .7s ease alternate;
		animation-delay: 4.3s;
		@include sp{
		animation: keysec .7s ease alternate;
		animation-delay: 4.3s;
		}
		.white{
			width: 0%;
		}
	    .loading-img-hands{
	    	opacity: 1;
	    }
		&::after, &::before{
			width: 40%;
		}
	}
	&::after{
		left: 100%;
	}
}
#loading.move.move02{
	.loading-animation{
		&::after, &::before{
			width: 51%;
			transition: 1s ease-in-out;
			transition-delay: 0;
		}
	}
}
#loading.loaded {
  opacity: 0;
  visibility: hidden;
}

.submv{
	position: relative;
	background: #007130;
	height: 215px;
	.read{
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translateY(-50%) translateX(-50%);
	    width: 100%;
	    padding: 0 20px;
	    color: #fff;
	    font-weight: bold;
	    font-size: 48px;
	    line-height: 48px;
	    span{
	    	font-size: 28px;
	    	font-weight: bold;
	    	letter-spacing: 3px;
	    	@include sp{
	    		display: block;
	    	}
	    }
	}
}


.wp-pagenavi {
  text-align: left;
}
.wp-pagenavi span {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding-top: 10px;
  color: #FFF;
  background: #007130;
  margin: 3px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-variant: top;
  border: 2px solid #007130;
  @include sp{
	  width: 36px;
	  height: 36px;
	  padding-top: 5px;
  }
}
.wp-pagenavi span.extend {
  background: none;
  color: #111;
  border: none;
}
.wp-pagenavi a.page {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 2px solid #007130;
  padding-top: 10px;
  margin: 3px;
  text-align: center;
  transition: 0.4s;
  border-radius: 5px;
  font-weight: bold;
  color: #007130;
  font-variant: top;
  @include sp{
	  width: 36px;
	  height: 36px;
	  padding-top: 5px;
  }
}
.wp-pagenavi a.first:hover,
.wp-pagenavi a.last:hover,
.wp-pagenavi a.last:hover,
.wp-pagenavi a.nextpostslink:hover,
.wp-pagenavi a.previouspostslink:hover,
.wp-pagenavi a.page:hover {
  color: #FFF;
  background: #02A848;
  opacity: 1;
  border-color: #02A848;
  &::after{
  	border-color: #fff;
  }
}
.wp-pagenavi a.previouspostslink {
  padding-right: 10px;
  transition: 0.4s;
}
.wp-pagenavi a.nextpostslink {
  padding-left: 10px;
  transition: 0.4s;
}
.wp-pagenavi a.nextpostslink,
.wp-pagenavi a.previouspostslink{
	position: relative;
	width: 48px;
	height: 48px;
	border: 2px solid #007130;
	display: inline-block;
	border-radius: 5px;
	padding: 0;
	vertical-align: top;
	margin: 3px 0px;
	transition: 0.4s;
	&::after{
		content: " ";
		display: block;
	    position: absolute;
	    top: 50%;
	    left: 46%;
	    transform: translateY(-50%) translateX(-50%) rotate(45deg);
		width: 10px;
		height: 10px;
		border-top: 2px solid #007130;
		border-right: 2px solid #007130;
		transition: 0.4s;
		@include sp{
			width: 8px;
			height: 8px;
		}
	}
	@include sp{
		height: 36px;
		width: 36px;
	}
}
.wp-pagenavi a.first,
.wp-pagenavi a.last{
	position: relative;
	width: auto;
	height: 48px;
	padding-right: 20px;
	border: 2px solid #007130;
	display: inline-block;
	border-radius: 5px;
	padding: 0;
	vertical-align: top;
	margin: 3px 0px;
	transition: 0.4s;
	color: #007130;
	width: 75px;
	font-weight: bold;
	padding: 9px 0 0 10px;
	&::after{
		content: " ";
		display: block;
	    position: absolute;
	    top: 50%;
	    left: 75%;
	    transform: translateY(-50%) translateX(-50%) rotate(45deg);
		width: 10px;
		height: 10px;
		border-top: 2px solid #007130;
		border-right: 2px solid #007130;
		transition: 0.4s;
		@include sp{
			width: 8px;
			height: 8px;
		}
	}
	@include sp{
		height: 36px;
		padding-top: 4px;
		font-size: 15px;
	}
}
.wp-pagenavi a.first{
	padding: 9px 10px 0 0;
	text-align: right;
	&::after{
		left: 25%;
		transform: translateY(-50%) translateX(-50%) rotate(-135deg);
	}
}







/* CASE
---------------------------*/
.case{
	padding: 0 20px;
	margin-bottom: 100px;
	@include sp{
		margin-bottom: 80px;
	}
	.case-list{
		margin: 0 auto 10px auto;
		display: block;
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    width: 100%;
	    justify-content: center;
	    @include sp{
	    	
	    	margin-bottom: 10px;
	    }
	    li{
	    	width: 13%;
	    	margin-right: calc(9% / 6);
	    	&:last-child,
	    	&:nth-child(7n){ margin-right: 0; }
	    	@include sp{
	    		width: 30%;
	    		margin-bottom: 20px;
	    		margin-right: 5%;
	    		&:nth-child(7n){ margin-right: 5%; }
	    		&:last-child,
	    		&:nth-child(3n){ margin-right: 0; }
	    	}
	    	a{
	    		display: block;
	    		height: 100%;
	    		transition: 0.4s;
	    	}
	    	.thum{
	    		transition: 0.4s;
	    		position: relative;
	    		overflow: hidden;
	    		margin-bottom: 15px;
	    		.thum-img{
	    			width: 100%;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translateY(-50%) translateX(-50%);
	    		}
	    	}
	    	.title{
	    		color: #007130;
	    		font-size: 12px;
	    		line-height: 20px;
	    		transition: 0.4s;
	    	}
	    }
	}
	.ac-btn{
		margin-bottom: 40px;
		a{
			font-size: 20px;
		}
	}
	.bt-txt{
		text-align: center;
		color: #007130;
		font-weight: bold;
		margin-bottom: 40px;
		@include sp{
			margin-bottom: 20px;
		}
	}
}




/* BLOG
---------------------------*/
.blog{
	padding: 0;
	margin-bottom: 100px;
	@include sp{
		margin-bottom: 80px;
	}
	.inner1080{
		position: relative;
		z-index: 0;
		padding: 80px 0 0 0;
		&::after{
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 230px;
			background: #007130;
			z-index: -1;
		}
		@include sp{
			padding-top: 70px;
		}
		.subttl{
			color: #fff;
			&::after{ background: #fff; }
		}
	}
	.blog-list{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: calc(100% - 40px);
	    text-align: left;
	    margin-bottom: 40px;
	    @include sp{
	    	display: block;
	    	margin-bottom: 30px;
	    }
	    li{
	    	width: 22%;
	    	margin: 0 3% 3% 0;
	    	&:nth-child(4n){ margin-right: 0; }
	    	@include sp{
	    		width: 100%;
	    		margin: 0 0 30px 0;
	    		&:last-child{ margin-bottom: 0; }
	    	}
	    	a{
	    		display: block;
	    		transition: 0.4s;
	    		color: #007130;
	    		height: 100%;
	    		&:hover{
	    			opacity: 1;
	    			.title{
	    				text-decoration: none;
	    				color: #02A848;
	    			}
	    			.thum{
	    				box-shadow: 3px 3px 0px 0px #02A848;
	    				.cate{
	    					background: #02A848;
	    				}
	    			}
	    		}
	    	}
	    	.thum{
	    		transition: 0.4s;
	    		position: relative;
	    		overflow: hidden;
	    		border-radius: 5px;
	    		box-shadow: 3px 3px 0px 0px #007130;
	    		margin-bottom: 15px;
	    		background: #f5f5f5;
	    		@include sp{
	    		}
	    		.thum-img{
	    			width: 100%;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translateY(-50%) translateX(-50%);
	    		}
	    		.cate{
	    			transition: 0.4s;
	    			display: inline-block;
	    			position: absolute;
	    			bottom: 0;
	    			right: 0;
	    			color: #fff;
	    			background: #007130;
	    			padding: 0 10px;
	    			font-size: 12px;
	    		}
	    	}
	    	.date{
	    		font-size: 12px;
	    		line-height: 24px;
	    		margin-bottom: 5px;
	    		font-weight: bold;
	    	}
	    	.title{
	    		text-decoration: underline;
	    		font-weight: bold;
	    		transition: 0.4s;
	    	}
	    }
	}
}


.not_found{
	padding: 40px 20px;
	text-align: left;
	.white_bg{
		padding: 50px;
		background: #f5f5f5;
		border-radius: 5px;
		@include sp{
			padding: 30px 20px;
		}
		.ttl02{
			color: #007130;
			margin-bottom: 20px;
		}
	}
}


@keyframes key{
  0% {margin-top: 0px;}
  33% {margin-top: -1%;}
  50% {margin-top: 0.5%;}
  80% {margin-top: -0.5%;}
  100% {margin-top: 0px;}
}
@keyframes keysec{
  0% {margin-top: 0px;}
  33% {margin-top: -2%;}
  50% {margin-top: 1%;}
  80% {margin-top: -1%;}
  100% {margin-top: 0px;}
}