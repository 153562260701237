.pagetypenews{

/* =========================================================
 news
========================================================= */

.main-clm{
	padding: 60px 20px 100px 20px;
    @include sp{
        padding: 50px 20px 60px 20px;
    }
	.inner1080{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: left;
	}
    .left{
    	max-width: 760px;
    	width: 70%;
        @include sp{
            max-width: 100%;
            width: 100%;
        }
        .pegebav_bt{
                display: -webkit-box;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                text-align: center;
                li.next, li.prev{
                    min-width: 85px;
                    a{
                        position: relative;
                        width: auto;
                        padding-right: 20px;
                        display: inline-block;
                        border-radius: 5px;
                        padding: 0;
                        vertical-align: top;
                        margin: 3px 0px;
                        transition: 0.4s;
                        color: #007130;
                        width: 85px;
                        font-weight: bold;
                        padding: 10px 25px 10px 10px;
                        @include sp{
                            font-size: 14px;
                            padding: 7px 20px 7px 10px;
                        }
                        &:hover{
                            color: #fff;
                            background: #02A848;
                            border-color: #02A848;
                            &::after{ border-color: #fff; }
                        }
                        &::after{
                            content: " ";
                            display: block;
                            transition: 0.4s;
                            position: absolute;
                            top: 50%;
                            left: 80%;
                            transform: translateY(-50%) translateX(-50%) rotate(45deg);
                            width: 10px;
                            height: 10px;
                            border-top: 2px solid #007130;
                            border-right: 2px solid #007130;
                            transition: 0.4s;
                            @include sp{
                                width: 8px;
                                height: 8px;
                            }
                        }
                    }
                }
                li.next a{
                    padding: 10px 5px 10px 25px;
                    @include sp{
                        padding: 7px 10px 7px 20px;
                        text-align: center;
                    }
                    &::after{
                        left: 20%;
                        transform: translateY(-50%) translateX(-50%) rotate(-135deg);
                    }
                }
                li.return a{
                    color: #007130;
                    transition: 0.4s;
                    &:hover{
                        color: #02A848;
                    }
                }
        }
    	.press{
    		border-top: 2px solid #007130;
    		padding-top: 40px;
            margin-bottom: 50px;
            &:last-of-type{
                border-bottom: 2px solid #007130;
                padding-bottom: 40px;
            }
    		.head{
    			color: #007130;
    			position: relative;
    			padding-left: 110px;
    			margin-bottom: 30px;
    			.date{
    				font-size: 18px;
    				line-height: 24px;
    				font-weight: bold;
    				position: absolute;
    				top: 3px;
    				left: 0;
    			}
                li{ display: inline-block; }
    			a{
    				color: #fff;
    				display: inline-block;
    				padding: 1px 10px;
    				text-align: center;
    				background: #007130;
    				font-size: 12px;
    				transition: 0.4s;
    				&:hover{
    					opacity: 1;
    					background: #02A848;
    				}
    			}
    		}
    		.title{
    			font-size: 32px;
    			font-weight: bold;
    			line-height: 48px;
    			color: #007130;
    			margin-bottom: 30px;
    			a{
    				transition: 0.4s;
    				color: #007130;
    				text-decoration: underline;
    				&:hover{
    					opacity: 1;
    					text-decoration: none;
    				}
    			}
    		}
    		.wyswyg{
                h1,h2,h3,h4,h5,h6{ color: #007130; }
                &.revival{
                    img{
                        margin: 10px 0;
                    }
                }
                p{
                    padding-bottom: 30px;
                    @include sp{ padding-bottom: 10px; }
                }
                img{ height: auto; width: auto; max-width: 100%; }
    			a{
    				color: #007130;
    				transition: 0.4s;
                    text-decoration: underline;
    				&:hover{
    					opacity: 1;
                        text-decoration: none;
    				}
    			}
                .sns{
                    display: -webkit-box;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    width: 100%;
                    text-align: left;
                    margin-top: 40px;
                }
                .wp_social_bookmarking_light{
                    padding: 0!important;
                }
                a.tw{
                    display: inline-block;
                    background: #1b95e0;
                    border-radius: 3px;
                    padding: 0 8px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 20px;
                    font-size: 11px;
                    text-decoration: none;
                    img{
                        display: inline-block;
                        width: 12px;
                        margin: 0 5px 0 0;
                        vertical-align: middle;
                    }
                }
    		}
    	}
        .sr-btn{
            text-align: left;
            @include sp{
                width: 100%;
                text-align: center;
            }
            a{
                display: inline-block;
                position: relative;
                font-weight: bold;
                padding-left: 20px;
                transition: 0.4s;
                color: #007130;
                &::after{
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    display: block;
                    width: 10px;
                    height: 10px;
                    transition: 0.4s;
                    border-right: 2px solid #007130;
                    border-bottom: 2px solid #007130;
                    transform: rotate(135deg);
                    transition: 0.4s;
                }
                &:hover{
                    color: #02A848;
                    opacity: 1;
                    &::after{
                        border-color: #02A848;
                    }
                }
            }
        }
    }
    .right{
    	max-width: 200px;
    	width: 70%;
        @include sp{
            max-width: 100%;
            width: 100%;
            margin-top: 40px;
        }
    	.sub-block{
    		border-top: 1px solid #007130;
    		padding-top: 20px;
    		margin-bottom: 30px;
            &:last-of-type{
                margin-bottom: 0;
            }
    		.block-title{
    			font-size: 16px;
    			font-weight: bold;
    			line-height: 24px;
    			margin-bottom: 10px;
    			color: #007130;
    		}
    		.sub-column-list-category{
    			li{
    				a{
    					color: #007130;
    					display: block;
    					transition: 0.4s;
    					padding: 3px 0 3px 25px;
    					position: relative;
    					font-weight: bold;
    					&::after{
    						content: " ";
    						position: absolute;
    						top: 12px;
    						left: 5px;
							display: block;
							width: 8px;
							height: 8px;
							border-top: 2px solid #007130;
							border-right: 2px solid #007130;
							transform: rotate(45deg);
							transition: 0.4s;
    					}
    					&:hover{
    						color: #fff;
    						opacity: 1;
    						background: #007130;
    						&::after{
    							border-color: #fff;
    						}
    					}
    				}
    			}
    		}
    		.sub-column-list-new,.sub-column-list-archive{
    			li{
    				a{
    					color: #007130;
    					display: block;
    					transition: 0.4s;
    					padding: 3px 0 3px 20px;
    					position: relative;
    					font-size: 14px;
    					&::after{
    						content: " ";
    						position: absolute;
    						top: 12px;
    						left: 5px;
							display: block;
							width: 5px;
							height: 5px;
							border-top: 1px solid #007130;
							border-right: 1px solid #007130;
							transform: rotate(45deg);
							transition: 0.4s;
    					}
    					&:hover{
    						color: #02A848;
    						opacity: 1;
    						&::after{
    							border-color: #02A848;
    						}
    					}
    				}
    			}
    		}

            .sub-column-list-archive{
                .tgl_area{
                    display: none;
                    padding-left: 20px;
                }
                .a_tgl{
                    color: #007130;
                    display: block;
                    transition: 0.4s;
                    padding: 3px 0 3px 25px;
                    position: relative;
                    font-weight: bold;
                    cursor: pointer;
                        &::after{
                            content: " ";
                            position: absolute;
                            top: 12px;
                            left: 5px;
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid #007130;
                            border-right: 2px solid #007130;
                            transform: rotate(45deg);
                            transition: 0.4s;
                        }
                        &.active-t::after{
                            transform: rotate(135deg);
                        }
                        &:hover{
                            color: #02A848;
                            opacity: 1;
                            &::after{
                                border-color: #02A848;
                            }
                        }
                }
            }
    	}
    }
}



} // news