.pagetypeentry,
.pagetypecontact{


.inner760{
	max-width: 760px;
	width: 100%;
	margin: 0 auto;
	display: block;
}
/* =========================================================
 entry
========================================================= */

.entry-conts{
	padding: 70px 20px 0 20px;
	margin-bottom: 120px;
	@include sp{
		padding-top: 60px;
	}
	.error-text{
		font-size: 14px;
		color: #e41616;
	}
	.read{
		text-align: left;
		font-size: 14px;
		margin-bottom: 60px;
		.cl{
			color: #007130;
			display: block;
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.bt{
			display: block;
			margin-top: 5px;
		}
		.warning{
			display: block;
			color: #e41616;
			margin-top: 5px;
		}
	}
	.tb01{
		border-collapse:collapse;
		margin-bottom: 40px;
		@include sp{
			margin-bottom: 30px;
		}
		tr{
			border-top: 1px solid #007130;
			&:first-child{
				border-width: 4px;
			}
			&:last-child{
				border-bottom: 1px solid #007130;
			}
		}
		th,td{
			vertical-align: middle;
			padding: 30px 0;
			text-align: left;
			@include sp{
				display: block;
			}
		}
		.txtarea{
			th,td{ vertical-align: top; }
		}
		th{
			width: 160px;
			color: #007130;
			@include sp{
				padding: 20px 0 10px 0;
				max-width: 100%;
				width: 100%;
			}
		}
		td{
			max-width: calc(100% - 160px);
			width: 70%;
			@include sp{
				max-width: 100%;
				width: 100%;
				padding: 0 0 20px 0;
			}
			p{
				margin-top: 10px;
				font-size: 14px;
			}
		}
		/*.file{
			position: relative;
			&::after{
				content: "ファイルを選択";
				background: #fff;
				border: 1px solid #ccc;
				padding: 0 8px;
				border-radius: 4px;
				box-shadow: 1px 1px 2px #ccc;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				font-size: 12px;
				pointer-events: none;
			}
		}*/
		textarea{
			width: 100%;
			cursor: pointer;
			appearance: none;
			display: block;
			border-radius: 4px;
			border: 1px solid #CCCCCC;
			outline: none;
			font-size: 16px;
			height: 160px;
			padding: 10px;
		}
		input[type="tel"],
		input[type="email"],
		input[type="text"]{
			width: 100%;
			cursor: pointer;
			appearance: none;
			display: block;
			border-radius: 4px;
			border: 1px solid #CCCCCC;
			outline: none;
			font-size: 16px;
			height: 48px;
			padding: 5px 10px;
		}
		textarea::placeholder,
		input[type="tel"]::placeholder,
		input[type="email"]::placeholder,
		input[type="text"]::placeholder{
			color: #ccc;
		}
		span{ padding: 0 25px 0 10px; }
		.w100, .year, .month, .days{
			position: relative;
			&::after{
				content: " ";
				position: absolute;
				top: -2px;
				right: 15px;
				bottom: 0;
				margin: auto;
				width: 11px;
				height: 11px;
			    border-right: 2px solid #007130;
			    border-bottom: 2px solid #007130;
			    transform: rotate(45deg);
			    pointer-events: none;
			}
			select{
				width: 100%;
				cursor: pointer;
				appearance: none;
				display: block;
				border-radius: 4px;
				border: 1px solid #CCCCCC;
				outline: none;
				font-size: 16px;
				height: 48px;
				padding: 5px 10px;
			}

		}
		.year{
			width: 25%;
			display: inline-block;
			font-variant: middle;
			@include sp{
				width: 40%;
			}
		}
		.month{
			width: 15%;
			display: inline-block;
			font-variant: middle;
			@include sp{
				width: 25%;
				margin-top: 15px;
			}
		}
		.days{
			width: 15%;
			display: inline-block;
			font-variant: middle;
			@include sp{
				width: 25%;
				margin-top: 15px;
			}
		}
	}
	.check-policy{
		text-align: center;
		color: #007130;
		font-size: 14px;
		margin-bottom: 40px;
		@include sp{
			margin-bottom: 30px;
		}
		a{
			text-decoration: underline;
			transition: 0.4s;
			color: #007130;
			&:hover{
				color: #02A848;
			}
		}
		input[type="checkbox"]:checked{
			cursor: pointer;
			background: #007130;
		}
	}
	.submit{
		cursor: pointer;
		display: block;
		appearance: none;
		max-width: 325px;
		width: 100%;
		margin: 0 auto;
		background: #007130;
		color: #fff;
		transition: 0.4s;
		border: none;
		border-radius: 8px;
		font-size: 20px;
		line-height: 32px;
		padding: 10px;
		&:hover{
			background: #02A848;
		}
	}
	.submit:disabled{
		background: #ccc;
	}

}


} // entry

.pagetypecontact{
	.entry-conts .read{
		font-size: 16px;
		a{
			color: #007130;
			transition: 0.4s;
			&:hover{
				color: #02A848;
			}
		}
		.bt{ font-weight: 400;
			font-size: 14px;
		}
		.warning{
			font-weight: 400;
			font-weight: bold;
			font-size: 16px;
		}
	}
}

.pagetypeentry{
	.entry-conts .read{
		font-size: 16px;
		a{
			color: #007130;
			transition: 0.4s;
			&:hover{
				color: #02A848;
			}
		}
		.bt{ font-weight: 400;
			font-size: 14px;
		}
		.warning{
			font-weight: 400;
			font-weight: bold;
			font-size: 16px;
		}
	}
}

@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .pagetypeentry .entry-conts .tb01 .w100::after, .pagetypeentry .entry-conts .tb01 .year::after, .pagetypeentry .entry-conts .tb01 .month::after, .pagetypeentry .entry-conts .tb01 .days::after, .pagetypecontact .entry-conts .tb01 .w100::after, .pagetypecontact .entry-conts .tb01 .year::after, .pagetypecontact .entry-conts .tb01 .month::after, .pagetypecontact .entry-conts .tb01 .days::after { display: none; } /* IE11 */
}
