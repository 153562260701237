@charset "UTF-8";
/* =========================================================
MEDIA
========================================================= */
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@400;600;700&display=swap");
@media (max-width: 768px) {
  .pc-visible {
    display: none;
  }
}
@media (min-width: 769px) {
  .sp-visible {
    display: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -90px, 0);
    transform: translate3d(0, -90px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -45px, 0);
    transform: translate3d(0, -45px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
/* =========================================================
RESET
========================================================= */
/* layout */
/*=================================
  initialization of style
===================================*/
html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #000000;
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  font-feature-settings: "palt";
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.en {
  word-break: break-all;
  font-family: "Bitter", serif;
  font-weight: 400;
}

a:link,
a:visited {
  text-decoration: none;
  color: #535252;
}

a:hover,
a:active {
  text-decoration: none;
  color: #535252;
  opacity: 0.7;
}

figure, figcaption, div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, form {
  margin: 0;
  padding: 0;
}

img, a img {
  border: none;
  vertical-align: bottom;
}

img {
  display: block;
  width: 100%;
}

/* List_group */
ul, ol, li {
  list-style: none;
}

.wyswyg ul, .wyswyg ol {
  padding-left: 20px;
  list-style-type: disc;
}

.wyswyg ul li, .wyswyg ol li {
  list-style: inherit;
}

.wyswyg ol {
  list-style-type: decimal;
}

/* inline-style */
address {
  font-style: normal;
  font-size: 12px;
  color: #535252;
}

address a,
address a:link,
address a:visited {
  text-decoration: none;
  color: #535252;
}

address a:hover,
address a:active {
  text-decoration: underline;
  color: #535252;
}

/* table */
table {
  width: 100%;
  border-collapse: collapse;
}

*, *:before, *:after {
  outline: none !important;
  box-sizing: border-box;
}

.tsn {
  transition: 0.4s;
}

.clear::after {
  content: " ";
  clear: both;
  display: block;
  width: 100%;
  height: 0;
}

/* =========================================================
common
========================================================= */
html {
  margin-top: 0 !important;
}

/* =========================================================
  header
========================================================= */
body {
  padding-top: 110px;
}

@media (max-width: 768px) {
  body {
    padding-top: 61px;
  }
}
#header {
  position: relative;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

#header.active .head-wrap .logo a::before {
  opacity: 1;
}

#header.active .head-wrap .logo a img {
  opacity: 0;
}

#header .head-wrap {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 0 30px;
  position: relative;
}

@media (max-width: 768px) {
  #header .head-wrap {
    padding: 8px 20px 15px 20px;
  }
}
#header .head-wrap .logo {
  width: 270px;
  transition: 0.4s;
  position: relative;
  z-index: 1;
  padding: 15px 0 40px 0;
}

@media screen and (max-width: 1140px) {
  #header .head-wrap .logo {
    width: 230px;
  }
}
@media screen and (max-width: 960px) {
  #header .head-wrap .logo {
    width: 170px;
  }
}
@media (max-width: 768px) {
  #header .head-wrap .logo {
    width: 170px;
    padding: 0;
  }
}
#header .head-wrap .logo a {
  display: block;
  transition: 0.4s;
  position: relative;
}

#header .head-wrap .logo a::after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  width: 0;
  transition: 0.4s;
  background: #02A848;
}

#header .head-wrap .logo a::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/common/logo_ft.png") no-repeat center;
  background-size: cover;
  opacity: 0;
  transition: 0.4s;
}

#header .head-wrap .logo a:hover {
  opacity: 1;
}

#header .head-wrap .logo a:hover::after {
  width: 100%;
}

#header .head-wrap .nav {
  width: calc(100% - 300px);
  text-align: right;
  transition: 0.4s;
}

@media screen and (max-width: 1140px) {
  #header .head-wrap .nav {
    width: calc(100% - 230px);
  }
}
@media screen and (max-width: 960px) {
  #header .head-wrap .nav {
    width: calc(100% - 170px);
  }
}
@media (max-width: 768px) {
  #header .head-wrap .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #007130;
    z-index: 0;
    display: none;
    transition: inherit;
    padding: 70px 0 30px 0;
    text-align: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
@media (max-width: 768px) {
  #header .head-wrap .nav ul {
    padding: 0 20px;
  }
}
#header .head-wrap .nav ul li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li {
    display: block;
  }
}
#header .head-wrap .nav ul li > a {
  display: block;
  color: #007130;
  padding: 30px 20px;
  font-weight: bold;
  position: relative;
  transition: 0.4s;
}

@media screen and (max-width: 1140px) {
  #header .head-wrap .nav ul li > a {
    padding: 30px 15px;
    font-size: 14px;
  }
}
@media screen and (max-width: 960px) {
  #header .head-wrap .nav ul li > a {
    font-size: 12px;
    padding: 30px 10px;
  }
}
@media (max-width: 768px) {
  #header .head-wrap .nav ul li > a {
    color: #fff;
    padding: 20px 10px;
    font-size: 18px;
  }
}
#header .head-wrap .nav ul li > a::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  width: 0;
  transition: 0.4s;
  background: #02A848;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li > a::after {
    display: none;
  }
}
#header .head-wrap .nav ul li > a:hover {
  color: #02A848;
  opacity: 1;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li > a:hover {
    color: #fff;
  }
}
#header .head-wrap .nav ul li > a:hover::after {
  width: 100%;
}

#header .head-wrap .nav ul li > a.barNone:hover::after {
  width: 0;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li > a.barNone {
    padding-bottom: 10px;
  }
}
#header .head-wrap .nav ul li.current > a {
  color: #007130 !important;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li.current > a {
    color: #fff !important;
  }
}
#header .head-wrap .nav ul li.current > a::after {
  content: " ";
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 8px;
  width: 8px !important;
  border-radius: 100%;
  background: #007130;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li.current > a::after {
    display: none;
  }
}
#header .head-wrap .nav ul li:hover .child {
  animation: fadeIn 0.2s linear forwards;
  display: block;
}

#header .head-wrap .nav ul li .child {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateY(0%) translateX(-50%);
  min-width: 170px;
  width: calc(100% + 50px);
  background: #02A848;
  text-align: center;
  transition: 0.4s;
  opacity: 0;
  display: none;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li .child {
    display: block;
    opacity: 1;
    position: inherit;
    top: inherit;
    left: inherit;
    transform: inherit;
    width: 100%;
    min-width: inherit;
    background: none;
  }
}
#header .head-wrap .nav ul li .child::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
  border-color: transparent transparent #02A848 transparent;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li .child::after {
    display: none;
  }
}
#header .head-wrap .nav ul li .child a {
  display: block;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  transition: 0.4s;
  position: relative;
}

#header .head-wrap .nav ul li .child a span {
  font-size: 12px;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li .child a {
    display: inline-block;
    font-size: 14px;
  }

  #header .head-wrap .nav ul li .child a::after {
    content: " ";
    position: absolute;
    top: 0;
    right: -3px;
    bottom: 0;
    margin: auto;
    height: 50%;
    width: 1px;
    background: #fff;
    transform: rotate(10deg);
  }

  #header .head-wrap .nav ul li .child a:last-child::after {
    display: none;
  }
}
@media (max-width: 768px) {
  #header .head-wrap .nav ul li.contact {
    margin-top: 10px;
  }
}
#header .head-wrap .nav ul li.contact a {
  color: #fff !important;
  background: #007130;
  border-radius: 4px;
  padding: 10px 20px;
}

#header .head-wrap .nav ul li.contact a.current {
  background: #02A848 !important;
}

@media screen and (max-width: 960px) {
  #header .head-wrap .nav ul li.contact a {
    padding: 10px;
  }
}
#header .head-wrap .nav ul li.contact a::after {
  display: none;
}

#header .head-wrap .nav ul li.contact a:hover {
  background: #02A848;
}

@media (max-width: 768px) {
  #header .head-wrap .nav ul li.contact a {
    background: #fff !important;
    color: #007130 !important;
  }
}
@media (max-width: 768px) {
  #header .head-wrap .sp-navBtn {
    display: block;
    width: 32px;
    height: 32px;
    margin-top: 6px;
    position: relative;
    cursor: pointer;
  }

  #header .head-wrap .sp-navBtn.active span {
    background: #fff;
  }

  #header .head-wrap .sp-navBtn span {
    background: #007130;
    width: 30px;
    height: 2px;
    display: block;
    border-radius: 100px;
    transition: 0.4s;
  }

  #header .head-wrap .sp-navBtn span:first-child {
    position: absolute;
    top: -20px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  #header .head-wrap .sp-navBtn span:nth-child(2) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  #header .head-wrap .sp-navBtn span:nth-child(3) {
    position: absolute;
    bottom: -20px;
    right: 0;
    top: 0;
    left: 0;
    margin: auto;
  }

  #header .head-wrap .sp-navBtn.active span:last-child {
    opacity: 0;
  }

  #header .head-wrap .sp-navBtn.active span:first-child {
    top: 0;
    bottom: 0;
    transform: rotate(45deg);
  }

  #header .head-wrap .sp-navBtn.active span:nth-child(3) {
    top: 0;
    right: 6px;
    bottom: 0;
    transform: rotate(45deg);
    background: #fff;
  }

  #header .head-wrap .sp-navBtn.active span:nth-child(2) {
    transform: rotate(-45deg);
  }
}
/* =========================================================
  footer
========================================================= */
footer {
  background: #007130;
  padding: 60px 20px 40px 20px;
  overflow: hidden;
}

@media (max-width: 768px) {
  footer {
    padding: 40px 20px 25px 20px;
  }
}
footer .footer-wrap {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  footer .footer-wrap {
    display: block;
  }
}
footer .footer-wrap .logo {
  max-width: 275px;
  width: 20%;
  transition: 0.4s;
}

@media screen and (max-width: 1140px) {
  footer .footer-wrap .logo {
    max-width: 170px;
  }
}
@media (max-width: 768px) {
  footer .footer-wrap .logo {
    max-width: 315px;
    width: 100%;
    margin: 0 auto 20px auto;
  }
}
footer .footer-wrap .logo a {
  display: block;
  transition: 0.4s;
  opacity: 1 !important;
}

footer .footer-wrap .ft-nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 57%;
  text-align: left;
  padding-top: 10px;
}

@media screen and (max-width: 1140px) {
  footer .footer-wrap .ft-nav {
    padding-top: 0px;
  }
}
@media (max-width: 768px) {
  footer .footer-wrap .ft-nav {
    max-width: 315px;
    width: 100%;
    display: block;
    margin: 0 auto 15px auto;
  }
}
footer .footer-wrap .ft-nav li {
  width: 25%;
}

@media (max-width: 768px) {
  footer .footer-wrap .ft-nav li {
    width: 100%;
    margin-bottom: 0;
  }
}
footer .footer-wrap .ft-nav li a {
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: 0.4s;
  padding: 5px 0;
}

footer .footer-wrap .ft-nav li a:hover {
  text-decoration: underline;
  opacity: 1;
}

footer .footer-wrap .ft-nav li.second {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  text-align: left;
}

@media (max-width: 768px) {
  footer .footer-wrap .ft-nav li.second {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}
footer .footer-wrap .ft-nav li.second .left, footer .footer-wrap .ft-nav li.second .right {
  width: 50%;
}

@media (max-width: 768px) {
  footer .footer-wrap .ft-nav li.second .left, footer .footer-wrap .ft-nav li.second .right {
    width: 40%;
  }
}
footer .footer-wrap .ft-nav li.second .left a, footer .footer-wrap .ft-nav li.second .right a {
  padding: 5px 0 5px 18px;
  position: relative;
}

footer .footer-wrap .ft-nav li.second .left a::after, footer .footer-wrap .ft-nav li.second .right a::after {
  content: " ";
  position: absolute;
  top: 16px;
  left: 0;
  width: 8px;
  height: 2px;
  background: #fff;
}

@media (max-width: 768px) {
  footer .footer-wrap .ft-nav li.second .right {
    width: 55%;
  }
}
@media (max-width: 768px) {
  footer .footer-wrap .ft-nav li.third {
    text-align: center;
  }
}
footer .footer-wrap .ft-nav li.third a {
  display: inline-block;
  width: 24px;
  margin: 0 8px;
  transition: 0.4s;
}

footer .footer-wrap .ft-nav li.third a:hover {
  text-decoration: none;
}

footer .footer-wrap .contact-btn {
  max-width: 160px;
  width: 17%;
}

@media (max-width: 768px) {
  footer .footer-wrap .contact-btn {
    margin: 0 auto;
    max-width: 315px;
    width: 100%;
  }
}
footer .footer-wrap .contact-btn a {
  display: block;
  transition: 0.4s;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  color: #007130;
  font-weight: bold;
  padding: 10px 0;
}

footer .footer-wrap .contact-btn a:hover {
  background: #EDFFAF;
  opacity: 1;
}

footer .footer-wrap .copyright {
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: 20px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}

@media (max-width: 768px) {
  footer .footer-wrap .copyright {
    margin-top: 25px;
  }
}
footer .footer-wrap .copyright a {
  color: #fff;
  opacity: 1 !important;
}

#pagetop {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
}

@media (max-width: 768px) {
  #pagetop {
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 10px;
  }
}
#pagetop a {
  background: #007130;
  border-radius: 100%;
  display: block;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  border: 2px solid #fff;
  transition: 0.4s;
}

#pagetop a:hover {
  opacity: 1;
  background: #02A848;
}

#pagetop a::after {
  content: " ";
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 11px;
  height: 11px;
  border-radius: 3px;
  transform: rotate(-45deg);
}

@media (max-width: 768px) {
  #pagetop a::after {
    width: 13px;
    height: 13px;
    top: 5px;
  }
}
/* =========================================================
  common
========================================================= */
.conts-wrap {
  overflow: hidden;
}

.inner1080 {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}

.inner920 {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}

.subttl {
  color: #007130;
  font-size: 26px;
  line-height: 34px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  position: relative;
}

@media (max-width: 768px) {
  .subttl {
    margin-bottom: 30px;
  }
}
.subttl::after {
  content: " ";
  position: absolute;
  width: 40px;
  height: 4px;
  background: #007130;
  border-radius: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.btn {
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
}

.btn.btn02 a {
  background: #007130;
  color: #fff;
}

.btn.btn02 a:hover {
  background: #02A848;
  color: #fff;
}

.btn a {
  display: block;
  border: 2px solid #007130;
  border-radius: 8px;
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  padding: 10px;
  font-weight: bold;
  color: #007130;
  transition: 0.4s;
}

.btn a:hover {
  opacity: 1;
  color: #02A848;
  border: 2px solid #02A848;
}

.ac-btn {
  text-align: center;
}

.ac-btn a {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
  transition: 0.4s;
  color: #007130;
  font-weight: bold;
}

.ac-btn a:hover {
  color: #02A848;
}

.ac-btn a:hover::after {
  border-color: #02A848;
}

.ac-btn a::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(-45deg);
}

.icon-svg {
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}

.icon-ajaile,
.icon-engineer,
.icon-onestop {
  stroke: #007130;
  transition: 0.4s;
}

#loading {
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}

#loading .loading-animation {
  overflow: hidden;
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
}

@media (max-width: 768px) {
  #loading .loading-animation {
    width: 800px;
    top: 50%;
  }
}
#loading .loading-animation::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #fff;
  transition: 1.7s ease-in-out;
  transition-delay: 2.5s;
  z-index: 1000;
}

#loading .loading-animation::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #fff;
  transition: 1.7s ease-in-out;
  transition-delay: 2.5s;
  z-index: 1000;
}

#loading .loading-animation .white {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  transition: 2s ease-in-out;
  transition-delay: 0.5s;
}

#loading .loading-animation .loading-img, #loading .loading-animation .loading-img-hands {
  position: absolute;
  top: 0;
  left: 0;
}

#loading .loading-animation .loading-img-hands {
  opacity: 0;
  transition: 1s;
  transition-delay: 2.2s;
  z-index: 10000;
}

#loading.move .loading-animation {
  animation: key 0.7s ease alternate;
  animation-delay: 4.3s;
}

@media (max-width: 768px) {
  #loading.move .loading-animation {
    animation: keysec 0.7s ease alternate;
    animation-delay: 4.3s;
  }
}
#loading.move .loading-animation .white {
  width: 0%;
}

#loading.move .loading-animation .loading-img-hands {
  opacity: 1;
}

#loading.move .loading-animation::after, #loading.move .loading-animation::before {
  width: 40%;
}

#loading.move::after {
  left: 100%;
}

#loading.move.move02 .loading-animation::after, #loading.move.move02 .loading-animation::before {
  width: 51%;
  transition: 1s ease-in-out;
  transition-delay: 0;
}

#loading.loaded {
  opacity: 0;
  visibility: hidden;
}

.submv {
  position: relative;
  background: #007130;
  height: 215px;
}

.submv .read {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  padding: 0 20px;
  color: #fff;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
}

.submv .read span {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 3px;
}

@media (max-width: 768px) {
  .submv .read span {
    display: block;
  }
}
.wp-pagenavi {
  text-align: left;
}

.wp-pagenavi span {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding-top: 10px;
  color: #FFF;
  background: #007130;
  margin: 3px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-variant: top;
  border: 2px solid #007130;
}

@media (max-width: 768px) {
  .wp-pagenavi span {
    width: 36px;
    height: 36px;
    padding-top: 5px;
  }
}
.wp-pagenavi span.extend {
  background: none;
  color: #111;
  border: none;
}

.wp-pagenavi a.page {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 2px solid #007130;
  padding-top: 10px;
  margin: 3px;
  text-align: center;
  transition: 0.4s;
  border-radius: 5px;
  font-weight: bold;
  color: #007130;
  font-variant: top;
}

@media (max-width: 768px) {
  .wp-pagenavi a.page {
    width: 36px;
    height: 36px;
    padding-top: 5px;
  }
}
.wp-pagenavi a.first:hover,
.wp-pagenavi a.last:hover,
.wp-pagenavi a.last:hover,
.wp-pagenavi a.nextpostslink:hover,
.wp-pagenavi a.previouspostslink:hover,
.wp-pagenavi a.page:hover {
  color: #FFF;
  background: #02A848;
  opacity: 1;
  border-color: #02A848;
}

.wp-pagenavi a.first:hover::after,
.wp-pagenavi a.last:hover::after,
.wp-pagenavi a.last:hover::after,
.wp-pagenavi a.nextpostslink:hover::after,
.wp-pagenavi a.previouspostslink:hover::after,
.wp-pagenavi a.page:hover::after {
  border-color: #fff;
}

.wp-pagenavi a.previouspostslink {
  padding-right: 10px;
  transition: 0.4s;
}

.wp-pagenavi a.nextpostslink {
  padding-left: 10px;
  transition: 0.4s;
}

.wp-pagenavi a.nextpostslink,
.wp-pagenavi a.previouspostslink {
  position: relative;
  width: 48px;
  height: 48px;
  border: 2px solid #007130;
  display: inline-block;
  border-radius: 5px;
  padding: 0;
  vertical-align: top;
  margin: 3px 0px;
  transition: 0.4s;
}

.wp-pagenavi a.nextpostslink::after,
.wp-pagenavi a.previouspostslink::after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .wp-pagenavi a.nextpostslink::after,
.wp-pagenavi a.previouspostslink::after {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 768px) {
  .wp-pagenavi a.nextpostslink,
.wp-pagenavi a.previouspostslink {
    height: 36px;
    width: 36px;
  }
}
.wp-pagenavi a.first,
.wp-pagenavi a.last {
  position: relative;
  width: auto;
  height: 48px;
  padding-right: 20px;
  border: 2px solid #007130;
  display: inline-block;
  border-radius: 5px;
  padding: 0;
  vertical-align: top;
  margin: 3px 0px;
  transition: 0.4s;
  color: #007130;
  width: 75px;
  font-weight: bold;
  padding: 9px 0 0 10px;
}

.wp-pagenavi a.first::after,
.wp-pagenavi a.last::after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .wp-pagenavi a.first::after,
.wp-pagenavi a.last::after {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 768px) {
  .wp-pagenavi a.first,
.wp-pagenavi a.last {
    height: 36px;
    padding-top: 4px;
    font-size: 15px;
  }
}
.wp-pagenavi a.first {
  padding: 9px 10px 0 0;
  text-align: right;
}

.wp-pagenavi a.first::after {
  left: 25%;
  transform: translateY(-50%) translateX(-50%) rotate(-135deg);
}

/* CASE
---------------------------*/
.case {
  padding: 0 20px;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .case {
    margin-bottom: 80px;
  }
}
.case .case-list {
  margin: 0 auto 10px auto;
  display: block;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

@media (max-width: 768px) {
  .case .case-list {
    margin-bottom: 10px;
  }
}
.case .case-list li {
  width: 13%;
  margin-right: 1.5%;
}

.case .case-list li:last-child, .case .case-list li:nth-child(7n) {
  margin-right: 0;
}

@media (max-width: 768px) {
  .case .case-list li {
    width: 30%;
    margin-bottom: 20px;
    margin-right: 5%;
  }

  .case .case-list li:nth-child(7n) {
    margin-right: 5%;
  }

  .case .case-list li:last-child, .case .case-list li:nth-child(3n) {
    margin-right: 0;
  }
}
.case .case-list li a {
  display: block;
  height: 100%;
  transition: 0.4s;
}

.case .case-list li .thum {
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.case .case-list li .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.case .case-list li .title {
  color: #007130;
  font-size: 12px;
  line-height: 20px;
  transition: 0.4s;
}

.case .ac-btn {
  margin-bottom: 40px;
}

.case .ac-btn a {
  font-size: 20px;
}

.case .bt-txt {
  text-align: center;
  color: #007130;
  font-weight: bold;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .case .bt-txt {
    margin-bottom: 20px;
  }
}
/* BLOG
---------------------------*/
.blog {
  padding: 0;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .blog {
    margin-bottom: 80px;
  }
}
.blog .inner1080 {
  position: relative;
  z-index: 0;
  padding: 80px 0 0 0;
}

.blog .inner1080::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background: #007130;
  z-index: -1;
}

@media (max-width: 768px) {
  .blog .inner1080 {
    padding-top: 70px;
  }
}
.blog .inner1080 .subttl {
  color: #fff;
}

.blog .inner1080 .subttl::after {
  background: #fff;
}

.blog .blog-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 40px);
  text-align: left;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .blog .blog-list {
    display: block;
    margin-bottom: 30px;
  }
}
.blog .blog-list li {
  width: 22%;
  margin: 0 3% 3% 0;
}

.blog .blog-list li:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 768px) {
  .blog .blog-list li {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .blog .blog-list li:last-child {
    margin-bottom: 0;
  }
}
.blog .blog-list li a {
  display: block;
  transition: 0.4s;
  color: #007130;
  height: 100%;
}

.blog .blog-list li a:hover {
  opacity: 1;
}

.blog .blog-list li a:hover .title {
  text-decoration: none;
  color: #02A848;
}

.blog .blog-list li a:hover .thum {
  box-shadow: 3px 3px 0px 0px #02A848;
}

.blog .blog-list li a:hover .thum .cate {
  background: #02A848;
}

.blog .blog-list li .thum {
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 3px 3px 0px 0px #007130;
  margin-bottom: 15px;
  background: #f5f5f5;
}

.blog .blog-list li .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.blog .blog-list li .thum .cate {
  transition: 0.4s;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  background: #007130;
  padding: 0 10px;
  font-size: 12px;
}

.blog .blog-list li .date {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}

.blog .blog-list li .title {
  text-decoration: underline;
  font-weight: bold;
  transition: 0.4s;
}

.not_found {
  padding: 40px 20px;
  text-align: left;
}

.not_found .white_bg {
  padding: 50px;
  background: #f5f5f5;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .not_found .white_bg {
    padding: 30px 20px;
  }
}
.not_found .white_bg .ttl02 {
  color: #007130;
  margin-bottom: 20px;
}

@keyframes key {
  0% {
    margin-top: 0px;
  }
  33% {
    margin-top: -1%;
  }
  50% {
    margin-top: 0.5%;
  }
  80% {
    margin-top: -0.5%;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes keysec {
  0% {
    margin-top: 0px;
  }
  33% {
    margin-top: -2%;
  }
  50% {
    margin-top: 1%;
  }
  80% {
    margin-top: -1%;
  }
  100% {
    margin-top: 0px;
  }
}
/* =========================================================
home
========================================================= */
.pagetypehome {
  /* =========================================================
    home
  ========================================================= */
  /* MV
  ---------------------------*/
  /* MV-BT
  ---------------------------*/
  /* SERVICE
  ---------------------------*/
  /* ABOUT
  ---------------------------*/
  /* NEWS
  ---------------------------*/
}

.pagetypehome .mv {
  width: 100%;
  height: calc(100vh - 85px);
  background: url("../images/home/mv.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  transition: 0.7s;
  opacity: 0;
}

.pagetypehome .mv.loaded {
  opacity: 1;
}

.pagetypehome .mv.loaded .txt-area .large-txt {
  opacity: 1;
  transform: translateX(0);
}

.pagetypehome .mv.loaded .txt-area .main-txt {
  opacity: 1;
  transform: translateY(0);
  height: 100px;
}

@media screen and (max-width: 1140px) {
  .pagetypehome .mv.loaded .txt-area .main-txt {
    height: 60px;
  }
}
@media (max-width: 768px) {
  .pagetypehome .mv.loaded .txt-area .main-txt {
    height: 60px;
  }
}
@media (max-width: 768px) {
  .pagetypehome .mv.loaded .txt-area .main-txt {
    height: 290px;
  }
}
@media (max-width: 768px) {
  .pagetypehome .mv {
    height: calc(100vh - 61px);
    background: url("../images/home/mv_sp.jpg") no-repeat center;
    background-size: cover;
  }
}
.pagetypehome .mv .txt-area {
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  max-width: 920px;
  width: calc(75% - 20px);
  color: #fff;
}

@media (max-width: 768px) {
  .pagetypehome .mv .txt-area {
    top: 47%;
    width: calc(100% - 30px);
  }
}
.pagetypehome .mv .txt-area .large-txt {
  font-weight: 600;
  font-size: 80px;
  line-height: 80px;
  margin-bottom: 5%;
  opacity: 0;
  transition: 1s;
  transition-delay: 0.7s;
  transform: translateX(50px);
}

@media screen and (max-width: 1140px) {
  .pagetypehome .mv .txt-area .large-txt {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 3%;
  }
}
@media (max-width: 768px) {
  .pagetypehome .mv .txt-area .large-txt {
    margin-bottom: 20px;
  }
}
.pagetypehome .mv .txt-area .main-txt {
  font-size: 36px;
  line-height: 56px;
  opacity: 0;
  height: 0;
  transition: 1.1s;
  transition-delay: 1.7s;
  transform: translateY(50px);
}

@media screen and (max-width: 1140px) {
  .pagetypehome .mv .txt-area .main-txt {
    font-size: 24px;
    line-height: 32px;
  }
}
.pagetypehome .mv .txt-area .main-txt span {
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5%;
}

@media screen and (max-width: 1140px) {
  .pagetypehome .mv .txt-area .main-txt span {
    margin-top: 3%;
  }
}
@media (max-width: 768px) {
  .pagetypehome .mv .txt-area .main-txt span {
    margin-top: 20px;
  }
}
.pagetypehome .mv .txt-area .main-txt a {
  display: inline-block;
  padding: 0 10px 0 0;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
}

.pagetypehome .mv .txt-area .main-txt a::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

.pagetypehome .mv-bt .mv-bt-wrap::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}

.pagetypehome .mv-bt {
  padding: 0 0 50px 0;
  margin-top: -40px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  /* Firefox 対応 */
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt {
    margin-top: -60px;
  }
}
@media screen and (max-width: 1000px) {
  .pagetypehome .mv-bt .mv-bt-wrap {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
  }
}
.pagetypehome .mv-bt .inner920.inner920-01 {
  max-width: 960px;
  width: 960px;
  padding: 0 20px;
}

.pagetypehome .mv-bt .mv-bt-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

.pagetypehome .mv-bt .mv-bt-list li {
  width: 30%;
  border-radius: 8px;
  border: 4px solid #007130;
  box-shadow: 3px 3px 0px 0px #007130;
  background: #fff;
  transition: 0.4s;
  color: #007130;
  font-weight: bold;
  cursor: pointer;
  padding: 15px 10px;
  font-size: 20px;
  line-height: 24px;
}

.pagetypehome .mv-bt .mv-bt-list li:hover {
  color: #02A848;
  border: 4px solid #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetypehome .mv-bt .mv-bt-list li:hover .icon-ajaile,
.pagetypehome .mv-bt .mv-bt-list li:hover .icon-engineer,
.pagetypehome .mv-bt .mv-bt-list li:hover .icon-onestop {
  stroke: #02A848;
}

.pagetypehome .mv-bt .mv-bt-list li.on {
  color: #fff !important;
  background: #007130 !important;
  pointer-events: none;
  box-shadow: inherit;
}

.pagetypehome .mv-bt .mv-bt-list li.on .icon-ajaile,
.pagetypehome .mv-bt .mv-bt-list li.on .icon-engineer,
.pagetypehome .mv-bt .mv-bt-list li.on .icon-onestop {
  stroke: #fff !important;
}

.pagetypehome .mv-bt .mv-bt-list li .thum-svg {
  margin-bottom: 15px;
}

.pagetypehome .mv-bt .mv-bt-list li .thum-svg.thum-svg03 {
  margin-bottom: 25px;
}

.pagetypehome .mv-bt .hid-area {
  border: 2px solid #007130;
  padding: 40px 10px;
  margin-top: 50px;
  color: #007130;
  position: relative;
  display: none;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area {
    border-right: none;
    border-left: none;
    padding: 40px 20px;
  }
}
.pagetypehome .mv-bt .hid-area::after {
  content: " ";
  position: absolute;
  top: -24px;
  right: 13%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 25px 20px;
  border-color: transparent transparent #ffffff transparent;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area::after {
    left: inherit;
    right: calc(50% - 11px);
  }
}
.pagetypehome .mv-bt .hid-area::before {
  content: " ";
  position: absolute;
  top: -27px;
  right: calc(13% - 2px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 22px 27px 22px;
  border-color: transparent transparent #007130 transparent;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area::before {
    left: inherit;
    right: calc(50% - 13px);
  }
}
.pagetypehome .mv-bt .hid-area#hid-area02::after {
  right: calc(50% - 11px);
}

.pagetypehome .mv-bt .hid-area#hid-area02::before {
  right: calc(50% - 13px);
}

.pagetypehome .mv-bt .hid-area#hid-area01::after {
  right: inherit;
  left: 13%;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area#hid-area01::after {
    left: inherit;
    right: calc(50% - 11px);
  }
}
.pagetypehome .mv-bt .hid-area#hid-area01::before {
  right: inherit;
  left: calc(13% - 2px);
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area#hid-area01::before {
    left: inherit;
    right: calc(50% - 13px);
  }
}
.pagetypehome .mv-bt .hid-area .hid-area-wrap {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area .hid-area-wrap {
    max-width: 100%;
  }
}
.pagetypehome .mv-bt .hid-area .read {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area .read {
    margin-bottom: 20px;
  }
}
.pagetypehome .mv-bt .hid-area .flex {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area .flex {
    display: block;
  }
}
.pagetypehome .mv-bt .hid-area .flex span {
  display: block;
  width: calc(100% - 240px);
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area .flex span {
    width: 100%;
  }
}
.pagetypehome .mv-bt .hid-area .flex span.w100 {
  width: 100%;
}

.pagetypehome .mv-bt .hid-area .flex a {
  display: block;
  width: 200px;
  text-align: center;
  border: 2px solid #007130;
  padding: 5px;
  transition: 0.4s;
  color: #007130;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetypehome .mv-bt .hid-area .flex a {
    width: 100%;
    margin-top: 30px;
  }
}
.pagetypehome .mv-bt .hid-area .flex a:hover {
  opacity: 1;
  color: #02A848;
  border-color: #02A848;
}

.pagetypehome .service {
  padding: 80px 20px 0 20px;
  margin-bottom: 100px;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypehome .service {
    margin-bottom: 80px;
  }
}
.pagetypehome .service::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 360px;
  background: #007130;
  z-index: -1;
}

.pagetypehome .service .subttl {
  color: #fff;
  margin-bottom: 120px;
}

.pagetypehome .service .subttl::after {
  background: #fff;
}

.pagetypehome .service .service-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .pagetypehome .service .service-list {
    display: block;
    margin-bottom: 50px;
  }
}
.pagetypehome .service .service-list li {
  width: calc(50% - 20px);
  text-align: center;
  display: none;
}

.pagetypehome .service .service-list li.w100 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.pagetypehome .service .service-list li:nth-child(1), .pagetypehome .service .service-list li:nth-child(2) {
  display: block;
}

@media (max-width: 768px) {
  .pagetypehome .service .service-list li {
    width: 100%;
    margin-bottom: 110px;
  }

  .pagetypehome .service .service-list li:last-child {
    margin-bottom: 0;
  }
}
.pagetypehome .service .service-list li a {
  display: block;
  color: #007130;
  transition: 0.4s;
  height: 100%;
}

.pagetypehome .service .service-list li a:hover {
  opacity: 1;
  color: #02A848;
}

.pagetypehome .service .service-list li a:hover .txt-area {
  border-color: #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetypehome .service .service-list li a:hover .txt-area .thum .thum-img {
  top: 45%;
}

.pagetypehome .service .service-list li .txt-area {
  transition: 0.4s;
  background: #fff;
  height: 100%;
  padding: 0 10px 30px 10px;
  border: 2px solid #007130;
  border-radius: 8px;
  box-shadow: 3px 3px 0px 0px #007130;
}

.pagetypehome .service .service-list li .txt-area .thum {
  position: relative;
  margin-bottom: 15px;
  max-width: 300px;
  width: 80%;
  margin: -100px auto 20px auto;
}

@media (max-width: 768px) {
  .pagetypehome .service .service-list li .txt-area .thum {
    max-width: 100%;
    width: 210px;
    margin-top: -80px;
  }
}
.pagetypehome .service .service-list li .txt-area .thum .thum-img {
  transition: 0.4s;
  height: 100%;
  width: auto;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetypehome .service .service-list li .txt-area .thum .thum-img.thum-border {
  border: 2px solid #007130;
  border-radius: 8px;
}

.pagetypehome .service .service-list li .txt-area .title {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
}

.pagetypehome .service .ac-btn {
  margin-bottom: 50px;
}

.pagetypehome .service .ac-btn a {
  font-size: 20px;
}

@media (max-width: 768px) {
  .pagetypehome .service .ac-btn a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .pagetypehome .service .ac-btn {
    margin-bottom: 40px;
  }
}
.pagetypehome .service .bt-txt {
  font-size: 20px;
  line-height: 32px;
  color: #007130;
  margin-bottom: 40px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetypehome .service .bt-txt {
    margin-bottom: 20px;
  }
}
.pagetypehome .about {
  padding: 30px 20px 0 20px;
  margin-bottom: 100px;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypehome .about {
    margin-bottom: 80px;
  }
}
.pagetypehome .about::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 360px;
  background: url("../images/home/img_about.jpg") no-repeat center;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}

@media (max-width: 768px) {
  .pagetypehome .about::after {
    background: url("../images/home/img_about_sp.jpg") no-repeat center;
    background-size: cover;
  }
}
.pagetypehome .about .subttl {
  margin-bottom: 40px;
}

.pagetypehome .about .apn-img {
  max-width: 200px;
  width: 100%;
  margin: 0 auto 30px auto;
}

.pagetypehome .about .about-txt {
  color: #007130;
  text-align: center;
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 30px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetypehome .about .about-txt {
    font-size: 31px;
  }
}
.pagetypehome .about .about-txt span {
  display: block;
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
}

.pagetypehome .about .bt-img {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
}

.pagetypehome .about .bt-txt {
  font-size: 20px;
  line-height: 32px;
  color: #007130;
  margin-bottom: 40px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetypehome .about .bt-txt {
    margin-bottom: 20px;
  }
}
.pagetypehome .news {
  padding: 0 20px 100px 20px;
}

@media (max-width: 768px) {
  .pagetypehome .news {
    padding: 0 0px 80px 0px;
  }
}
.pagetypehome .news .inner1080 {
  background: url("../images/common/line.png") no-repeat top center;
  background-size: contain;
  padding-top: 100px;
}

@media (max-width: 768px) {
  .pagetypehome .news .inner1080 {
    background-size: auto 60px;
    padding-top: 80px;
  }
}
.pagetypehome .news .subttl {
  font-size: 36px;
  padding-bottom: 30px;
}

.pagetypehome .news .new-list {
  margin: 0 auto 30px auto;
}

@media (max-width: 768px) {
  .pagetypehome .news .new-list {
    padding: 0 20px;
  }
}
.pagetypehome .news .new-list li a {
  display: block;
  color: #007130;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding: 5px 0;
}

.pagetypehome .news .new-list li a .date {
  font-size: 12px;
  width: 80px;
  font-weight: bold;
}

.pagetypehome .news .new-list li a .title {
  width: calc(100% - 85px);
  text-decoration: underline;
  transition: 0.4s;
}

.pagetypehome .news .new-list li a:hover {
  opacity: 1;
}

.pagetypehome .news .new-list li a:hover .title {
  text-decoration: none;
  color: #02A848;
}

/* =========================================================
news
========================================================= */
.pagetypenews {
  /* =========================================================
   news
  ========================================================= */
}

.pagetypenews .main-clm {
  padding: 60px 20px 100px 20px;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm {
    padding: 50px 20px 60px 20px;
  }
}
.pagetypenews .main-clm .inner1080 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.pagetypenews .main-clm .left {
  max-width: 760px;
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left {
    max-width: 100%;
    width: 100%;
  }
}
.pagetypenews .main-clm .left .pegebav_bt {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
}

.pagetypenews .main-clm .left .pegebav_bt li.next, .pagetypenews .main-clm .left .pegebav_bt li.prev {
  min-width: 85px;
}

.pagetypenews .main-clm .left .pegebav_bt li.next a, .pagetypenews .main-clm .left .pegebav_bt li.prev a {
  position: relative;
  width: auto;
  padding-right: 20px;
  display: inline-block;
  border-radius: 5px;
  padding: 0;
  vertical-align: top;
  margin: 3px 0px;
  transition: 0.4s;
  color: #007130;
  width: 85px;
  font-weight: bold;
  padding: 10px 25px 10px 10px;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left .pegebav_bt li.next a, .pagetypenews .main-clm .left .pegebav_bt li.prev a {
    font-size: 14px;
    padding: 7px 20px 7px 10px;
  }
}
.pagetypenews .main-clm .left .pegebav_bt li.next a:hover, .pagetypenews .main-clm .left .pegebav_bt li.prev a:hover {
  color: #fff;
  background: #02A848;
  border-color: #02A848;
}

.pagetypenews .main-clm .left .pegebav_bt li.next a:hover::after, .pagetypenews .main-clm .left .pegebav_bt li.prev a:hover::after {
  border-color: #fff;
}

.pagetypenews .main-clm .left .pegebav_bt li.next a::after, .pagetypenews .main-clm .left .pegebav_bt li.prev a::after {
  content: " ";
  display: block;
  transition: 0.4s;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left .pegebav_bt li.next a::after, .pagetypenews .main-clm .left .pegebav_bt li.prev a::after {
    width: 8px;
    height: 8px;
  }
}
.pagetypenews .main-clm .left .pegebav_bt li.next a {
  padding: 10px 5px 10px 25px;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left .pegebav_bt li.next a {
    padding: 7px 10px 7px 20px;
    text-align: center;
  }
}
.pagetypenews .main-clm .left .pegebav_bt li.next a::after {
  left: 20%;
  transform: translateY(-50%) translateX(-50%) rotate(-135deg);
}

.pagetypenews .main-clm .left .pegebav_bt li.return a {
  color: #007130;
  transition: 0.4s;
}

.pagetypenews .main-clm .left .pegebav_bt li.return a:hover {
  color: #02A848;
}

.pagetypenews .main-clm .left .press {
  border-top: 2px solid #007130;
  padding-top: 40px;
  margin-bottom: 50px;
}

.pagetypenews .main-clm .left .press:last-of-type {
  border-bottom: 2px solid #007130;
  padding-bottom: 40px;
}

.pagetypenews .main-clm .left .press .head {
  color: #007130;
  position: relative;
  padding-left: 110px;
  margin-bottom: 30px;
}

.pagetypenews .main-clm .left .press .head .date {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  position: absolute;
  top: 3px;
  left: 0;
}

.pagetypenews .main-clm .left .press .head li {
  display: inline-block;
}

.pagetypenews .main-clm .left .press .head a {
  color: #fff;
  display: inline-block;
  padding: 1px 10px;
  text-align: center;
  background: #007130;
  font-size: 12px;
  transition: 0.4s;
}

.pagetypenews .main-clm .left .press .head a:hover {
  opacity: 1;
  background: #02A848;
}

.pagetypenews .main-clm .left .press .title {
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  color: #007130;
  margin-bottom: 30px;
}

.pagetypenews .main-clm .left .press .title a {
  transition: 0.4s;
  color: #007130;
  text-decoration: underline;
}

.pagetypenews .main-clm .left .press .title a:hover {
  opacity: 1;
  text-decoration: none;
}

.pagetypenews .main-clm .left .press .wyswyg h1, .pagetypenews .main-clm .left .press .wyswyg h2, .pagetypenews .main-clm .left .press .wyswyg h3, .pagetypenews .main-clm .left .press .wyswyg h4, .pagetypenews .main-clm .left .press .wyswyg h5, .pagetypenews .main-clm .left .press .wyswyg h6 {
  color: #007130;
}

.pagetypenews .main-clm .left .press .wyswyg.revival img {
  margin: 10px 0;
}

.pagetypenews .main-clm .left .press .wyswyg p {
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left .press .wyswyg p {
    padding-bottom: 10px;
  }
}
.pagetypenews .main-clm .left .press .wyswyg img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.pagetypenews .main-clm .left .press .wyswyg a {
  color: #007130;
  transition: 0.4s;
  text-decoration: underline;
}

.pagetypenews .main-clm .left .press .wyswyg a:hover {
  opacity: 1;
  text-decoration: none;
}

.pagetypenews .main-clm .left .press .wyswyg .sns {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  margin-top: 40px;
}

.pagetypenews .main-clm .left .press .wyswyg .wp_social_bookmarking_light {
  padding: 0 !important;
}

.pagetypenews .main-clm .left .press .wyswyg a.tw {
  display: inline-block;
  background: #1b95e0;
  border-radius: 3px;
  padding: 0 8px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  font-size: 11px;
  text-decoration: none;
}

.pagetypenews .main-clm .left .press .wyswyg a.tw img {
  display: inline-block;
  width: 12px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.pagetypenews .main-clm .left .sr-btn {
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .left .sr-btn {
    width: 100%;
    text-align: center;
  }
}
.pagetypenews .main-clm .left .sr-btn a {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
  transition: 0.4s;
  color: #007130;
}

.pagetypenews .main-clm .left .sr-btn a::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

.pagetypenews .main-clm .left .sr-btn a:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypenews .main-clm .left .sr-btn a:hover::after {
  border-color: #02A848;
}

.pagetypenews .main-clm .right {
  max-width: 200px;
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypenews .main-clm .right {
    max-width: 100%;
    width: 100%;
    margin-top: 40px;
  }
}
.pagetypenews .main-clm .right .sub-block {
  border-top: 1px solid #007130;
  padding-top: 20px;
  margin-bottom: 30px;
}

.pagetypenews .main-clm .right .sub-block:last-of-type {
  margin-bottom: 0;
}

.pagetypenews .main-clm .right .sub-block .block-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
  color: #007130;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-category li a {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 25px;
  position: relative;
  font-weight: bold;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-category li a::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-category li a:hover {
  color: #fff;
  opacity: 1;
  background: #007130;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-category li a:hover::after {
  border-color: #fff;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-new li a, .pagetypenews .main-clm .right .sub-block .sub-column-list-archive li a {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 20px;
  position: relative;
  font-size: 14px;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-new li a::after, .pagetypenews .main-clm .right .sub-block .sub-column-list-archive li a::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 5px;
  height: 5px;
  border-top: 1px solid #007130;
  border-right: 1px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-new li a:hover, .pagetypenews .main-clm .right .sub-block .sub-column-list-archive li a:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-new li a:hover::after, .pagetypenews .main-clm .right .sub-block .sub-column-list-archive li a:hover::after {
  border-color: #02A848;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .tgl_area {
  display: none;
  padding-left: 20px;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .a_tgl {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 25px;
  position: relative;
  font-weight: bold;
  cursor: pointer;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .a_tgl::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .a_tgl.active-t::after {
  transform: rotate(135deg);
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .a_tgl:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypenews .main-clm .right .sub-block .sub-column-list-archive .a_tgl:hover::after {
  border-color: #02A848;
}

/* =========================================================
case
========================================================= */
.pagetypecase {
  /* =========================================================
   case
  ========================================================= */
  /* detail
  --------------------------------------------- */
}

.pagetypecase .case-conts01 {
  padding: 70px 20px 0 20px;
  margin-bottom: 90px;
}

@media (max-width: 768px) {
  .pagetypecase .case-conts01 {
    padding: 40px 20px 0 20px;
    margin-bottom: 50px;
  }
}
.pagetypecase .case-conts01 .case-ttl {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .pagetypecase .case-conts01 .case-ttl {
    margin-bottom: 30px;
  }
}
.pagetypecase .case-conts-list {
  display: block;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 768px) {
  .pagetypecase .case-conts-list {
    display: block;
  }
}
.pagetypecase .case-conts-list li {
  width: 30%;
  margin-right: 5%;
  text-align: left;
  margin-bottom: 30px;
}

.pagetypecase .case-conts-list li.w100 {
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .pagetypecase .case-conts-list li {
    display: block;
    width: 100%;
  }

  .pagetypecase .case-conts-list li:last-child {
    margin-bottom: 0;
  }
}
.pagetypecase .case-conts-list li:nth-child(3n) {
  margin-right: 0;
}

.pagetypecase .case-conts-list li a {
  display: block;
  transition: 0.4s;
  color: #007130 !important;
}

.pagetypecase .case-conts-list li a:hover {
  opacity: 1;
  color: #02A848 !important;
}

.pagetypecase .case-conts-list li a:hover .thum {
  border: 4px solid #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetypecase .case-conts-list li .thum {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 4px solid #007130;
  box-shadow: 3px 3px 0px 0px #007130;
  transition: 0.4s;
}

.pagetypecase .case-conts-list li .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetypecase .case-conts-list li .title {
  font-weight: bold;
  font-size: 22px;
  line-height: 38px;
  margin-bottom: 15px;
}

.pagetypecase .case-conts-list li .txt {
  margin-bottom: 15px;
}

.pagetypecase .case-conts-list li .detail span {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
  transition: 0.4s;
  font-weight: bold;
}

.pagetypecase .case-conts-list li .detail span::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 8px;
  height: 8px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(-45deg);
}

.pagetypecase .case {
  margin-bottom: 140px;
}

@media (max-width: 768px) {
  .pagetypecase .case {
    margin-bottom: 50px;
  }
}
.pagetypecase .case .case-list {
  max-width: 100%;
  margin-bottom: 0;
}

.pagetypecase .case .case-list li {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypecase .case .case-list li {
    margin-bottom: 15px;
  }
}
.pagetypecase .case .case-list li .title {
  font-size: 16px;
  line-height: 24px;
}

.pagetypecase .case-detail {
  padding: 70px 20px 0 20px;
  margin-bottom: 150px;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail {
    padding: 0;
    margin-bottom: 50px;
  }
}
.pagetypecase .case-detail .inner1080 {
  width: 100%;
  text-align: left;
  position: relative;
}

.pagetypecase .case-detail .thum-img {
  max-width: 520px;
  min-width: 400px;
  width: 48%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .thum-img {
    position: inherit;
    max-width: 100%;
    min-width: auto;
    width: 100%;
    margin-bottom: 30px;
  }
}
.pagetypecase .case-detail .thum-img .cap {
  margin-top: 30px;
  width: 250px;
  color: #007130;
  font-weight: 400;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .thum-img .cap {
    width: 100%;
    margin-top: -20px;
    background: #fff;
    padding: 20px 20px 0 20px;
    position: relative;
    z-index: 0;
    width: calc(100% - 20px);
  }
}
.pagetypecase .case-detail .detaile-area {
  width: 100%;
  text-align: left;
  margin-left: auto;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area {
    padding: 0 20px 50px 20px;
  }
}
.pagetypecase .case-detail .detaile-area .title {
  color: #007130;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  max-width: 520px;
  width: calc(100% - 250px);
  margin-left: auto;
  height: 200px;
  position: relative;
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .title {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
.pagetypecase .case-detail .detaile-area .title span {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .title span {
    position: inherit;
    top: inherit;
    left: inherit;
    transform: inherit;
  }
}
.pagetypecase .case-detail .detaile-area .conts-wrap {
  background: #fff;
  padding: 60px 60px 0 60px;
  max-width: 824px;
  width: calc(100% - 250px);
  margin-left: auto;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .conts-wrap {
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin-top: 30px;
    z-index: 0;
  }
}
.pagetypecase .case-detail .detaile-area .conts {
  color: #007130;
  margin-bottom: 40px;
}

.pagetypecase .case-detail .detaile-area .conts img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.pagetypecase .case-detail .detaile-area .conts a {
  color: #007130;
  transition: 0.4s;
}

.pagetypecase .case-detail .detaile-area .conts .link {
  padding-left: 20px;
  background: url("../images/case/icon_link.png") no-repeat top 3px left;
  background-size: 16px;
}

.pagetypecase .case-detail .detaile-area .conts .list01 {
  color: #000;
  padding-left: 20px;
}

.pagetypecase .case-detail .detaile-area .conts .list01 li {
  list-style: disc;
}

.pagetypecase .case-detail .detaile-area .conts .list02 li {
  padding-left: 20px;
  position: relative;
}

.pagetypecase .case-detail .detaile-area .conts .list02 li a {
  text-decoration: underline;
}

.pagetypecase .case-detail .detaile-area .conts .list02 li::after {
  content: " ";
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  width: 6px;
  height: 6px;
  transition: 0.4s;
  border-right: 1px solid #007130;
  border-bottom: 1px solid #007130;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.pagetypecase .case-detail .detaile-area .bt-contact {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 30px 0;
  border-top: 2px solid #007130;
  border-bottom: 2px solid #007130;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .bt-contact {
    display: block;
  }
}
.pagetypecase .case-detail .detaile-area .bt-contact .read {
  width: 60%;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .bt-contact .read {
    width: 100%;
  }
}
.pagetypecase .case-detail .detaile-area .bt-contact .btn.btn02 {
  width: 35%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .detaile-area .bt-contact .btn.btn02 {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }
}
.pagetypecase .case-detail .sr-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypecase .case-detail .sr-btn {
    width: 100%;
    text-align: center;
  }
}
.pagetypecase .case-detail .sr-btn:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypecase .case-detail .sr-btn:hover span::after {
  border-color: #02A848;
}

.pagetypecase .case-detail .sr-btn span {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
}

.pagetypecase .case-detail .sr-btn span::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

/* =========================================================
service
========================================================= */
.pagetypeservice {
  /* =========================================================
   service
  ========================================================= */
  /* detail
  --------------------------------------------- */
}

.pagetypeservice .service-conts01 {
  padding: 70px 20px 0 20px;
  margin-bottom: 90px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 {
    padding: 40px 20px 0 20px;
    margin-bottom: 50px;
  }
}
.pagetypeservice .service-conts01 .service-ttl {
  text-align: center;
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service-ttl {
    margin-bottom: 30px;
  }
}
.pagetypeservice .service-conts01 .service {
  padding: 80px 20px 0 20px;
  margin-bottom: 100px;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service {
    margin-bottom: 80px;
  }
}
.pagetypeservice .service-conts01 .service .subttl {
  color: #fff;
  margin-bottom: 120px;
}

.pagetypeservice .service-conts01 .service .subttl::after {
  background: #fff;
}

.pagetypeservice .service-conts01 .service .service-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service .service-list {
    display: block;
    margin-bottom: 50px;
  }
}
.pagetypeservice .service-conts01 .service .service-list li {
  width: calc(50% - 20px);
  text-align: center;
  margin-top: 150px;
}

.pagetypeservice .service-conts01 .service .service-list li:nth-child(1), .pagetypeservice .service-conts01 .service .service-list li:nth-child(2) {
  margin-top: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service .service-list li {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 110px !important;
  }

  .pagetypeservice .service-conts01 .service .service-list li:last-child {
    margin-bottom: 0 !important;
  }
}
.pagetypeservice .service-conts01 .service .service-list li a {
  display: block;
  color: #007130;
  transition: 0.4s;
  height: 100%;
}

.pagetypeservice .service-conts01 .service .service-list li a:hover {
  opacity: 1;
  color: #02A848;
}

.pagetypeservice .service-conts01 .service .service-list li a:hover .txt-area {
  border-color: #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetypeservice .service-conts01 .service .service-list li a:hover .txt-area .thum .thum-img {
  top: 45%;
}

.pagetypeservice .service-conts01 .service .service-list li .txt-area {
  transition: 0.4s;
  background: #fff;
  height: 100%;
  padding: 0 10px 30px 10px;
  border: 2px solid #007130;
  border-radius: 8px;
  box-shadow: 3px 3px 0px 0px #007130;
}

.pagetypeservice .service-conts01 .service .service-list li .txt-area .thum {
  position: relative;
  margin-bottom: 15px;
  max-width: 300px;
  width: 80%;
  margin: -100px auto 20px auto;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service .service-list li .txt-area .thum {
    max-width: 100%;
    width: 210px;
    margin-top: -80px;
  }
}
.pagetypeservice .service-conts01 .service .service-list li .txt-area .thum .thum-img {
  transition: 0.4s;
  height: 100%;
  width: auto;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetypeservice .service-conts01 .service .service-list li .txt-area .thum .thum-img.thum-border {
  border: 2px solid #007130;
  border-radius: 8px;
}

.pagetypeservice .service-conts01 .service .service-list li .txt-area .title {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
}

.pagetypeservice .service-conts01 .service02 {
  border-top: 2px solid #007130;
  margin-bottom: 70px;
}

.pagetypeservice .service-conts01 .service02 li a {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  border-bottom: 2px solid #007130;
  color: #007130;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service02 li a {
    padding-bottom: 20px;
    display: block;
  }
}
.pagetypeservice .service-conts01 .service02 li a:hover {
  color: #02A848;
}

.pagetypeservice .service-conts01 .service02 li a:hover {
  opacity: 1;
}

.pagetypeservice .service-conts01 .service02 li a .thum {
  position: relative;
  width: 160px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service02 li a .thum {
    max-width: 160px;
    width: 100%;
    margin: 0 auto;
  }
}
.pagetypeservice .service-conts01 .service02 li a .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetypeservice .service-conts01 .service02 li a .about {
  width: calc(100% - 160px - 160px);
  padding: 0 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service02 li a .about {
    width: 100%;
    padding: 0 0 20px 0;
  }
}
.pagetypeservice .service-conts01 .service02 li a .about .title {
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 10px;
  font-weight: bold;
}

.pagetypeservice .service-conts01 .service02 li a .detail {
  width: 160px;
  text-align: center;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service02 li a .detail {
    text-align: left;
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service02 li a .detail span {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
  transition: 0.4s;
}

.pagetypeservice .service-conts01 .service02 li a .detail span::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 8px;
  height: 8px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(-45deg);
}

.pagetypeservice .service-conts01 .service03 {
  margin-bottom: 40px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  align-items: center;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service03 {
    display: block;
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service03 li:first-child {
  width: 70%;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service03 li:first-child {
    display: block;
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service03 li:first-child img {
  width: 15%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service03 li:first-child img {
    max-width: 120px;
    width: 100%;
    margin: 0 auto 10px auto;
  }
}
.pagetypeservice .service-conts01 .service03 li:first-child p {
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  color: #007130;
  width: 81%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service03 li:first-child p {
    width: 100%;
    margin-bottom: 20px;
  }
}
.pagetypeservice .service-conts01 .service03 li:first-child p span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #000;
  line-height: 24px;
}

.pagetypeservice .service-conts01 .service03 li:last-child {
  width: 30%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service03 li:last-child {
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service04 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.pagetypeservice .service-conts01 .service04 li:first-child {
  width: 21%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service04 li:first-child {
    max-width: 200px;
    width: 100%;
    margin: 0 auto 20px auto;
  }
}
.pagetypeservice .service-conts01 .service04 li:last-child {
  width: 76%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service04 li:last-child {
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service05 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin-top: 40px;
}

.pagetypeservice .service-conts01 .service05 li:first-child {
  width: 21%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service05 li:first-child {
    max-width: 200px;
    width: 100%;
    margin: 0 auto 20px auto;
  }
}
.pagetypeservice .service-conts01 .service05 li:first-child img {
  border: 2px solid #f5f5f5;
}

.pagetypeservice .service-conts01 .service05 li:last-child {
  width: 76%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-conts01 .service05 li:last-child {
    width: 100%;
  }
}
.pagetypeservice .service-conts01 .service05 li:last-child span {
  font-weight: bold;
  display: block;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 10px;
  color: #007130;
}

.pagetypeservice .service-detail {
  padding: 70px 20px 0 20px;
  margin-bottom: 90px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail {
    padding: 40px 20px 0 20px;
    margin-bottom: 50px;
  }
}
.pagetypeservice .service-detail .inner1080 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  position: relative;
  padding-bottom: 60px;
}

.pagetypeservice .service-detail .thum-img {
  width: 25%;
  height: auto;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .thum-img {
    max-width: 160px;
    width: 100%;
    margin: 0 auto 30px auto;
  }
}
.pagetypeservice .service-detail .detaile-area {
  width: 70%;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area {
    width: 100%;
  }
}
.pagetypeservice .service-detail .detaile-area .title {
  color: #007130;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 38px;
  }
}
.pagetypeservice .service-detail .detaile-area .conts {
  margin-bottom: 40px;
}

.pagetypeservice .service-detail .detaile-area .conts img {
  height: auto;
  width: auto;
  max-width: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area .conts small {
    font-size: 12px;
  }
}
.pagetypeservice .service-detail .detaile-area .conts a {
  color: #007130;
}

.pagetypeservice .service-detail .detaile-area .bt-contact {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 30px 0;
  border-top: 2px solid #007130;
  border-bottom: 2px solid #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area .bt-contact {
    display: block;
  }
}
.pagetypeservice .service-detail .detaile-area .bt-contact .read {
  width: 60%;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area .bt-contact .read {
    width: 100%;
  }
}
.pagetypeservice .service-detail .detaile-area .bt-contact .btn.btn02 {
  width: 35%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .detaile-area .bt-contact .btn.btn02 {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }
}
.pagetypeservice .service-detail .sr-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .service-detail .sr-btn {
    width: 100%;
    text-align: center;
  }
}
.pagetypeservice .service-detail .sr-btn:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypeservice .service-detail .sr-btn:hover span::after {
  border-color: #02A848;
}

.pagetypeservice .service-detail .sr-btn span {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
}

.pagetypeservice .service-detail .sr-btn span::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

.pagetypeservice .kuratopi {
  padding: 0;
  margin-bottom: 90px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi {
    padding: 0;
    margin-bottom: 100px;
  }
}
.pagetypeservice .kuratopi .btn.btn02 {
  max-width: 420px;
}

.pagetypeservice .kuratopi .btn.btn02 a {
  padding: 20px 10px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .btn.btn02 a {
    padding: 10px;
  }
}
.pagetypeservice .kuratopi .mv-img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-img {
    width: 100%;
    margin-bottom: 30px;
  }
}
.pagetypeservice .kuratopi .mv-area {
  padding: 0 20px 60px;
  background: #fff;
  position: relative;
  z-index: 100;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area {
    padding: 0 0 40px;
  }
}
.pagetypeservice .kuratopi .mv-area .mv-read {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #006922;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .mv-read {
    padding: 0 20px;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.pagetypeservice .kuratopi .mv-area .bg-gray-read {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #006922;
  background: #F7F7F7;
  padding: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .bg-gray-read {
    padding: 20px;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
    text-align: left;
  }
}
.pagetypeservice .kuratopi .mv-area .flow {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow {
    max-width: 270px;
    text-align: center;
    display: block;
    width: calc(100% - 40px);
    margin: 0 auto 30px auto;
  }
}
.pagetypeservice .kuratopi .mv-area .flow::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 19px);
  height: 100%;
  border: 1px solid #007130;
  border-right: none;
  z-index: -1;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow::after {
    width: 100%;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li {
  position: relative;
  width: 20%;
  height: 64px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li {
    width: 100%;
    height: auto;
    padding: 40px 0 20px 0;
    border-right: 1px solid #007130;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li:nth-child(1) {
  border-left: 1px solid #007130;
  border-bottom: none;
}

.pagetypeservice .kuratopi .mv-area .flow li:nth-child(1) span {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li:nth-child(1) span {
    padding: 0;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li:nth-child(3), .pagetypeservice .kuratopi .mv-area .flow li:nth-child(5) {
  background: #007130;
}

.pagetypeservice .kuratopi .mv-area .flow li:nth-child(3) span, .pagetypeservice .kuratopi .mv-area .flow li:nth-child(5) span {
  color: #fff;
}

.pagetypeservice .kuratopi .mv-area .flow li:nth-child(3)::after, .pagetypeservice .kuratopi .mv-area .flow li:nth-child(5)::after {
  display: none;
}

.pagetypeservice .kuratopi .mv-area .flow li:nth-child(3)::before, .pagetypeservice .kuratopi .mv-area .flow li:nth-child(5)::before {
  left: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li:nth-child(3)::before, .pagetypeservice .kuratopi .mv-area .flow li:nth-child(5)::before {
    left: 0;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li::after, .pagetypeservice .kuratopi .mv-area .flow li::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10;
  border-width: 33px 0 33px 15px;
  border-color: transparent transparent transparent #fff;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li::after, .pagetypeservice .kuratopi .mv-area .flow li::before {
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    transform: inherit;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 135px 0 135px;
    border-color: #fff transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li::before {
  left: calc(100% + 1px);
  border-color: transparent transparent transparent #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li::before {
    left: 0;
    top: 100%;
    border-color: #007130 transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi .mv-area .flow li span {
  color: #007130;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 0 0 30px;
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .flow li span {
    font-size: 14px;
    padding: 20px 0 0 0;
  }
}
@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .inner-ttl {
    padding: 0 20px;
  }
}
.pagetypeservice .kuratopi .mv-area .btn.btn02 {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .btn.btn02 {
    width: calc(100% - 40px);
    margin-top: 30px;
  }
}
.pagetypeservice .kuratopi .mv-area .cap {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .mv-area .cap {
    padding: 0 20px;
  }
}
.pagetypeservice .kuratopi .inner-ttl {
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.pagetypeservice .kuratopi .detaile-area {
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area {
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area .inner-conts {
  margin-top: -60px;
  padding-top: 120px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area .inner-conts {
    margin-top: -40px;
    padding-top: 90px;
  }
}
.pagetypeservice .kuratopi .kc-ttl, .pagetypeservice .kuratopi .kc-ttl-sub {
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #007130;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kc-ttl, .pagetypeservice .kuratopi .kc-ttl-sub {
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }
}
.pagetypeservice .kuratopi .kc-ttl span, .pagetypeservice .kuratopi .kc-ttl-sub span {
  display: inline-block;
}

.pagetypeservice .kuratopi .kuratop-conts01 {
  background: #F8F8F8;
  padding: 60px 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 {
    padding: 30px 20px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 140px;
  position: relative;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list {
    display: block;
    margin-bottom: 130px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: -80px;
  width: 30px;
  height: 30px;
  background: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list::after {
    bottom: -70px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: -110px;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 35px 0 35px;
  border-color: #007130 transparent transparent transparent;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list::before {
    bottom: -100px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list li {
  text-align: center;
  width: calc(33.3333333333% - 10px);
  height: 274px;
  border-radius: 8px;
  background: #fff;
  border: 4px solid #007130;
  box-shadow: 4px 4px 0px #007130;
  position: relative;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #007130;
  text-shadow: 1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, -1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li {
    width: 100%;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #007130;
    height: 210px;
  }

  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:last-child {
    margin-bottom: 0;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(1) {
  background: url(../images/service/kuratopi/pict01.png) no-repeat bottom center, #fff;
  background-size: 70% auto;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(1) {
    background-size: auto 110px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(2) {
  background: url(../images/service/kuratopi/pict02.png) no-repeat bottom center, #fff;
  background-size: 70% auto;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(2) {
    background-size: auto 110px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(3) {
  background: url(../images/service/kuratopi/pict03.png) no-repeat bottom center, #fff;
  background-size: 70% auto;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li:nth-child(3) {
    background-size: auto 110px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .pict-list li div {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  width: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .pict-list li div {
    top: 20px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list {
    display: block;
    margin-bottom: 30px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list li {
  text-align: center;
  width: calc(33.3333333333% - 10px);
  background: #fff;
  border: 1px solid #007130;
  position: relative;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #007130;
  padding: 30px 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li {
    width: 100%;
    margin-bottom: 30px;
    padding: 20px;
  }

  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li:last-child {
    margin-bottom: 0;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  position: relative;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h {
    font-size: 20px;
    line-height: 32px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h::after {
  content: " ";
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
  margin: auto;
  height: 30px;
  width: 1px;
  background: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h::after {
    bottom: 13px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h div {
  font-weight: bold;
  font-style: italic;
  font-size: 70px;
  line-height: 75px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li .num-h div {
    font-size: 60px;
    line-height: 65px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts01 .num-list li .txt {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts01 .num-list li .txt {
    font-size: 16px;
    line-height: 24px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 {
  padding: 60px 20px 0;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 {
    padding: 40px 20px 0;
    margin-bottom: 60px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap01, .pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap03 {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap01, .pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap03 {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    margin-bottom: 40px;
    padding-bottom: 10px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap03 {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .tb-wrap03 {
    margin-bottom: 30px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb {
  border-collapse: collapse;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb {
    width: 1080px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr {
  border-bottom: 2px dashed #B4B4B4;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr:first-child, .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr:last-child {
  border: none;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 0;
  height: 85px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th {
    height: 75px;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th.w45 {
  width: 45%;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th.w30 {
  width: 30%;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  z-index: -1;
  width: calc(100% - 4px);
  height: 85px;
  border-radius: 8px 8px 0px 0px;
  background: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr th::after {
    height: 2000px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td {
  height: 120px;
  position: relative;
  text-align: center;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td {
    height: 110px;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  z-index: -1;
  width: calc(100% - 4px);
  height: 120px;
  background: #F8F8F8;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td::after {
    height: 2000px;
    display: block;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td:nth-child(1) {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #007130;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td:nth-child(1)::after {
  background: #EAF7F0;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb tr td:nth-child(2) {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  color: #007130;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 {
  border-collapse: collapse;
  text-align: left;
  position: relative;
  margin-bottom: 170px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 {
    margin-bottom: 130px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02::after, .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02::before {
  content: " ";
  position: absolute;
  bottom: -120px;
  left: 0;
  right: 0;
  margin: auto;
  width: 30px;
  height: 75px;
  background: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02::after, .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02::before {
    width: 20px;
    height: 50px;
    bottom: -90px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02::before {
  transform: rotate(90deg);
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr {
  border-bottom: 1px solid #007130;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr:nth-child(1) {
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr th {
  width: 30%;
  padding: 20px;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  text-align: left;
  color: #007130;
  padding: 40px 30px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr th {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 25px 0 0;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr td {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  padding: 40px 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .kc-tb02 tr td {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    display: block;
    padding: 5px 0 25px;
  }
}
.pagetypeservice .kuratopi .kuratop-conts02 .bt-cap {
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  color: #007130;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .kuratop-conts02 .bt-cap {
    font-size: 20px;
    line-height: 30px;
  }
}
.pagetypeservice .kuratopi .detaile-area02 {
  width: 70%;
  text-align: left;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 {
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .title {
  color: #007130;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .title {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 35px;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts {
  margin-top: -60px;
  padding-top: 120px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts {
    margin-top: -40px;
    padding-top: 90px;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts.inner-conts06 {
  margin-bottom: 100px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .inner-ttl {
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 tr {
  border-bottom: 1px solid #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 th {
  vertical-align: top;
  text-align: right;
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  width: 200px;
  padding: 30px 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 th {
    text-align: left;
    width: 100%;
    display: block;
    padding: 30px 0 0 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 th span {
  display: block;
  font-size: 13px;
  margin-top: 20px;
  line-height: 25px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 th span {
    margin-top: 10px;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 td {
  width: calc(100% - 200px);
  padding: 30px 0 30px 40px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 td {
    display: block;
    width: 100%;
    padding: 10px 0 30px 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 td .list-style {
  margin: 30px 0 10px 0;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb01 td img {
  margin-top: 30px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 tr {
  border-bottom: 1px solid #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 th {
  vertical-align: top;
  text-align: left;
  color: #007130;
  font-weight: 400;
  width: 200px;
  padding: 30px 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 th {
    padding: 30px 0 0 0;
    display: block;
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 td {
  width: calc(100% - 200px);
  padding: 30px 0 30px 40px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 td {
    padding: 10px 0 30px 0;
    display: block;
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb02 td .list-style {
  margin: 30px 0 10px 0;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 tr {
  border-bottom: 1px solid #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 tr:nth-child(1) {
    display: none;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 th {
  vertical-align: top;
  text-align: left;
  color: #007130;
  font-weight: 400;
  padding: 30px 0 30px 20px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 th:nth-child(1) {
  color: #007130;
  padding-left: 0;
  width: 18%;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 th:nth-child(2) {
  width: 30%;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 th:nth-child(4), .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 th:nth-child(5) {
  text-align: right;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td {
  padding: 30px 0 30px 20px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td {
    display: block;
    width: 100%;
    padding: 5px 0 0 0;
  }

  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td b.small {
    font-weight: 400;
  }

  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(1) {
    padding-top: 30px;
  }

  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:last-child {
    padding-bottom: 30px;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(1) {
  color: #007130;
  padding-left: 0;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(4), .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(5) {
  text-align: right;
  font-weight: bold;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(4) span, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb03 td:nth-child(5) span {
  font-weight: 400;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb-read {
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb-cap {
  margin: 20px 0 40px 0;
  font-size: 14px;
  line-height: 25px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .tb-cap {
    margin: 20px 0 30px 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .list-style li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 10px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .list-style li:last-child {
  margin-bottom: 0;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .list-style li::after {
  content: " ";
  position: absolute;
  top: 9px;
  left: 0;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 100%;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .cap {
  font-size: 14px;
  line-height: 25px;
  margin-top: 15px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow {
    text-align: center;
    display: block;
    max-width: 270px;
    margin: 0 auto 30px auto;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 19px);
  height: 100%;
  border: 1px solid #007130;
  border-right: none;
  z-index: -1;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow::after {
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li {
  position: relative;
  width: 20%;
  height: 64px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li {
    width: 100%;
    height: auto;
    padding: 40px 0 20px 0;
    border-right: 1px solid #007130;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(1) {
  border-left: 1px solid #007130;
  border-bottom: none;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(1) span {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(1) span {
    padding: 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(3), .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(5) {
  background: #007130;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(3) span, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(5) span {
  color: #fff;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(3)::after, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(5)::after {
  display: none;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(3)::before, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(5)::before {
  left: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(3)::before, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li:nth-child(5)::before {
    left: 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::after, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10;
  border-width: 33px 0 33px 15px;
  border-color: transparent transparent transparent #fff;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::after, .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::before {
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    transform: inherit;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 135px 0 135px;
    border-color: #fff transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::before {
  left: calc(100% + 1px);
  border-color: transparent transparent transparent #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li::before {
    left: 0;
    top: 100%;
    border-color: #007130 transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li span {
  color: #007130;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 0 0 30px;
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .flow li span {
    font-size: 14px;
    padding: 20px 0 0 0;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .gr-read {
  font-weight: bold;
  color: #007130;
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .w-clm {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin-bottom: 60px;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .w-clm li:nth-child(1) {
  width: 100%;
}

.pagetypeservice .kuratopi .detaile-area02 .inner-conts .w-clm li:nth-child(1) img {
  max-width: 300px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .inner-conts .w-clm li:nth-child(1) img {
    max-width: 80%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .inner-conts .w-clm li:nth-child(2) {
  text-align: left;
  width: 100%;
}

.pagetypeservice .kuratopi .detaile-area02 .bt-contact {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 30px 0;
  border-top: 2px solid #007130;
  border-bottom: 2px solid #007130;
  align-items: center;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .bt-contact {
    display: block;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .bt-contact .read {
  width: 60%;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .bt-contact .read {
    width: 100%;
  }
}
.pagetypeservice .kuratopi .detaile-area02 .bt-contact .btn.btn02 {
  width: 35%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .detaile-area02 .bt-contact .btn.btn02 {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }
}
.pagetypeservice .kuratopi .sr-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi .sr-btn {
    bottom: -50px;
    width: 100%;
    text-align: center;
  }
}
.pagetypeservice .kuratopi .sr-btn:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypeservice .kuratopi .sr-btn:hover span::after {
  border-color: #02A848;
}

.pagetypeservice .kuratopi .sr-btn span {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
}

.pagetypeservice .kuratopi .sr-btn span::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

.pagetypeservice .kuratopi-bt {
  position: relative;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt {
    padding: 0;
  }
}
.pagetypeservice .kuratopi-bt .inner1080-wrap {
  position: relative;
}

.pagetypeservice .kuratopi-bt .inner1080-wrap .absol {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .inner1080-wrap .absol {
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
    padding: 0 20px;
    z-index: 0;
  }
}
.pagetypeservice .kuratopi-bt .inner1080-wrap .absol .side-bar {
  background: #fff;
  border-radius: 5px;
}

.pagetypeservice .kuratopi-bt .inner1080-wrap .absol.active-side {
  animation: fadeIn 0.2s linear forwards;
  position: fixed;
  margin-top: 120px;
  height: 0;
  width: calc(100% - 40px);
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .inner1080-wrap .absol.active-side {
    animation: inherit;
    position: inherit;
    margin-top: inherit;
    height: auto;
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .inner1080 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  position: relative;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .inner1080 {
    padding-bottom: 0;
  }
}
.pagetypeservice .kuratopi-bt .mv-img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .mv-img {
    width: 100%;
    margin-bottom: 30px;
  }
}
.pagetypeservice .kuratopi-bt .mvtxt {
  color: #007130;
  font-size: 24px;
  font-weight: bold;
  line-height: 45px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .mvtxt {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 30px;
  }
}
.pagetypeservice .kuratopi-bt .side-bar {
  max-width: 240px;
  width: 25%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .side-bar {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  .pagetypeservice .kuratopi-bt .side-bar img {
    max-width: 60%;
    margin: 0 auto;
  }
}
.pagetypeservice .kuratopi-bt .side-bar .inner-nav {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi-bt .side-bar .inner-nav li {
  margin-bottom: 10px;
}

.pagetypeservice .kuratopi-bt .side-bar .inner-nav li a {
  display: block;
  color: #007130;
  transition: 0.4s;
  font-size: 14px;
  position: relative;
  padding-left: 15px;
}

.pagetypeservice .kuratopi-bt .side-bar .inner-nav li a:hover {
  color: #02A848;
}

.pagetypeservice .kuratopi-bt .side-bar .inner-nav li a:hover::after {
  border-color: #02A848;
}

.pagetypeservice .kuratopi-bt .side-bar .inner-nav li a::after {
  content: " ";
  position: absolute;
  top: -2px;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 8px;
  height: 8px;
  transition: 0.4s;
  border-right: 1px solid #007130;
  border-bottom: 1px solid #007130;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.pagetypeservice .kuratopi-bt .detaile-area {
  width: 70%;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area {
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .title {
  color: #007130;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .title {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 35px;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts {
  margin-top: -60px;
  padding-top: 120px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts {
    margin-top: -40px;
    padding-top: 90px;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts.inner-conts06 {
  margin-bottom: 100px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .inner-ttl {
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 tr {
  border-bottom: 1px solid #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 th {
  vertical-align: top;
  text-align: right;
  color: #007130;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  width: 200px;
  padding: 30px 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 th {
    text-align: left;
    width: 100%;
    display: block;
    padding: 30px 0 0 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 th span {
  display: block;
  font-size: 13px;
  margin-top: 20px;
  line-height: 25px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 th span {
    margin-top: 10px;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 td {
  width: calc(100% - 200px);
  padding: 30px 0 30px 40px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 td {
    display: block;
    width: 100%;
    padding: 10px 0 30px 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 td .list-style {
  margin: 30px 0 10px 0;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb01 td img {
  margin-top: 30px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 tr {
  border-bottom: 1px solid #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 th {
  vertical-align: top;
  text-align: left;
  color: #007130;
  font-weight: 400;
  width: 200px;
  padding: 30px 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 th {
    padding: 30px 0 0 0;
    display: block;
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 td {
  width: calc(100% - 200px);
  padding: 30px 0 30px 40px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 td {
    padding: 10px 0 30px 0;
    display: block;
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb02 td .list-style {
  margin: 30px 0 10px 0;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 {
  border-collapse: collapse;
  border-top: 2px solid #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 tr {
  border-bottom: 1px solid #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 tr:nth-child(1) {
    display: none;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 th {
  vertical-align: top;
  text-align: left;
  color: #007130;
  font-weight: 400;
  padding: 30px 0 30px 20px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 th:nth-child(1) {
  color: #007130;
  padding-left: 0;
  width: 18%;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 th:nth-child(2) {
  width: 30%;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 th:nth-child(4), .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 th:nth-child(5) {
  text-align: right;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td {
  padding: 30px 0 30px 20px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td {
    display: block;
    width: 100%;
    padding: 5px 0 0 0;
  }

  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td b.small {
    font-weight: 400;
  }

  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(1) {
    padding-top: 30px;
  }

  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:last-child {
    padding-bottom: 30px;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(1) {
  color: #007130;
  padding-left: 0;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(4), .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(5) {
  text-align: right;
  font-weight: bold;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(4) span, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb03 td:nth-child(5) span {
  font-weight: 400;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb-read {
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb-cap {
  margin: 20px 0 40px 0;
  font-size: 14px;
  line-height: 25px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .tb-cap {
    margin: 20px 0 30px 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .list-style li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 10px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .list-style li:last-child {
  margin-bottom: 0;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .list-style li::after {
  content: " ";
  position: absolute;
  top: 9px;
  left: 0;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 100%;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .cap {
  font-size: 14px;
  line-height: 25px;
  margin-top: 15px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow {
    text-align: center;
    display: block;
    max-width: 270px;
    margin: 0 auto 30px auto;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 19px);
  height: 100%;
  border: 1px solid #007130;
  border-right: none;
  z-index: -1;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow::after {
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li {
  position: relative;
  width: 20%;
  height: 64px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li {
    width: 100%;
    height: auto;
    padding: 40px 0 20px 0;
    border-right: 1px solid #007130;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(1) {
  border-left: 1px solid #007130;
  border-bottom: none;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(1) span {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(1) span {
    padding: 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(3), .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(5) {
  background: #007130;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(3) span, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(5) span {
  color: #fff;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(3)::after, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(5)::after {
  display: none;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(3)::before, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(5)::before {
  left: 100%;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(3)::before, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li:nth-child(5)::before {
    left: 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::after, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10;
  border-width: 33px 0 33px 15px;
  border-color: transparent transparent transparent #fff;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::after, .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::before {
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    transform: inherit;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 135px 0 135px;
    border-color: #fff transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::before {
  left: calc(100% + 1px);
  border-color: transparent transparent transparent #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li::before {
    left: 0;
    top: 100%;
    border-color: #007130 transparent transparent transparent;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li span {
  color: #007130;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 0 0 30px;
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .flow li span {
    font-size: 14px;
    padding: 20px 0 0 0;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .gr-read {
  font-weight: bold;
  color: #007130;
  margin-bottom: 20px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .w-clm {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin-bottom: 60px;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .w-clm li:nth-child(1) {
  width: 100%;
}

.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .w-clm li:nth-child(1) img {
  max-width: 300px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .inner-conts .w-clm li:nth-child(1) img {
    max-width: 80%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .inner-conts .w-clm li:nth-child(2) {
  text-align: left;
  width: 100%;
}

.pagetypeservice .kuratopi-bt .detaile-area .bt-contact {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 30px 0;
  border-top: 2px solid #007130;
  border-bottom: 2px solid #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .bt-contact {
    display: block;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .bt-contact .read {
  width: 60%;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .bt-contact .read {
    width: 100%;
  }
}
.pagetypeservice .kuratopi-bt .detaile-area .bt-contact .btn.btn02 {
  width: 35%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .detaile-area .bt-contact .btn.btn02 {
    max-width: 315px;
    width: 100%;
    margin: 20px auto 0 auto;
  }
}
.pagetypeservice .kuratopi-bt .sr-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeservice .kuratopi-bt .sr-btn {
    bottom: -50px;
    width: 100%;
    text-align: center;
  }
}
.pagetypeservice .kuratopi-bt .sr-btn:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypeservice .kuratopi-bt .sr-btn:hover span::after {
  border-color: #02A848;
}

.pagetypeservice .kuratopi-bt .sr-btn span {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
}

.pagetypeservice .kuratopi-bt .sr-btn span::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

@media (max-width: 768px) {
  .tb-wrap::-webkit-scrollbar {
    width: 10px;
  }

  /*スクロールバーの軌道*/
  .tb-wrap::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #F8F8F8;
  }

  /*スクロールバーの動く部分*/
  .tb-wrap::-webkit-scrollbar-thumb {
    background-color: #007130;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #007130;
  }
}
/* =========================================================
contents
========================================================= */
.pagetypecontents {
  /* =========================================================
   news
  ========================================================= */
}

.pagetypecontents .main-clm {
  padding: 60px 20px 100px 20px;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm {
    padding: 50px 20px 60px 20px;
  }
}
.pagetypecontents .main-clm .inner1080 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.pagetypecontents .main-clm .left {
  max-width: 760px;
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left {
    max-width: 100%;
    width: 100%;
  }
}
.pagetypecontents .main-clm .left .blog-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 40px;
  border-bottom: 2px solid #007130;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .blog-list {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 40px;
  }
}
.pagetypecontents .main-clm .left .blog-list li {
  width: 48%;
  margin: 0 0 50px 0;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .blog-list li {
    width: 100%;
    margin: 0 0 25px 0;
  }

  .pagetypecontents .main-clm .left .blog-list li:last-child {
    margin-bottom: 0;
  }
}
.pagetypecontents .main-clm .left .blog-list li a {
  display: block;
  transition: 0.4s;
  color: #007130;
  height: 100%;
}

.pagetypecontents .main-clm .left .blog-list li a:hover {
  opacity: 1;
}

.pagetypecontents .main-clm .left .blog-list li a:hover .title {
  text-decoration: none;
  color: #02A848;
}

.pagetypecontents .main-clm .left .blog-list li a:hover .date {
  color: #02A848;
}

.pagetypecontents .main-clm .left .blog-list li a:hover .thum {
  border-color: #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetypecontents .main-clm .left .blog-list li a:hover .thum .cate {
  background: #02A848;
}

.pagetypecontents .main-clm .left .blog-list li .thum {
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 4px solid #007130;
  box-shadow: 3px 3px 0px 0px #007130;
  margin-bottom: 15px;
  background: #f5f5f5;
}

.pagetypecontents .main-clm .left .blog-list li .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetypecontents .main-clm .left .blog-list li .thum .cate {
  transition: 0.4s;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  background: #007130;
  padding: 0 10px;
  font-size: 12px;
}

.pagetypecontents .main-clm .left .blog-list li .date {
  margin-bottom: 5px;
  font-weight: bold;
  transition: 0.4s;
}

.pagetypecontents .main-clm .left .blog-list li .title {
  text-decoration: underline;
  font-weight: bold;
  transition: 0.4s;
  font-size: 18px;
  line-height: 26px;
}

.pagetypecontents .main-clm .left .blog-list.blog-list-bt {
  justify-content: inherit;
}

.pagetypecontents .main-clm .left .blog-list.blog-list-bt li {
  width: 30%;
  margin-right: 5%;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .blog-list.blog-list-bt li {
    width: 100%;
    margin-right: 0;
  }
}
.pagetypecontents .main-clm .left .blog-list.blog-list-bt li:nth-child(3n) {
  margin-right: 0;
}

.pagetypecontents .main-clm .left .press {
  border-top: 2px solid #007130;
  padding-top: 40px;
  margin-bottom: 50px;
}

.pagetypecontents .main-clm .left .press:last-of-type {
  border-bottom: 2px solid #007130;
  padding-bottom: 40px;
}

.pagetypecontents .main-clm .left .press .thum {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .press .thum {
    margin-bottom: 20px;
  }
}
.pagetypecontents .main-clm .left .press .thum figcaption {
  color: #007130;
  margin-top: 20px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .press .thum figcaption {
    margin-top: 10px;
  }
}
.pagetypecontents .main-clm .left .press .head {
  color: #007130;
  position: relative;
  padding-left: 110px;
  margin-bottom: 30px;
}

.pagetypecontents .main-clm .left .press .head .date {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  position: absolute;
  top: 3px;
  left: 0;
}

.pagetypecontents .main-clm .left .press .head li {
  display: inline-block;
}

.pagetypecontents .main-clm .left .press .head a {
  color: #fff;
  display: inline-block;
  padding: 1px 10px;
  text-align: center;
  background: #007130;
  font-size: 12px;
  transition: 0.4s;
}

.pagetypecontents .main-clm .left .press .head a:hover {
  opacity: 1;
  background: #02A848;
}

.pagetypecontents .main-clm .left .press .title {
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  color: #007130;
  margin-bottom: 30px;
}

.pagetypecontents .main-clm .left .press .title a {
  transition: 0.4s;
  color: #007130;
  text-decoration: underline;
}

.pagetypecontents .main-clm .left .press .title a:hover {
  opacity: 1;
  color: #02A848;
}

.pagetypecontents .main-clm .left .press .wyswyg h1, .pagetypecontents .main-clm .left .press .wyswyg h2, .pagetypecontents .main-clm .left .press .wyswyg h3, .pagetypecontents .main-clm .left .press .wyswyg h4, .pagetypecontents .main-clm .left .press .wyswyg h5, .pagetypecontents .main-clm .left .press .wyswyg h6 {
  color: #007130;
}

.pagetypecontents .main-clm .left .press .wyswyg.revival img {
  margin: 10px 0;
}

.pagetypecontents .main-clm .left .press .wyswyg p {
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .press .wyswyg p {
    padding-bottom: 10px;
  }
}
.pagetypecontents .main-clm .left .press .wyswyg pre {
  line-height: 1.8;
  box-sizing: border-box;
  margin-bottom: 32px;
  max-width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;
  overflow: auto;
  padding: 20px 24px;
}

.pagetypecontents .main-clm .left .press .wyswyg img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.pagetypecontents .main-clm .left .press .wyswyg a {
  color: #007130;
  transition: 0.4s;
  text-decoration: underline;
}

.pagetypecontents .main-clm .left .press .wyswyg a:hover {
  opacity: 1;
  text-decoration: none;
}

.pagetypecontents .main-clm .left .press .wyswyg .sns {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  margin-top: 40px;
}

.pagetypecontents .main-clm .left .press .wyswyg .wp_social_bookmarking_light {
  padding: 0 !important;
}

.pagetypecontents .main-clm .left .press .wyswyg a.tw {
  display: inline-block;
  background: #1b95e0;
  border-radius: 3px;
  padding: 0 8px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  font-size: 11px;
  text-decoration: none;
}

.pagetypecontents .main-clm .left .press .wyswyg a.tw img {
  display: inline-block;
  width: 12px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.pagetypecontents .main-clm .left .press .wp_social_bookmarking_light {
  margin-top: 40px;
}

.pagetypecontents .main-clm .left .press .wp_social_bookmarking_light div {
  float: left !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 5px 0px 0 !important;
  min-height: 30px !important;
  line-height: 18px !important;
  text-indent: 0 !important;
}

.pagetypecontents .main-clm .left .press .wp_social_bookmarking_light .wsbl_hatena_button {
  position: relative;
}

.pagetypecontents .main-clm .left .press .wp_social_bookmarking_light .wsbl_hatena_button::after {
  content: " ";
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/common/dummy_b1.jpg") no-repeat center;
  background-size: contain;
  pointer-events: none;
}

.pagetypecontents .main-clm .left .bt-conts .read {
  color: #007130;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 20px;
  font-weight: bold;
}

.pagetypecontents .main-clm .left .sr-btn {
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .left .sr-btn {
    width: 100%;
    text-align: center;
  }
}
.pagetypecontents .main-clm .left .sr-btn a {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
  transition: 0.4s;
  color: #007130;
}

.pagetypecontents .main-clm .left .sr-btn a::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

.pagetypecontents .main-clm .left .sr-btn a:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypecontents .main-clm .left .sr-btn a:hover::after {
  border-color: #02A848;
}

.pagetypecontents .main-clm .right {
  max-width: 200px;
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypecontents .main-clm .right {
    max-width: 100%;
    width: 100%;
    margin-top: 40px;
  }
}
.pagetypecontents .main-clm .right .sub-block {
  border-top: 1px solid #007130;
  padding-top: 20px;
  margin-bottom: 30px;
}

.pagetypecontents .main-clm .right .sub-block.sub-block02 {
  border-top: none;
  padding-top: 0;
  margin-top: -10px;
}

.pagetypecontents .main-clm .right .sub-block:last-of-type {
  margin-bottom: 0;
}

.pagetypecontents .main-clm .right .sub-block .block-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 0;
  color: #007130;
}

.pagetypecontents .main-clm .right .sub-block .block-title.en {
  margin-bottom: 10px;
}

.pagetypecontents .main-clm .right .sub-block .block-title a {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #007130;
  transition: 0.4s;
  display: block;
  padding: 5px;
}

.pagetypecontents .main-clm .right .sub-block .block-title a span {
  font-size: 13px;
}

.pagetypecontents .main-clm .right .sub-block .block-title a:hover {
  opacity: 1;
  background: #007130;
  color: #fff;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-category li.child {
  padding-left: 20px;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-category li a {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 25px;
  position: relative;
  font-weight: bold;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-category li a::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-category li a:hover {
  color: #fff;
  opacity: 1;
  background: #007130;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-category li a:hover::after {
  border-color: #fff;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-new li a, .pagetypecontents .main-clm .right .sub-block .sub-column-list-archive li a {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 20px;
  position: relative;
  font-size: 14px;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-new li a::after, .pagetypecontents .main-clm .right .sub-block .sub-column-list-archive li a::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 5px;
  height: 5px;
  border-top: 1px solid #007130;
  border-right: 1px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-new li a:hover, .pagetypecontents .main-clm .right .sub-block .sub-column-list-archive li a:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-new li a:hover::after, .pagetypecontents .main-clm .right .sub-block .sub-column-list-archive li a:hover::after {
  border-color: #02A848;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .tgl_area {
  display: none;
  padding-left: 20px;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .a_tgl {
  color: #007130;
  display: block;
  transition: 0.4s;
  padding: 3px 0 3px 25px;
  position: relative;
  font-weight: bold;
  cursor: pointer;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .a_tgl::after {
  content: " ";
  position: absolute;
  top: 12px;
  left: 5px;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #007130;
  border-right: 2px solid #007130;
  transform: rotate(45deg);
  transition: 0.4s;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .a_tgl.active-t::after {
  transform: rotate(135deg);
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .a_tgl:hover {
  color: #02A848;
  opacity: 1;
}

.pagetypecontents .main-clm .right .sub-block .sub-column-list-archive .a_tgl:hover::after {
  border-color: #02A848;
}

/* =========================================================
recruit
========================================================= */
.pagetyperecruit {
  /* =========================================================
   recruit
  ========================================================= */
}

.pagetyperecruit .subttl.mb100 {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .pagetyperecruit .subttl.mb100 {
    margin-bottom: 40px;
  }
}
.pagetyperecruit .rec-parts01-wrap {
  background: #007130;
  padding: 0 20px 60px 20px;
  margin: -35px 0 50px 0;
  position: relative;
  z-index: 10;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-parts01-wrap {
    padding-bottom: 40px;
    margin: -45px 0 40px 0;
  }
}
.pagetyperecruit .rec-parts01-wrap .rec-parts01 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pagetyperecruit .rec-parts01-wrap .rec-parts01 li {
  width: calc(50% - 20px);
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-parts01-wrap .rec-parts01 li {
    width: calc(50% - 10px);
  }
}
.pagetyperecruit .rec-parts01-wrap .rec-parts01 li a {
  display: block;
  text-align: center;
  background: #fff;
  color: #007130;
  font-size: 20px;
  font-weight: bold;
  line-height: 56px;
  border-radius: 8px;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-parts01-wrap .rec-parts01 li a {
    font-size: 16px;
    line-height: 38px;
  }
}
.pagetyperecruit .rec-parts01-wrap .rec-parts01 li a:hover {
  background: #EDFFAF;
  opacity: 1;
}

.pagetyperecruit .rec-parts02-wrap {
  padding: 0 20px;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-parts02-wrap {
    padding: 0;
    margin-bottom: 50px;
  }
}
.pagetyperecruit .rec-parts02-wrap .rec-parts02 {
  text-align: center;
}

.pagetyperecruit .rec-parts02-wrap .rec-parts02 a {
  display: inline-block;
  padding: 0 20px;
  border-right: 1px solid #007130;
  color: #007130;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-parts02-wrap .rec-parts02 a {
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
  }
}
.pagetyperecruit .rec-parts02-wrap .rec-parts02 a:last-child {
  border: none;
}

.pagetyperecruit .rec-parts02-wrap .rec-parts02 a:hover {
  color: #02A848;
}

.pagetyperecruit .rec-parts02-wrap .rec-parts02 a.current {
  position: relative;
  pointer-events: none;
}

.pagetyperecruit .rec-parts02-wrap .rec-parts02 a.current::after {
  content: " ";
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 8px;
  width: 8px !important;
  border-radius: 100%;
  background: #007130;
}

.pagetyperecruit .rec-conts {
  padding: 0 20px;
}

.pagetyperecruit .rec-conts.massage-wrap {
  margin-bottom: 120px;
}

.pagetyperecruit .rec-conts .inner-link {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inner-link {
    margin-bottom: 60px;
  }
}
.pagetyperecruit .rec-conts .inner-link li {
  width: 32%;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inner-link li {
    width: 100%;
  }

  .pagetyperecruit .rec-conts .inner-link li:first-child a {
    border-radius: 8px 8px 0 0;
    border-top: 2px solid #007130;
  }

  .pagetyperecruit .rec-conts .inner-link li:last-child a {
    border-radius: 0 0 8px 8px;
    border-bottom: 2px solid #007130;
  }
}
.pagetyperecruit .rec-conts .inner-link li.no-press {
  opacity: 0.3;
  pointer-events: none;
}

.pagetyperecruit .rec-conts .inner-link li a {
  display: block;
  position: relative;
  border-radius: 8px;
  border: 2px solid #007130;
  color: #007130;
  font-size: 20px;
  background: #fff;
  height: 90px;
  transition: 0.4s;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inner-link li a {
    border-radius: 0;
    border-top: 1px solid #007130;
    border-bottom: 1px solid #007130;
  }
}
.pagetyperecruit .rec-conts .inner-link li a:hover {
  color: #02A848;
  border-color: #02A848;
  opacity: 1;
}

.pagetyperecruit .rec-conts .inner-link li a:hover::after {
  border-color: #02A848;
}

.pagetyperecruit .rec-conts .inner-link li a span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  padding-bottom: 15px;
}

.pagetyperecruit .rec-conts .inner-link li a span::after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.pagetyperecruit .rec-conts .clmn {
  margin-top: -100px;
  padding-top: 100px;
  margin-bottom: 120px;
  position: relative;
}

.pagetyperecruit .rec-conts .clmn:nth-of-type(1) .wclmn {
  display: block;
}

.pagetyperecruit .rec-conts .clmn.active-w .wclmn {
  animation: fadeIn 0.2s linear forwards;
  position: fixed;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1080px;
  width: calc(100% - 40px);
  height: 0;
  z-index: 100;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn.active-w .wclmn {
    animation: inherit;
    position: absolute;
    top: inherit;
    left: inherit;
    transform: inherit;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn {
    margin-top: -60px;
    padding-top: 60px;
    margin-bottom: 60px;
    padding-bottom: 80px;
  }
}
.pagetyperecruit .rec-conts .clmn .wclmn {
  width: 100%;
  position: absolute;
  top: 100px;
  display: none;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .wclmn {
    display: block;
    top: inherit;
    bottom: 0;
  }
}
.pagetyperecruit .rec-conts .clmn .wclmn .btn {
  max-width: 280px;
  margin: 0 0 0 auto;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .wclmn .btn {
    margin: 0 auto;
  }
}
.pagetyperecruit .rec-conts .clmn .title {
  width: calc(50% - 20px);
  text-align: left;
  font-size: 40px;
  line-height: 56px;
  color: #007130;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 45px;
  }
}
.pagetyperecruit .rec-conts .clmn .no-press {
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  color: #007130;
  margin-top: 30px;
}

.pagetyperecruit .rec-conts .clmn .context {
  text-align: left;
  background: #fff;
  padding: 30px 50px 50px 0;
  width: calc(100% - 300px);
  z-index: 10;
  position: relative;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .context {
    width: 100%;
    z-index: 0;
    padding: 0;
  }
}
.pagetyperecruit .rec-conts .clmn .context .thum {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}

.pagetyperecruit .rec-conts .clmn .context .thum .thum-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetyperecruit .rec-conts .clmn .context .read {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .context .read {
    margin-bottom: 20px;
  }
}
.pagetyperecruit .rec-conts .clmn .context .table-cap {
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 10px;
}

.pagetyperecruit .rec-conts .clmn .context .table-cap-sub {
  margin-bottom: 20px;
}

.pagetyperecruit .rec-conts .clmn .context .tb01 {
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
  border-top: 2px solid #007130;
}

.pagetyperecruit .rec-conts .clmn .context .tb01 tr {
  border-bottom: 1px solid #007130;
}

.pagetyperecruit .rec-conts .clmn .context .tb01 tr th {
  font-weight: bold;
  width: 160px;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .context .tb01 tr th {
    display: block;
    width: 100%;
    padding: 20px 0 0 0 !important;
  }
}
.pagetyperecruit .rec-conts .clmn .context .tb01 tr td {
  width: calc(100% - 160px);
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .clmn .context .tb01 tr td {
    display: block;
    width: 100%;
    padding: 10px 0 20px 0 !important;
  }
}
.pagetyperecruit .rec-conts .clmn .context .tb01 tr th, .pagetyperecruit .rec-conts .clmn .context .tb01 tr td {
  vertical-align: top;
  padding: 20px 20px 20px 0;
}

.pagetyperecruit .rec-conts .mass-conts {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}

.pagetyperecruit .rec-conts .mass-conts .main-img {
  margin-bottom: 50px;
}

.pagetyperecruit .rec-conts .mass-conts .txt {
  text-align: left;
  margin-bottom: 80px;
}

.pagetyperecruit .rec-conts .mass-conts .txt .read-ttl {
  color: #007130;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 24px;
}

.pagetyperecruit .rec-conts .mass-conts .txt .main-read {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 15px;
}

.pagetyperecruit .rec-conts .inv-conts {
  max-width: 760px;
  width: 100%;
  margin: 0 auto 120px auto;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list {
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-conts .inv-list {
    margin-bottom: 60px;
  }
}
.pagetyperecruit .rec-conts .inv-conts .inv-list li {
  margin-bottom: 40px;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li:last-child {
  margin-bottom: 0;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a {
  transition: 0.4s;
  color: #007130;
  text-align: left;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-conts .inv-list li a {
    display: block;
  }
}
.pagetyperecruit .rec-conts .inv-conts .inv-list li a:hover {
  opacity: 1;
  color: #02A848;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a:hover .thum {
  border-color: #02A848;
  box-shadow: 3px 3px 0px 0px #02A848;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a:hover .contents .detail span::after {
  border-color: #02A848;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .thum {
  width: calc(50% - 20px);
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 4px solid #007130;
  box-shadow: 3px 3px 0px 0px #007130;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-conts .inv-list li a .thum {
    width: 100%;
  }
}
.pagetyperecruit .rec-conts .inv-conts .inv-list li a .thum .thum-img {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents {
  width: calc(50% - 20px);
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents {
    width: 100%;
  }
}
.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .job {
  font-weight: bold;
  margin-bottom: 10px;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .name {
  font-size: 12px;
  line-height: 35px;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .name span {
  font-weight: bold;
  font-size: 29px;
  padding-right: 10px;
  display: inline-block;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .entr {
  margin-bottom: 20px;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .read {
  font-size: 12px;
  line-height: 20px;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .detail span {
  display: inline-block;
  position: relative;
  padding: 0 10px 0 0;
  font-weight: bold;
}

.pagetyperecruit .rec-conts .inv-conts .inv-list li a .contents .detail span::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 8px;
  height: 8px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.pagetyperecruit .rec-conts .inv-detail-conts {
  max-width: 760px;
  width: 100%;
  margin: 0 auto 120px auto;
}

.pagetyperecruit .rec-conts .inv-detail-conts .thum {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .thum {
    padding-bottom: 0;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .thum .catch {
  display: inline-block;
  text-align: left;
  background: #007130;
  padding: 30px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .thum .catch {
    display: block;
    width: calc(100% - 40px);
    position: inherit;
    margin-top: -40px;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .wclmn {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
}

.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .thum-img {
  width: 280px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .wclmn .thum-img {
    width: 100%;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents {
  width: calc(100% - 300px);
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents {
    width: 100%;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .job {
  font-weight: bold;
  margin-bottom: 10px;
}

.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .name {
  font-size: 12px;
  line-height: 35px;
  color: #007130;
}

.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .name span {
  font-weight: bold;
  font-size: 29px;
  padding-right: 10px;
  display: inline-block;
}

.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .entr {
  margin-bottom: 20px;
}

.pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .read {
  font-size: 12px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .wclmn .contents .read {
    margin-bottom: 20px;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .wisy {
  text-align: left;
  margin-bottom: 100px;
  margin-bottom: 50px;
}

.pagetyperecruit .rec-conts .inv-detail-conts .sr-btn {
  text-align: left;
  padding-top: 40px;
  border-top: 2px solid #007130;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .pagetyperecruit .rec-conts .inv-detail-conts .sr-btn {
    width: 100%;
    margin-top: 50px;
  }
}
.pagetyperecruit .rec-conts .inv-detail-conts .sr-btn a {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-left: 20px;
  transition: 0.4s;
  color: #007130;
}

.pagetyperecruit .rec-conts .inv-detail-conts .sr-btn a::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  transition: 0.4s;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(135deg);
  transition: 0.4s;
}

.pagetyperecruit .rec-conts .inv-detail-conts .sr-btn a:hover {
  color: #02A848;
  opacity: 1;
}

.pagetyperecruit .rec-conts .inv-detail-conts .sr-btn a:hover::after {
  border-color: #02A848;
}

/* =========================================================
about
========================================================= */
.pagetypeabout {
  /* =========================================================
   about
  ========================================================= */
}

.pagetypeabout .inner760 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.pagetypeabout .about-conts {
  padding: 70px 20px 0 20px;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts {
    padding-top: 60px;
  }
}
.pagetypeabout .about-conts .read {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
  margin-bottom: 70px;
}

.pagetypeabout .about-conts .img-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .img-list {
    display: block;
  }
}
.pagetypeabout .about-conts .img-list li {
  width: 33.3333333333%;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .img-list li {
    width: 100%;
  }
}
.pagetypeabout .about-conts .txt {
  text-align: left;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .txt {
    margin-bottom: 40px;
  }
}
.pagetypeabout .about-conts .img-pc {
  display: block;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .img-pc {
    display: none;
  }
}
.pagetypeabout .about-conts .img-sp {
  display: none;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .img-sp {
    display: block;
  }
}
.pagetypeabout .about-conts .apn {
  border: 2px solid #007130;
  padding: 30px 40px;
  text-align: left;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .apn {
    padding: 30px 20px;
    display: block;
  }
}
.pagetypeabout .about-conts .apn .side-img {
  width: 120px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .apn .side-img {
    margin: 0 auto 30px auto;
  }
}
.pagetypeabout .about-conts .apn .side-txt {
  width: calc(100% - 150px);
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .apn .side-txt {
    width: 100%;
  }
}
.pagetypeabout .about-conts .apn .side-txt p {
  margin-bottom: 10px;
}

.pagetypeabout .about-conts .apn .side-txt p span {
  color: #007130;
  display: block;
  font-size: 20px;
  line-height: 35px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .apn .side-txt p span {
    margin-bottom: 10px;
  }
}
.pagetypeabout .about-conts .apn .side-txt .bt-img {
  max-width: 280px;
  width: 100%;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .apn .side-txt .bt-img {
    margin: 20px auto 0 auto;
  }
}
.pagetypeabout .about-conts .w-clmn {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn {
    display: block;
    margin-bottom: 60px;
  }
}
.pagetypeabout .about-conts .w-clmn::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 1px;
  height: 100%;
  background: #007130;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn::after {
    display: none;
  }
}
.pagetypeabout .about-conts .w-clmn li {
  width: calc(50% - 40px);
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn li {
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #007130;
  }

  .pagetypeabout .about-conts .w-clmn li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
}
.pagetypeabout .about-conts .w-clmn li span {
  color: #007130;
  font-weight: bold;
  display: block;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

.pagetypeabout .about-conts .w-clmn02 {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn02 {
    margin-bottom: 70px;
  }
}
.pagetypeabout .about-conts .w-clmn02 li {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  text-align: left;
  margin-bottom: 30px;
}

.pagetypeabout .about-conts .w-clmn02 li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn02 li {
    display: block;
    margin-bottom: 20px;
  }
}
.pagetypeabout .about-conts .w-clmn02 li img {
  width: 200px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn02 li img {
    margin: 0 auto 20px auto;
  }
}
.pagetypeabout .about-conts .w-clmn02 li p {
  width: calc(100% - 240px);
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .w-clmn02 li p {
    text-align: left;
    width: 100%;
  }
}
.pagetypeabout .about-conts .w-clmn02 li p span {
  color: #007130;
  font-weight: bold;
  display: block;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

.pagetypeabout .about-conts .mvvss {
  padding: 0;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .mvvss {
    margin-bottom: 80px;
  }
}
.pagetypeabout .about-conts .partner {
  padding: 0;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .partner {
    margin-bottom: 40px;
  }
}
.pagetypeabout .about-conts .case {
  padding: 0;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .case {
    margin-bottom: 40px;
  }
}
.pagetypeabout .about-conts .case .case-list li {
  width: 18%;
  margin-right: 2.5% !important;
}

.pagetypeabout .about-conts .case .case-list li:last-child, .pagetypeabout .about-conts .case .case-list li:nth-child(5n) {
  margin-right: 0 !important;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .case .case-list li {
    width: 30%;
    margin-right: 5% !important;
  }

  .pagetypeabout .about-conts .case .case-list li:nth-child(5n) {
    margin-right: 5% !important;
  }

  .pagetypeabout .about-conts .case .case-list li:last-child, .pagetypeabout .about-conts .case .case-list li:nth-child(3n) {
    margin-right: 0 !important;
  }
}
.pagetypeabout .about-conts .worklife {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife {
    margin-bottom: 80px;
  }
}
.pagetypeabout .about-conts .worklife .mini-txt {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .mini-txt {
    text-align: left;
  }
}
.pagetypeabout .about-conts .worklife .w-clmns {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .w-clmns {
    display: block;
  }
}
.pagetypeabout .about-conts .worklife .w-clmns .side-img {
  width: calc(50% - 20px);
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .w-clmns .side-img {
    width: 100%;
  }
}
.pagetypeabout .about-conts .worklife .w-clmns .txt-list {
  width: calc(50% - 20px);
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .w-clmns .txt-list {
    width: 100%;
    display: block;
    margin-bottom: 40px;
  }
}
.pagetypeabout .about-conts .worklife .w-clmns .txt-list li {
  border-top: 2px solid #007130;
  padding-top: 20px;
  text-align: left;
  width: calc(50% - 20px);
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .w-clmns .txt-list li {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .pagetypeabout .about-conts .worklife .w-clmns .txt-list li:first-child {
    margin-top: 30px;
  }
}
.pagetypeabout .about-conts .worklife .w-clmns .txt-list li span {
  display: block;
  font-weight: bold;
  color: #007130;
  font-size: 18px;
  margin-bottom: 10px;
}

.pagetypeabout .about-conts .worklife .bt-txt {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .worklife .bt-txt {
    text-align: left;
  }
}
.pagetypeabout .about-conts .blog .mini-txt {
  margin-bottom: 40px;
}

.pagetypeabout .about-conts .blog .blog-list {
  width: 100%;
  margin-bottom: 20px;
}

.pagetypeabout .about-conts .blog .blog-list li {
  width: 30%;
  margin-right: 5%;
}

@media (max-width: 768px) {
  .pagetypeabout .about-conts .blog .blog-list li {
    width: 100%;
    margin-right: 0;
  }
}
.pagetypeabout .about-conts .blog .blog-list li:nth-child(3n) {
  margin-right: 0;
}

.pagetypeabout .about-conts .blog .blog-list li a:hover .thum {
  border-color: #02A848;
}

.pagetypeabout .about-conts .blog .blog-list li .thum {
  border: 4px solid #007130;
  transition: 0.4s;
}

/* =========================================================
entry
========================================================= */
.pagetypeentry,
.pagetypecontact {
  /* =========================================================
   entry
  ========================================================= */
}

.pagetypeentry .inner760,
.pagetypecontact .inner760 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.pagetypeentry .entry-conts,
.pagetypecontact .entry-conts {
  padding: 70px 20px 0 20px;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts,
.pagetypecontact .entry-conts {
    padding-top: 60px;
  }
}
.pagetypeentry .entry-conts .error-text,
.pagetypecontact .entry-conts .error-text {
  font-size: 14px;
  color: #e41616;
}

.pagetypeentry .entry-conts .read,
.pagetypecontact .entry-conts .read {
  text-align: left;
  font-size: 14px;
  margin-bottom: 60px;
}

.pagetypeentry .entry-conts .read .cl,
.pagetypecontact .entry-conts .read .cl {
  color: #007130;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pagetypeentry .entry-conts .read .bt,
.pagetypecontact .entry-conts .read .bt {
  display: block;
  margin-top: 5px;
}

.pagetypeentry .entry-conts .read .warning,
.pagetypecontact .entry-conts .read .warning {
  display: block;
  color: #e41616;
  margin-top: 5px;
}

.pagetypeentry .entry-conts .tb01,
.pagetypecontact .entry-conts .tb01 {
  border-collapse: collapse;
  margin-bottom: 40px;
  /*.file{
  	position: relative;
  	&::after{
  		content: "ファイルを選択";
  		background: #fff;
  		border: 1px solid #ccc;
  		padding: 0 8px;
  		border-radius: 4px;
  		box-shadow: 1px 1px 2px #ccc;
  		position: absolute;
  		top: 0;
  		left: 0;
  		height: 100%;
  		font-size: 12px;
  		pointer-events: none;
  	}
  }*/
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01,
.pagetypecontact .entry-conts .tb01 {
    margin-bottom: 30px;
  }
}
.pagetypeentry .entry-conts .tb01 tr,
.pagetypecontact .entry-conts .tb01 tr {
  border-top: 1px solid #007130;
}

.pagetypeentry .entry-conts .tb01 tr:first-child,
.pagetypecontact .entry-conts .tb01 tr:first-child {
  border-width: 4px;
}

.pagetypeentry .entry-conts .tb01 tr:last-child,
.pagetypecontact .entry-conts .tb01 tr:last-child {
  border-bottom: 1px solid #007130;
}

.pagetypeentry .entry-conts .tb01 th, .pagetypeentry .entry-conts .tb01 td,
.pagetypecontact .entry-conts .tb01 th,
.pagetypecontact .entry-conts .tb01 td {
  vertical-align: middle;
  padding: 30px 0;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 th, .pagetypeentry .entry-conts .tb01 td,
.pagetypecontact .entry-conts .tb01 th,
.pagetypecontact .entry-conts .tb01 td {
    display: block;
  }
}
.pagetypeentry .entry-conts .tb01 .txtarea th, .pagetypeentry .entry-conts .tb01 .txtarea td,
.pagetypecontact .entry-conts .tb01 .txtarea th,
.pagetypecontact .entry-conts .tb01 .txtarea td {
  vertical-align: top;
}

.pagetypeentry .entry-conts .tb01 th,
.pagetypecontact .entry-conts .tb01 th {
  width: 160px;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 th,
.pagetypecontact .entry-conts .tb01 th {
    padding: 20px 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
}
.pagetypeentry .entry-conts .tb01 td,
.pagetypecontact .entry-conts .tb01 td {
  max-width: calc(100% - 160px);
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 td,
.pagetypecontact .entry-conts .tb01 td {
    max-width: 100%;
    width: 100%;
    padding: 0 0 20px 0;
  }
}
.pagetypeentry .entry-conts .tb01 td p,
.pagetypecontact .entry-conts .tb01 td p {
  margin-top: 10px;
  font-size: 14px;
}

.pagetypeentry .entry-conts .tb01 textarea,
.pagetypecontact .entry-conts .tb01 textarea {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 160px;
  padding: 10px;
}

.pagetypeentry .entry-conts .tb01 input[type=tel],
.pagetypeentry .entry-conts .tb01 input[type=email],
.pagetypeentry .entry-conts .tb01 input[type=text],
.pagetypecontact .entry-conts .tb01 input[type=tel],
.pagetypecontact .entry-conts .tb01 input[type=email],
.pagetypecontact .entry-conts .tb01 input[type=text] {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 48px;
  padding: 5px 10px;
}

.pagetypeentry .entry-conts .tb01 textarea::placeholder,
.pagetypeentry .entry-conts .tb01 input[type=tel]::placeholder,
.pagetypeentry .entry-conts .tb01 input[type=email]::placeholder,
.pagetypeentry .entry-conts .tb01 input[type=text]::placeholder,
.pagetypecontact .entry-conts .tb01 textarea::placeholder,
.pagetypecontact .entry-conts .tb01 input[type=tel]::placeholder,
.pagetypecontact .entry-conts .tb01 input[type=email]::placeholder,
.pagetypecontact .entry-conts .tb01 input[type=text]::placeholder {
  color: #ccc;
}

.pagetypeentry .entry-conts .tb01 span,
.pagetypecontact .entry-conts .tb01 span {
  padding: 0 25px 0 10px;
}

.pagetypeentry .entry-conts .tb01 .w100, .pagetypeentry .entry-conts .tb01 .year, .pagetypeentry .entry-conts .tb01 .month, .pagetypeentry .entry-conts .tb01 .days,
.pagetypecontact .entry-conts .tb01 .w100,
.pagetypecontact .entry-conts .tb01 .year,
.pagetypecontact .entry-conts .tb01 .month,
.pagetypecontact .entry-conts .tb01 .days {
  position: relative;
}

.pagetypeentry .entry-conts .tb01 .w100::after, .pagetypeentry .entry-conts .tb01 .year::after, .pagetypeentry .entry-conts .tb01 .month::after, .pagetypeentry .entry-conts .tb01 .days::after,
.pagetypecontact .entry-conts .tb01 .w100::after,
.pagetypecontact .entry-conts .tb01 .year::after,
.pagetypecontact .entry-conts .tb01 .month::after,
.pagetypecontact .entry-conts .tb01 .days::after {
  content: " ";
  position: absolute;
  top: -2px;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(45deg);
  pointer-events: none;
}

.pagetypeentry .entry-conts .tb01 .w100 select, .pagetypeentry .entry-conts .tb01 .year select, .pagetypeentry .entry-conts .tb01 .month select, .pagetypeentry .entry-conts .tb01 .days select,
.pagetypecontact .entry-conts .tb01 .w100 select,
.pagetypecontact .entry-conts .tb01 .year select,
.pagetypecontact .entry-conts .tb01 .month select,
.pagetypecontact .entry-conts .tb01 .days select {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 48px;
  padding: 5px 10px;
}

.pagetypeentry .entry-conts .tb01 .year,
.pagetypecontact .entry-conts .tb01 .year {
  width: 25%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 .year,
.pagetypecontact .entry-conts .tb01 .year {
    width: 40%;
  }
}
.pagetypeentry .entry-conts .tb01 .month,
.pagetypecontact .entry-conts .tb01 .month {
  width: 15%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 .month,
.pagetypecontact .entry-conts .tb01 .month {
    width: 25%;
    margin-top: 15px;
  }
}
.pagetypeentry .entry-conts .tb01 .days,
.pagetypecontact .entry-conts .tb01 .days {
  width: 15%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .tb01 .days,
.pagetypecontact .entry-conts .tb01 .days {
    width: 25%;
    margin-top: 15px;
  }
}
.pagetypeentry .entry-conts .check-policy,
.pagetypecontact .entry-conts .check-policy {
  text-align: center;
  color: #007130;
  font-size: 14px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypeentry .entry-conts .check-policy,
.pagetypecontact .entry-conts .check-policy {
    margin-bottom: 30px;
  }
}
.pagetypeentry .entry-conts .check-policy a,
.pagetypecontact .entry-conts .check-policy a {
  text-decoration: underline;
  transition: 0.4s;
  color: #007130;
}

.pagetypeentry .entry-conts .check-policy a:hover,
.pagetypecontact .entry-conts .check-policy a:hover {
  color: #02A848;
}

.pagetypeentry .entry-conts .check-policy input[type=checkbox]:checked,
.pagetypecontact .entry-conts .check-policy input[type=checkbox]:checked {
  cursor: pointer;
  background: #007130;
}

.pagetypeentry .entry-conts .submit,
.pagetypecontact .entry-conts .submit {
  cursor: pointer;
  display: block;
  appearance: none;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  background: #007130;
  color: #fff;
  transition: 0.4s;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  line-height: 32px;
  padding: 10px;
}

.pagetypeentry .entry-conts .submit:hover,
.pagetypecontact .entry-conts .submit:hover {
  background: #02A848;
}

.pagetypeentry .entry-conts .submit:disabled,
.pagetypecontact .entry-conts .submit:disabled {
  background: #ccc;
}

.pagetypecontact .entry-conts .read {
  font-size: 16px;
}

.pagetypecontact .entry-conts .read a {
  color: #007130;
  transition: 0.4s;
}

.pagetypecontact .entry-conts .read a:hover {
  color: #02A848;
}

.pagetypecontact .entry-conts .read .bt {
  font-weight: 400;
  font-size: 14px;
}

.pagetypecontact .entry-conts .read .warning {
  font-weight: 400;
  font-weight: bold;
  font-size: 16px;
}

.pagetypeentry .entry-conts .read {
  font-size: 16px;
}

.pagetypeentry .entry-conts .read a {
  color: #007130;
  transition: 0.4s;
}

.pagetypeentry .entry-conts .read a:hover {
  color: #02A848;
}

.pagetypeentry .entry-conts .read .bt {
  font-weight: 400;
  font-size: 14px;
}

.pagetypeentry .entry-conts .read .warning {
  font-weight: 400;
  font-weight: bold;
  font-size: 16px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .pagetypeentry .entry-conts .tb01 .w100::after, .pagetypeentry .entry-conts .tb01 .year::after, .pagetypeentry .entry-conts .tb01 .month::after, .pagetypeentry .entry-conts .tb01 .days::after, .pagetypecontact .entry-conts .tb01 .w100::after, .pagetypecontact .entry-conts .tb01 .year::after, .pagetypecontact .entry-conts .tb01 .month::after, .pagetypecontact .entry-conts .tb01 .days::after {
    display: none;
  }

  /* IE11 */
}
/* =========================================================
company
========================================================= */
.pagetypecompany {
  /* =========================================================
   company
  ========================================================= */
}

.pagetypecompany .inner760 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.pagetypecompany .company-conts {
  padding: 80px 20px 0 20px;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts {
    padding: 60px 0 0 0;
    margin-bottom: 60px;
  }
}
.pagetypecompany .company-conts .g-map iframe {
  border: none;
  width: 100%;
  height: 360px;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .g-map iframe {
    height: 200px;
  }
}
.pagetypecompany .company-conts .undertxt {
  margin-top: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .undertxt {
    width: calc(100% - 40px);
    margin: 20px auto 0 auto;
  }
}
.pagetypecompany .company-conts .undertxt b {
  font-size: 19px;
  line-height: 32px;
  display: block;
  margin-bottom: 10px;
}

.pagetypecompany .company-conts .read {
  text-align: left;
  font-size: 14px;
  margin-bottom: 60px;
}

.pagetypecompany .company-conts .read .cl {
  color: #007130;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pagetypecompany .company-conts .read .bt {
  display: block;
  margin-top: 5px;
}

.pagetypecompany .company-conts .tb01 {
  border-collapse: collapse;
  margin-bottom: 80px;
  /*.file{
  	position: relative;
  	&::after{
  		content: "ファイルを選択";
  		background: #fff;
  		border: 1px solid #ccc;
  		padding: 0 8px;
  		border-radius: 4px;
  		box-shadow: 1px 1px 2px #ccc;
  		position: absolute;
  		top: 0;
  		left: 0;
  		height: 100%;
  		font-size: 12px;
  		pointer-events: none;
  	}
  }*/
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 {
    margin-bottom: 30px;
    width: calc(100% - 40px);
    margin: 0 auto 60px auto;
  }
}
.pagetypecompany .company-conts .tb01 tr {
  border-top: 1px solid #007130;
}

.pagetypecompany .company-conts .tb01 tr:first-child {
  border-width: 4px;
}

.pagetypecompany .company-conts .tb01 tr:last-child {
  border-bottom: 1px solid #007130;
}

.pagetypecompany .company-conts .tb01 th, .pagetypecompany .company-conts .tb01 td {
  vertical-align: top;
  padding: 30px 0;
  text-align: left;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 th, .pagetypecompany .company-conts .tb01 td {
    display: block;
  }
}
.pagetypecompany .company-conts .tb01 .txtarea th, .pagetypecompany .company-conts .tb01 .txtarea td {
  vertical-align: top;
}

.pagetypecompany .company-conts .tb01 th {
  width: 160px;
  color: #007130;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 th {
    padding: 20px 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
}
.pagetypecompany .company-conts .tb01 td {
  max-width: calc(100% - 160px);
  width: 70%;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 td {
    max-width: 100%;
    width: 100%;
    padding: 0 0 20px 0;
  }
}
.pagetypecompany .company-conts .tb01 td p {
  margin-top: 10px;
  font-size: 14px;
}

.pagetypecompany .company-conts .tb01 textarea {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 160px;
  padding: 10px;
}

.pagetypecompany .company-conts .tb01 input[type=tel],
.pagetypecompany .company-conts .tb01 input[type=email],
.pagetypecompany .company-conts .tb01 input[type=text] {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 48px;
  padding: 5px 10px;
}

.pagetypecompany .company-conts .tb01 textarea::placeholder,
.pagetypecompany .company-conts .tb01 input[type=tel]::placeholder,
.pagetypecompany .company-conts .tb01 input[type=email]::placeholder,
.pagetypecompany .company-conts .tb01 input[type=text]::placeholder {
  color: #ccc;
}

.pagetypecompany .company-conts .tb01 span {
  padding: 0 25px 0 10px;
}

.pagetypecompany .company-conts .tb01 .w100, .pagetypecompany .company-conts .tb01 .year, .pagetypecompany .company-conts .tb01 .month, .pagetypecompany .company-conts .tb01 .days {
  position: relative;
}

.pagetypecompany .company-conts .tb01 .w100::after, .pagetypecompany .company-conts .tb01 .year::after, .pagetypecompany .company-conts .tb01 .month::after, .pagetypecompany .company-conts .tb01 .days::after {
  content: " ";
  position: absolute;
  top: -2px;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-right: 2px solid #007130;
  border-bottom: 2px solid #007130;
  transform: rotate(45deg);
  pointer-events: none;
}

.pagetypecompany .company-conts .tb01 .w100 select, .pagetypecompany .company-conts .tb01 .year select, .pagetypecompany .company-conts .tb01 .month select, .pagetypecompany .company-conts .tb01 .days select {
  width: 100%;
  cursor: pointer;
  appearance: none;
  display: block;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  outline: none;
  font-size: 16px;
  height: 48px;
  padding: 5px 10px;
}

.pagetypecompany .company-conts .tb01 .year {
  width: 25%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 .year {
    width: 40%;
  }
}
.pagetypecompany .company-conts .tb01 .month {
  width: 15%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 .month {
    width: 25%;
    margin-top: 15px;
  }
}
.pagetypecompany .company-conts .tb01 .days {
  width: 15%;
  display: inline-block;
  font-variant: middle;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .tb01 .days {
    width: 25%;
    margin-top: 15px;
  }
}
.pagetypecompany .company-conts .check-policy {
  text-align: center;
  color: #007130;
  font-size: 14px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .pagetypecompany .company-conts .check-policy {
    margin-bottom: 30px;
  }
}
.pagetypecompany .company-conts .check-policy a {
  text-decoration: underline;
  transition: 0.4s;
  color: #007130;
}

.pagetypecompany .company-conts .check-policy a:hover {
  color: #02A848;
}

.pagetypecompany .company-conts .check-policy input[type=checkbox]:checked {
  cursor: pointer;
  background: #007130;
}

.pagetypecompany .company-conts .submit {
  cursor: pointer;
  display: block;
  appearance: none;
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
  background: #007130;
  color: #fff;
  transition: 0.4s;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  line-height: 32px;
  padding: 10px;
}

.pagetypecompany .company-conts .submit:hover {
  background: #02A848;
}

.pagetypecompany .company-conts .submit:disabled {
  background: #ccc;
}

.pagetypecontact .company-conts .read {
  font-weight: bold;
  font-size: 16px;
}

.pagetypecontact .company-conts .read a {
  color: #007130;
  transition: 0.4s;
}

.pagetypecontact .company-conts .read a:hover {
  color: #02A848;
}

.pagetypecontact .company-conts .read .bt {
  font-weight: 400;
  font-size: 14px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .pagetypecompany .company-conts .tb01 .w100::after, .pagetypecompany .company-conts .tb01 .year::after, .pagetypecompany .company-conts .tb01 .month::after, .pagetypecompany .company-conts .tb01 .days::after, .pagetypecontact .company-conts .tb01 .w100::after, .pagetypecontact .company-conts .tb01 .year::after, .pagetypecontact .company-conts .tb01 .month::after, .pagetypecontact .company-conts .tb01 .days::after {
    display: none;
  }

  /* IE11 */
}
/* =========================================================
privacypolicy
========================================================= */
.pagetypeprivacypolicy {
  /* =========================================================
   privacypolicy
  ========================================================= */
}

.pagetypeprivacypolicy .read {
  font-weight: bold;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .pagetypeprivacypolicy .read {
    margin-bottom: 40px;
  }
}
.pagetypeprivacypolicy .inner760 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: left;
}

.pagetypeprivacypolicy .privacypolicy-conts {
  padding: 80px 20px 0 20px;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .pagetypeprivacypolicy .privacypolicy-conts {
    padding: 60px 20px 0 20px;
    margin-bottom: 100px;
  }
}
.pagetypeprivacypolicy .plist li {
  margin-bottom: 40px;
}

.pagetypeprivacypolicy .plist li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .pagetypeprivacypolicy .plist li {
    margin-bottom: 30px;
  }
}
.pagetypeprivacypolicy .plist li .midashi {
  color: #007130;
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.pagetypeprivacypolicy .plist li a {
  color: #007130;
  text-decoration: underline;
  transition: 0.4s;
}

.pagetypeprivacypolicy .plist li a:hover {
  opacity: 1;
  color: #02A848;
  text-decoration: none;
}