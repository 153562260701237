.pagetypecontents{

/* =========================================================
 news
========================================================= */

.main-clm{
	padding: 60px 20px 100px 20px;
    @include sp{
        padding: 50px 20px 60px 20px;
    }
	.inner1080{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: left;
	}
    .left{
    	max-width: 760px;
    	width: 70%;
        @include sp{
            max-width: 100%;
            width: 100%;
        }

        .blog-list{
            display: -webkit-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 40px;
            border-bottom: 2px solid #007130;
            @include sp{
                display: block;
                margin-bottom: 30px;
                padding-bottom: 40px;
            }
            li{
                width: 48%;
                margin: 0 0 50px 0;
                @include sp{
                    width: 100%;
                    margin: 0 0 25px 0;
                    &:last-child{ margin-bottom: 0; }
                }
                a{
                    display: block;
                    transition: 0.4s;
                    color: #007130;
                    height: 100%;
                    &:hover{
                        opacity: 1;
                        .title{
                            text-decoration: none;
                            color: #02A848;
                        }
                        .date{
                            color: #02A848;
                        }
                        .thum{
                            border-color: #02A848;
                            box-shadow: 3px 3px 0px 0px #02A848;
                            .cate{
                                background: #02A848;
                            }
                        }
                    }
                }
                .thum{
                    transition: 0.4s;
                    position: relative;
                    overflow: hidden;
                    border-radius: 8px;
                    border: 4px solid #007130;
                    box-shadow: 3px 3px 0px 0px #007130;
                    margin-bottom: 15px;
                    background: #f5f5f5;
                    @include sp{
                    }
                    .thum-img{
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                    .cate{
                        transition: 0.4s;
                        display: inline-block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        color: #fff;
                        background: #007130;
                        padding: 0 10px;
                        font-size: 12px;
                    }
                }
                .date{
                    margin-bottom: 5px;
                    font-weight: bold;
                    transition: 0.4s;
                }
                .title{
                    text-decoration: underline;
                    font-weight: bold;
                    transition: 0.4s;
                    font-size: 18px;
                    line-height: 26px;
                }
            }
            &.blog-list-bt{
                justify-content: inherit;
                li{
                    width: 30%;
                    margin-right: 5%;
                    @include sp{
                        width: 100%;
                        margin-right: 0;
                    }
                    &:nth-child(3n){ margin-right: 0; }
                }
            }
        }

        .press{
            border-top: 2px solid #007130;
            padding-top: 40px;
            margin-bottom: 50px;
            &:last-of-type{
                border-bottom: 2px solid #007130;
                padding-bottom: 40px;
            }
            .thum{
                margin-bottom: 40px;
                @include sp{
                    margin-bottom: 20px;
                }
                figcaption{
                    color: #007130;
                    margin-top: 20px;
                    font-size: 12px;
                    @include sp{
                        margin-top: 10px;
                    }
                }
            }
            .head{
                color: #007130;
                position: relative;
                padding-left: 110px;
                margin-bottom: 30px;
                .date{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
                li{ display: inline-block; }
                a{
                    color: #fff;
                    display: inline-block;
                    padding: 1px 10px;
                    text-align: center;
                    background: #007130;
                    font-size: 12px;
                    transition: 0.4s;
                    &:hover{
                        opacity: 1;
                        background: #02A848;
                    }
                }
            }
            .title{
                font-size: 32px;
                font-weight: bold;
                line-height: 48px;
                color: #007130;
                margin-bottom: 30px;
                a{
                    transition: 0.4s;
                    color: #007130;
                    text-decoration: underline;
                    &:hover{
                        opacity: 1;
                        color: #02A848;
                    }
                }
            }
            .wyswyg{
                h1,h2,h3,h4,h5,h6{ color: #007130; }
                &.revival{
                    img{
                        margin: 10px 0;
                    }
                }
                p{
                    padding-bottom: 30px;
                    @include sp{ padding-bottom: 10px; }
                }
                pre{
                    line-height: 1.8;
                    box-sizing: border-box;
                    margin-bottom: 32px;
                    max-width: 100%;
                    white-space: pre-wrap;
                    overflow-wrap: break-word;
                    font-size: 14px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(0, 0, 0, 0.1);
                    border-image: initial;
                    overflow: auto;
                    padding: 20px 24px;
                }
                img{ height: auto; width: auto; max-width: 100%; }
                a{
                    color: #007130;
                    transition: 0.4s;
                    text-decoration: underline;
                    &:hover{
                        opacity: 1;
                        text-decoration: none;
                    }
                }
                .sns{
                    display: -webkit-box;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    width: 100%;
                    text-align: left;
                    margin-top: 40px;
                }
                .wp_social_bookmarking_light{
                    padding: 0!important;
                }
                a.tw{
                    display: inline-block;
                    background: #1b95e0;
                    border-radius: 3px;
                    padding: 0 8px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 20px;
                    font-size: 11px;
                    text-decoration: none;
                    img{
                        display: inline-block;
                        width: 12px;
                        margin: 0 5px 0 0;
                        vertical-align: middle;
                    }
                }
            }
            .wp_social_bookmarking_light{
                margin-top: 40px;
                div{
                    float: left !important;
                    border: 0 !important;
                    padding: 0 !important;
                    margin: 0 5px 0px 0 !important;
                    min-height: 30px !important;
                    line-height: 18px !important;
                    text-indent: 0 !important;
                }
                .wsbl_hatena_button{
                    position: relative;
                    &::after{
                        content: " ";
                        position: absolute;
                        top: -6px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: url('../images/common/dummy_b1.jpg') no-repeat center;
                        background-size: contain;
                        pointer-events: none;
                    }
                }
            }
        }
        .bt-conts{
            .read{
                color: #007130;
                font-size: 20px;
                line-height: 35px;
                margin-bottom: 20px;
                font-weight: bold;
            }
        }
        .sr-btn{
            text-align: left;
            @include sp{
                width: 100%;
                text-align: center;
            }
            a{
                display: inline-block;
                position: relative;
                font-weight: bold;
                padding-left: 20px;
                transition: 0.4s;
                color: #007130;
                &::after{
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    display: block;
                    width: 10px;
                    height: 10px;
                    transition: 0.4s;
                    border-right: 2px solid #007130;
                    border-bottom: 2px solid #007130;
                    transform: rotate(135deg);
                    transition: 0.4s;
                }
                &:hover{
                    color: #02A848;
                    opacity: 1;
                    &::after{
                        border-color: #02A848;
                    }
                }
            }
        }
    }
    .right{
    	max-width: 200px;
    	width: 70%;
        @include sp{
            max-width: 100%;
            width: 100%;
            margin-top: 40px;
        }
    	.sub-block{
    		border-top: 1px solid #007130;
    		padding-top: 20px;
    		margin-bottom: 30px;
            &.sub-block02{
                border-top: none;
                padding-top: 0;
                margin-top: -10px;
            }
            &:last-of-type{
                margin-bottom: 0;
            }
    		.block-title{
    			font-size: 16px;
    			font-weight: bold;
    			line-height: 24px;
    			margin-bottom: 0;
    			color: #007130;
                &.en{
                    margin-bottom: 10px;
                }
                a{
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 24px;
                    color: #007130;
                    transition: 0.4s;
                    display: block;
                    padding: 5px;
                    span{
                        font-size: 13px;
                    }
                    &:hover{
                        opacity: 1;
                        background: #007130;
                        color: #fff;
                    }
                }
    		}
    		.sub-column-list-category{
    			li{
                    &.child{
                        padding-left: 20px;
                    }
    				a{
    					color: #007130;
    					display: block;
    					transition: 0.4s;
    					padding: 3px 0 3px 25px;
    					position: relative;
    					font-weight: bold;
    					&::after{
    						content: " ";
    						position: absolute;
    						top: 12px;
    						left: 5px;
							display: block;
							width: 8px;
							height: 8px;
							border-top: 2px solid #007130;
							border-right: 2px solid #007130;
							transform: rotate(45deg);
							transition: 0.4s;
    					}
    					&:hover{
    						color: #fff;
    						opacity: 1;
    						background: #007130;
    						&::after{
    							border-color: #fff;
    						}
    					}
    				}
    			}
    		}
    		.sub-column-list-new,.sub-column-list-archive{
    			li{
    				a{
    					color: #007130;
    					display: block;
    					transition: 0.4s;
    					padding: 3px 0 3px 20px;
    					position: relative;
    					font-size: 14px;
    					&::after{
    						content: " ";
    						position: absolute;
    						top: 12px;
    						left: 5px;
							display: block;
							width: 5px;
							height: 5px;
							border-top: 1px solid #007130;
							border-right: 1px solid #007130;
							transform: rotate(45deg);
							transition: 0.4s;
    					}
    					&:hover{
    						color: #02A848;
    						opacity: 1;
    						&::after{
    							border-color: #02A848;
    						}
    					}
    				}
    			}
    		}
            .sub-column-list-archive{
                .tgl_area{
                    display: none;
                    padding-left: 20px;
                }
                .a_tgl{
                    color: #007130;
                    display: block;
                    transition: 0.4s;
                    padding: 3px 0 3px 25px;
                    position: relative;
                    font-weight: bold;
                    cursor: pointer;
                        &::after{
                            content: " ";
                            position: absolute;
                            top: 12px;
                            left: 5px;
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid #007130;
                            border-right: 2px solid #007130;
                            transform: rotate(45deg);
                            transition: 0.4s;
                        }
                        &.active-t::after{
                            transform: rotate(135deg);
                        }
                        &:hover{
                            color: #02A848;
                            opacity: 1;
                            &::after{
                                border-color: #02A848;
                            }
                        }
                }
            }
    	}
    }
}



} // news