.pagetypehome{

/* =========================================================
  home
========================================================= */

/* MV
---------------------------*/
.mv{
	width: 100%;
	height: calc( 100vh - 85px );
	background: url('../images/home/mv.jpg') no-repeat center;
	background-size: cover;
	position: relative;
	overflow: hidden;
	transition: 0.7s;
	opacity: 0;
	&.loaded{
		opacity: 1;
		.txt-area .large-txt{
			opacity: 1;
			transform: translateX(0);
		}
		.txt-area .main-txt{
			opacity: 1;
			transform: translateY(0);
			height: 100px;
			@media screen and (max-width:1140px) {
				height: 60px;
			}
			@media (max-width:768px) {
				height: 60px;
			}
			@include sp{
				height: 290px;
			}
		}
	}
	@include sp{
		height: calc( 100vh - 61px );
		background: url('../images/home/mv_sp.jpg') no-repeat center;
		background-size: cover;
	}
	.txt-area{
		text-align: left;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translateY(-50%) translateX(-50%);
	    max-width: 920px;
	    width: calc(75% - 20px);
	    color: #fff;
	    @include sp{
	    	top: 47%;
	    	width: calc(100% - 30px);
	    }
	    .large-txt{
	    	font-weight: 600;
	    	font-size: 80px;
	    	line-height: 80px;
	    	margin-bottom: 5%;
	    	opacity: 0;
	    	transition: 1s;
	    	transition-delay: 0.7s;
	    	transform: translateX(50px);
	    	@media screen and (max-width:1140px) {
	    		font-size: 50px;
	    		line-height: 50px;
	    		margin-bottom: 3%;
	    	}
		    @include sp{
		    	margin-bottom: 20px;
		    }
	    }
	    .main-txt{
	    	font-size: 36px;
	    	line-height: 56px;
	    	opacity: 0;
	    	height: 0;
	    	transition: 1.1s;
	    	transition-delay: 1.7s;
	    	transform: translateY(50px);
	    	@media screen and (max-width:1140px) {
	    		font-size: 24px;
	    		line-height: 32px;
	    	}
	    	span{
	    		display: block;
	    		font-size: 16px;
	    		line-height: 24px;
	    		margin-top: 5%;
		    	@media screen and (max-width:1140px) {
		    		margin-top: 3%;
		    	}
			    @include sp{
			    	margin-top: 20px;
			    }
	    	}
	    	a{
	    		display: inline-block;
	    		padding: 0 10px 0 0;
	    		position: relative;
	    		font-size: 16px;
	    		line-height: 24px;
	    		font-weight: bold;
	    		color: #fff;
	    		transition: 0.4s;
	    		&::after{
	    			content: " ";
	    			position: absolute;
	    			top: 0;
	    			right: 0;
	    			bottom: 0;
	    			margin: auto;
	    			display: block;
	    			width: 10px;
	    			height: 10px;
	    			border-right: 2px solid #fff;
	    			border-bottom: 2px solid #fff;
	    			transform: rotate(-45deg);
	    		}
	    	}
	    }
	}
}

/* MV-BT
---------------------------*/
.mv-bt .mv-bt-wrap::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
}
.mv-bt{
	padding: 0 0 50px 0;
	margin-top: -40px;
    position: relative;
    overflow: hidden;
    z-index: 10;     /* Firefox 対応 */
	@include sp{
		margin-top: -60px;
	}
	.mv-bt-wrap{
		@media screen and (max-width:1000px) {
			-webkit-overflow-scrolling: touch;
			overflow-x: scroll;
			-ms-overflow-style: none;    /* IE, Edge 対応 */
		    scrollbar-width: none;
		}
	}
    .inner920.inner920-01{
    	max-width: 960px;
    	width: 960px;
    	padding: 0 20px;
    }
	.mv-bt-list{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: center;
	    li{
	    	width: 30%;
	    	border-radius: 8px;
	    	border: 4px solid #007130;
	    	box-shadow: 3px 3px 0px 0px #007130;
	    	background: #fff;
	    	transition: 0.4s;
	    	color: #007130;
	    	font-weight: bold;
	    	cursor: pointer;
	    	padding: 15px 10px;
	    	font-size: 20px;
	    	line-height: 24px;
	    	&:hover{
	    		color: #02A848;
	    		border: 4px solid #02A848;
	    		box-shadow: 3px 3px 0px 0px #02A848;
				.icon-ajaile,
				.icon-engineer,
				.icon-onestop{
					stroke: #02A848;
				}
	    	}
	    	&.on{
	    		color: #fff!important;
	    		background: #007130!important;
	    		pointer-events: none;
	    		box-shadow: inherit;
				.icon-ajaile,
				.icon-engineer,
				.icon-onestop{
					stroke: #fff!important;
				}
	    	}
	    	.thum-svg{
	    		margin-bottom: 15px;
	    		&.thum-svg03{
	    			margin-bottom: 25px;
	    		}
	    	}
	    }
	}
	.hid-area{
		border: 2px solid #007130;
		padding: 40px 10px;
		margin-top: 50px;
		color: #007130;
		position: relative;
		display: none;
		@include sp{
			border-right: none;
			border-left: none;
			padding: 40px 20px;
		}
		&::after{
			content: " ";
			position: absolute;
			top: -24px;
			right: 13%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 20px 25px 20px;
			border-color: transparent transparent #ffffff transparent;
			@include sp{
				left: inherit;
				right: calc(50% - 11px);
			}
		}
		&::before{
			content: " ";
			position: absolute;
			top: -27px;
			right: calc(13% - 2px);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 22px 27px 22px;
			border-color: transparent transparent #007130 transparent;
			@include sp{
				left: inherit;
				right: calc(50% - 13px);
			}
		}
		&#hid-area02::after{
			right: calc(50% - 11px);
		}
		&#hid-area02::before{
			right: calc(50% - 13px);
		}
		&#hid-area01::after{
			right: inherit;
			left: 13%;
			@include sp{
				left: inherit;
				right: calc(50% - 11px);
			}
		}
		&#hid-area01::before{
			right: inherit;
			left: calc(13% - 2px);
			@include sp{
				left: inherit;
				right: calc(50% - 13px);
			}
		}
		.hid-area-wrap{
			max-width: 760px;
			width: 100%;
			margin: 0 auto;
			text-align: left;
			@include sp{
				max-width: 100%;
			}
		}
		.read{
			font-weight: bold;
			font-size: 20px;
			line-height: 32px;
			margin-bottom: 10px;
			@include sp{
				margin-bottom: 20px;
			}
		}
		.flex{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    align-items: flex-start;
		    @include sp{
		    	display: block;
		    }
		    span{
		    	display: block;
		    	width: calc(100% - 240px);
		    	@include sp{
		    		width: 100%;
		    	}
		    	&.w100{
		    		width: 100%;
		    	}
		    }
		    a{
		    	display: block;
		    	width: 200px;
		    	text-align: center;
		    	border: 2px solid #007130;
		    	padding: 5px;
		    	transition: 0.4s;
		    	color: #007130;
		    	font-weight: bold;
		    	@include sp{
		    		width: 100%;
		    		margin-top: 30px;
		    	}
		    	&:hover{
		    		opacity: 1;
		    		color: #02A848;
		    		border-color: #02A848;
		    	}
		    }
		}
	}
}


/* SERVICE
---------------------------*/
.service{
	padding: 80px 20px 0 20px;
	margin-bottom: 100px;
	position: relative;
	z-index: 0;
	@include sp{
		margin-bottom: 80px;
	}
	&::after{
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 360px;
		background: #007130;
		z-index: -1;
	}
	.subttl{
		color: #fff;
		margin-bottom: 120px;
		&::after{
			background: #fff;
		}
	}
	.service-list{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    margin-bottom: 70px;
	    @include sp{
	    	display: block;
	    	margin-bottom: 50px;
	    }
	    li{
	    	width: calc(50% - 20px);
	    	text-align: center;
	    	display: none;
	    	&.w100{
	    		width: 100%;
	    		text-align: center;
	    		font-weight: bold;
	    		color: #fff;
	    	}
	    	&:nth-child(1),&:nth-child(2){
	    		display: block;
	    	}
	    	@include sp{
	    		width: 100%;
	    		margin-bottom: 110px;
	    		&:last-child{ margin-bottom: 0; }
	    	}
	    	a{
	    		display: block;
	    		color: #007130;
	    		transition: 0.4s;
	    		height: 100%;
	    		&:hover{
	    			opacity: 1;
	    			color: #02A848;
	    			.txt-area{
	    				border-color: #02A848;
	    				box-shadow: 3px 3px 0px 0px #02A848;
	    				.thum .thum-img{
	    					top: 45%;
	    				}
	    			}
	    		}
	    	}
    		.txt-area{
    			transition: 0.4s;
    			background: #fff;
    			height: 100%;
    			padding: 0 10px 30px 10px;
    			border: 2px solid #007130;
    			border-radius: 8px;
    			box-shadow: 3px 3px 0px 0px #007130;

		    	.thum{
		    		position: relative;
		    		margin-bottom: 15px;
		    		max-width: 300px;
		    		width: 80%;
		    		margin: -100px auto 20px auto;
		    		@include sp{
		    			max-width: 100%;
		    			width: 210px;
		    			margin-top: -80px;
		    		}
		    		.thum-img{
		    			transition: 0.4s;
		    			height: 100%;
		    			width: auto;
		    			max-width: 100%;
					    position: absolute;
					    top: 50%;
					    left: 50%;
					    transform: translateY(-50%) translateX(-50%);
		    			&.thum-border{
		    				border: 2px solid #007130;
		    				border-radius: 8px;
		    			}
		    		}
		    	}
	    		.title{
	    			font-weight: bold;
	    			font-size: 20px;
	    			line-height: 32px;
	    			margin-bottom: 10px;
	    		}
    		}
	    }
	}
	.ac-btn{
		margin-bottom: 50px;
		a{
			font-size: 20px;
			@include sp{
				font-size: 18px;
			}
		}
		@include sp{
			margin-bottom: 40px;
		}
	}
	.bt-txt{
		font-size: 20px;
		line-height: 32px;
		color: #007130;
		margin-bottom: 40px;
		font-weight: bold;
		@include sp{
			margin-bottom: 20px;
		}
	}
}



/* ABOUT
---------------------------*/
.about{
	padding: 30px 20px 0 20px;
	margin-bottom: 100px;
	position: relative;
	z-index: 0;
	@include sp{
		margin-bottom: 80px;
	}
	&::after{
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 360px;
		background: url('../images/home/img_about.jpg') no-repeat center;
		background-size: cover;
		opacity: 0.2;
		z-index: -1;
		@include sp{
			background: url('../images/home/img_about_sp.jpg') no-repeat center;
			background-size: cover;
		}
	}
	.subttl{
		margin-bottom: 40px;
	}
	.apn-img{
		max-width: 200px;
		width: 100%;
		margin: 0 auto 30px auto;
	}
	.about-txt{
		color: #007130;
		text-align: center;
		font-size: 28px;
		line-height: 37px;
		margin-bottom: 30px;
		font-weight: bold;
		@include sp{
			font-size: 31px;
		}
		span{
			display: block;
			font-size: 20px;
			line-height: 32px;
			margin-top: 20px;
		}
	}
	.bt-img{
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
	}
	.bt-txt{
		font-size: 20px;
		line-height: 32px;
		color: #007130;
		margin-bottom: 40px;
		font-weight: bold;
		@include sp{
			margin-bottom: 20px;
		}
	}
}






/* NEWS
---------------------------*/
.news{
	padding: 0 20px 100px 20px;
	@include sp{
		padding: 0 0px 80px 0px;
	}
	.inner1080{
		background: url('../images/common/line.png') no-repeat top center;
		background-size: contain;
		padding-top: 100px;
		@include sp{
			background-size: auto 60px;
			padding-top: 80px;
		}
	}
	.subttl{
		font-size: 36px;
		padding-bottom: 30px;
	}
	.new-list{
		margin: 0 auto 30px auto;
		@include sp{
			padding: 0 20px;
		}
		li{
			a{
				display: block;
				color: #007130;
				font-size: 14px;
				line-height: 20px;
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 100%;
			    text-align: left;
			    font-weight: bold;
			    padding: 5px 0;
				.date{
					font-size: 12px;
					width: 80px;
					font-weight: bold;
				}
				.title{
					width: calc(100% - 85px);
					text-decoration: underline;
					transition: 0.4s;
				}
				&:hover{
					opacity: 1;
					.title{
						text-decoration: none;
						color: #02A848;
					}
				}
			}
		}
	}
}




} // home