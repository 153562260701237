.pagetypeservice{

/* =========================================================
 service
========================================================= */
.service-conts01{
	padding: 70px 20px 0 20px;
	margin-bottom: 90px;
	@include sp{
		padding: 40px 20px 0 20px;
		margin-bottom: 50px;
	}
	.service-ttl{
		text-align: center;
		color: #007130;
		font-weight: bold;
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 80px;
		@include sp{
			margin-bottom: 30px;
		}
	}

	.service{
		padding: 80px 20px 0 20px;
		margin-bottom: 100px;
		position: relative;
		z-index: 0;
		@include sp{
			margin-bottom: 80px;
		}
		.subttl{
			color: #fff;
			margin-bottom: 120px;
			&::after{
				background: #fff;
			}
		}
		.service-list{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    margin-bottom: 70px;
		    @include sp{
		    	display: block;
		    	margin-bottom: 50px;
		    }
		    li{
		    	width: calc(50% - 20px);
		    	text-align: center;
		    	margin-top: 150px;
		    	&:nth-child(1),&:nth-child(2){ margin-top: 0; }
		    	@include sp{
		    		width: 100%;
		    		margin-top: 0!important;
		    		margin-bottom: 110px!important;
		    		&:last-child{ margin-bottom: 0!important; }
		    	}
		    	a{
		    		display: block;
		    		color: #007130;
		    		transition: 0.4s;
		    		height: 100%;
		    		&:hover{
		    			opacity: 1;
		    			color: #02A848;
		    			.txt-area{
		    				border-color: #02A848;
		    				box-shadow: 3px 3px 0px 0px #02A848;
		    				.thum .thum-img{
		    					top: 45%;
		    				}
		    			}
		    		}
		    	}
	    		.txt-area{
	    			transition: 0.4s;
	    			background: #fff;
	    			height: 100%;
	    			padding: 0 10px 30px 10px;
	    			border: 2px solid #007130;
	    			border-radius: 8px;
	    			box-shadow: 3px 3px 0px 0px #007130;

			    	.thum{
			    		position: relative;
			    		margin-bottom: 15px;
			    		max-width: 300px;
			    		width: 80%;
			    		margin: -100px auto 20px auto;
			    		@include sp{
			    			max-width: 100%;
			    			width: 210px;
			    			margin-top: -80px;
			    		}
			    		.thum-img{
			    			transition: 0.4s;
			    			height: 100%;
			    			width: auto;
			    			max-width: 100%;
						    position: absolute;
						    top: 50%;
						    left: 50%;
						    transform: translateY(-50%) translateX(-50%);
			    			&.thum-border{
		    					border: 2px solid #007130;
		    					border-radius: 8px;
		    				}
			    		}
			    	}
		    		.title{
		    			font-weight: bold;
		    			font-size: 20px;
		    			line-height: 32px;
		    			margin-bottom: 10px;
		    		}
	    		}
		    }
		}
	}

	.service02{
		border-top: 2px solid #007130;
		margin-bottom: 70px;
		li a{
			position: relative;
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: center;
		    width: 100%;
		    text-align: left;
		    border-bottom: 2px solid #007130;
		    color: #007130;
		    transition: 0.4s;
		    @include sp{
		    	padding-bottom: 20px;
		    	display: block;
		    }
		    &:hover{
		    	color: #02A848;
		    }
		    &:hover{
		    	opacity: 1;
		    }
	    	.thum{
	    		position: relative;
	    		width: 160px;
	    		overflow: hidden;
	    		@include sp{
	    			max-width: 160px;
	    			width: 100%;
	    			margin: 0 auto;
	    		}
	    		.thum-img{
	    			width: 100%;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    width: 80%;
				    transform: translateY(-50%) translateX(-50%);
	    		}
	    	}
	    	.about{
	    		width: calc(100% - 160px - 160px);
	    		padding: 0 20px;
	    		@include sp{
	    			width: 100%;
	    			padding: 0 0 20px 0;
	    		}
	    		.title{
	    			font-size: 24px;
	    			line-height: 38px;
	    			margin-bottom: 10px;
	    			font-weight: bold;
	    		}
	    	}
	    	.detail{
	    		width: 160px;
	    		text-align: center;
	    		@include sp{
	    			text-align: left;
	    			width: 100%;
	    		}
	    		span{
					display: inline-block;
					position: relative;
					padding: 0 10px 0 0;
					transition: 0.4s;
					&::after{
						content: " ";
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						display: block;
		    			width: 8px;
		    			height: 8px;
		    			transition: 0.4s;
		    			border-right: 2px solid #007130;
		    			border-bottom: 2px solid #007130;
		    			transform: rotate(-45deg);
			    		}
				}
	    	}
		}
	}

	.service03{
		margin-bottom: 40px;
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: left;
	    align-items: center;
	    @include sp{
	    	display: block;
	    	width: 100%;
	    }
	    li:first-child{
	    	width: 70%;
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: center;
		    @include sp{
		    	display: block;
		    	width: 100%;
		    }
		    img{
		    	width: 15%;
		    	@include sp{
		    		max-width: 120px;
		    		width: 100%;
		    		margin: 0 auto 10px auto;
		    	}
		    }
		    p{
		    	font-weight: bold;
		    	font-size: 24px;
		    	line-height: 38px;
	    		color: #007130;
		    	width: 81%;
		    	@include sp{
		    		width: 100%;
		    		margin-bottom: 20px;
		    	}
		    	span{
		    		display: block;
		    		margin-top: 10px;
		    		font-size: 16px;
	    			color: #000;
		    		line-height: 24px;
		    	}
		    }
	    }
	    li:last-child{
	    	width: 30%;
		    @include sp{
		    	width: 100%;
		    }
	    }
	}
	.service04{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: left;
	    li:first-child{
	    	width: 21%;
	    	@include sp{
	    		max-width: 200px;
	    		width: 100%;
	    		margin: 0 auto 20px auto;
	    	}
	    }
	    li:last-child{
	    	width: 76%;
	    	@include sp{
	    		width: 100%;
	    	}
	    }
	}
	.service05{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    text-align: left;
	    margin-top: 40px;
	    li:first-child{
	    	width: 21%;
	    	@include sp{
	    		max-width: 200px;
	    		width: 100%;
	    		margin: 0 auto 20px auto;
	    	}
	    	img{ border: 2px solid #f5f5f5; }
	    }
	    li:last-child{
	    	width: 76%;
	    	@include sp{
	    		width: 100%;
	    	}
	    	span{
	    		font-weight: bold;
	    		display: block;
		    	font-size: 24px;
		    	line-height: 38px;
		    	margin-bottom: 10px;
		    	color: #007130;
	    	}
	    }
	}


}


/* detail
--------------------------------------------- */

.service-detail{
	padding: 70px 20px 0 20px;
	margin-bottom: 90px;
	@include sp{
		padding: 40px 20px 0 20px;
		margin-bottom: 50px;
	}
	.inner1080{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: flex-start;
	    width: 100%;
	    text-align: left;
	    position: relative;
	    padding-bottom: 60px;
	}
	.thum-img{
		width: 25%;
		height: auto;
		@include sp{
			max-width: 160px;
			width: 100%;
			margin: 0 auto 30px auto;
		}
	}
	.detaile-area{
		width: 70%;
		text-align: left;
		@include sp{
			width: 100%;
		}
		.title{
			color: #007130;
			font-weight: bold;
			font-size: 40px;
			line-height: 56px;
			margin-bottom: 40px;
			@include sp{
				text-align: center;
				margin-bottom: 20px;
				font-size: 30px;
				line-height: 38px;
			}
		}
		.conts{
			margin-bottom: 40px;
                img{ height: auto; width: auto; max-width: 100%; }
			small{
				@include sp{
					font-size: 12px;
				}
			}
			a{color: #007130;}
		}
		.bt-contact{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    text-align: left;
		    padding: 30px 0;
		    border-top: 2px solid #007130;
		    border-bottom: 2px solid #007130;
		    @include sp{
		    	display: block;
		    }
		    .read{
		    	width: 60%;
		    	color: #007130;
		    	@include sp{
		    		width: 100%;
		    	}
		    }
		    .btn.btn02{
		    	width: 35%;
		    	max-width: 100%;
		    	margin: 0;
		    	@include sp{
		    		max-width: 315px;
		    		width: 100%;
		    		margin: 20px auto 0 auto;
		    	}
		    }
		}
	}
	.sr-btn{
		position: absolute;
		left: 0;
		bottom: 0;
		transition: 0.4s;
		color: #007130;
		@include sp{

			width: 100%;
			text-align: center;
		}
		&:hover{
			color: #02A848;
			opacity: 1;
			span::after{
				border-color: #02A848;
			}
		}
		span{
			display: inline-block;
			position: relative;
			font-weight: bold;
			padding-left: 20px;
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
				display: block;
				width: 10px;
				height: 10px;
				transition: 0.4s;
				border-right: 2px solid #007130;
				border-bottom: 2px solid #007130;
				transform: rotate(135deg);
				transition: 0.4s;
			}
		}
	}

}




.kuratopi{
	padding: 0;
	margin-bottom: 90px;
	@include sp{
		padding: 0;
		margin-bottom: 100px;
	}
	.btn.btn02{
		max-width: 420px;
		a{
			padding: 20px 10px;
			@include sp{
				padding: 10px;
			}
		}
	}
	.mv-img{
		width: 100%;
		height: auto;
		margin-bottom: 40px;
		@include sp{
			width: 100%;
			margin-bottom: 30px;
		}
	}
	.mv-area{
		padding: 0 20px 60px;
		background: #fff;
		position: relative;
		z-index: 100;
		@include sp{
			padding: 0 0 40px;
		}
		.mv-read{
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			color: #006922;
			margin-bottom: 30px;
			@include sp{
				padding: 0 20px;
				font-size: 18px;
				line-height: 30px;
				margin-bottom: 20px;
			}
		}
		.bg-gray-read{
			font-weight: 500;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			color: #006922;
			background: #F7F7F7;
			padding: 30px;
			margin-bottom: 30px;
			@include sp{
				padding: 20px;
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 20px;
				text-align: left;
			}
		}
		.flow{
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			text-align: left;
			position: relative;
			z-index: 0;
			margin-bottom: 20px;
			@include sp{
				max-width: 270px;
				text-align: center;
				display: block;
				width: calc(100% - 40px);
				margin: 0 auto 30px auto;
			}
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 19px);
				height: 100%;
				border: 1px solid #007130;
				border-right: none;
				z-index: -1;
				@include sp{
					width: 100%;
				}
			}
			li{
				position: relative;
				width: 20%;
				height: 64px;
				@include sp{
					width: 100%;
					height: auto;
					padding: 40px 0 20px 0;
					border-right: 1px solid #007130;
				}
				&:nth-child(1){
					border-left: 1px solid #007130;
					border-bottom: none;
					span{
						padding-left: 20px;
						@include sp{
							padding: 0;
						}
					}
				}
				&:nth-child(3),&:nth-child(5){
					background: #007130;
					span{
						color: #fff;
					}
					&::after{
						display: none;
					}
					&::before{
						left: 100%;
						@include sp{
							left: 0;
						}
					}
				}
				&::after,&::before{
					content: " ";
					position: absolute;
					top: 50%;
					left: 100%;
					transform: translateY(-50%);
					width: 0;
					height: 0;
					border-style: solid;
					z-index: 10;
					border-width: 33px 0 33px 15px;
					border-color: transparent transparent transparent #fff;
					@include sp{
						left: 0;
						right: 0;
						top: calc(100% - 1px);
						transform: inherit;
						margin: auto;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 15px 135px 0 135px;
						border-color: #fff transparent transparent transparent;
					}
				}
				&::before{
					left: calc(100% + 1px);
					border-color: transparent transparent transparent #007130;
					@include sp{
						left: 0;
						top: calc( 100% );
						border-color: #007130 transparent transparent transparent;
					}
				}
				span{
					color: #007130;
					font-size: 13px;
					line-height: 20px;
					width: calc(100%);
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 50%;
					padding: 0 0 0 30px;
					transform: translateY(-50%) translateX(-50%);
					@include sp{
						font-size: 14px;
						padding: 20px 0 0 0;
					}
				}
			}
		}
		.inner-ttl{
			@include sp{
				padding: 0 20px;
			}
		}
		.btn.btn02{
			margin-top: 40px;
			@include sp{
				width: calc(100% - 40px);
				margin-top: 30px;
			}
		}
		.cap{
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			text-align: left;
			@include sp{
				padding: 0 20px;
			}
		}
	}
	.inner-ttl {
		color: #007130;
		font-weight: bold;
		font-size: 20px;
		margin-bottom: 20px;
		text-align: left;
	}
	.detaile-area{
		width: 100%;
		text-align: left;
		@include sp{
			width: 100%;
		}
		.inner-conts{
			margin-top: -60px;
			padding-top: 120px;
			@include sp{
				margin-top: -40px;
				padding-top: 90px;
			}
		}
	}
	.kc-ttl, .kc-ttl-sub{
		font-weight: bold;
		font-size: 40px;
		line-height: 56px;
		text-align: center;
		color: #007130;
		margin-bottom: 30px;
		@include sp{
			font-weight: bold;
			font-size: 32px;
			line-height: 42px;
			margin-bottom: 20px;
		}
		span{
			display: inline-block;
		}
	}
	.kuratop-conts01{
		background: #F8F8F8;
		padding: 60px 20px;
		@include sp{
			padding: 30px 20px;
		}
		.pict-list{
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			align-items: flex-start;
			margin-bottom: 140px;
			position: relative;
			@include sp{
				display: block;
				margin-bottom: 130px;
			}
			&::after{
				content: "";
				position: absolute;
				right: 0;
				left: 0;
				margin: auto;
				bottom: -80px;
				width: 30px;
				height: 30px;
				background: #007130;
				@include sp{
					bottom: -70px;
				}
			}
			&::before{
				content: "";
				position: absolute;
				right: 0;
				left: 0;
				bottom: -110px;
				margin: auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 35px 0 35px;
				border-color: #007130 transparent transparent transparent;
				@include sp{
					bottom: -100px;
				}
			}
			li{
				text-align: center;
				width: calc(100% / 3 - 10px);
				height: 274px;
				border-radius: 8px;
				background: #fff;
				border: 4px solid #007130;
				box-shadow: 4px 4px 0px #007130;
				position: relative;
				font-weight: bold;
				font-size: 24px;
				line-height: 34px;
				color: #007130;
				text-shadow:1px 1px 0 #FFF, -1px -1px 0 #FFF,
				  -1px 1px 0 #FFF, 1px -1px 0 #FFF,
				  0px 1px 0 #FFF,  0-1px 0 #FFF,
				  -1px 0 0 #FFF, 1px 0 0 #FFF;
				  @include sp{
						width: 100%;
						margin-bottom: 30px;
						font-weight: bold;
						font-size: 20px;
						line-height: 32px;
						text-align: center;
						color: #007130;
						height: 210px;
					  &:last-child{
						  margin-bottom: 0;
					  }
				  }
				&:nth-child(1){
					background: url(../images/service/kuratopi/pict01.png) no-repeat bottom center,#fff;
					background-size: 70% auto;
					@include sp{
						background-size: auto 110px;
					}
				}
				&:nth-child(2){
					background: url(../images/service/kuratopi/pict02.png) no-repeat bottom center,#fff;
					background-size: 70% auto;
					@include sp{
						background-size: auto 110px;
					}
				}
				&:nth-child(3){
					background: url(../images/service/kuratopi/pict03.png) no-repeat bottom center,#fff;
					background-size: 70% auto;
					@include sp{
						background-size: auto 110px;
					}
				}
				div{
					position: absolute;
					top: 30px;
					left: 50%;
					transform: translateX(-50%);
					padding: 0 20px;
					width: 100%;
					@include sp{
						top: 20px;
					}
				}
			}
		}
		.num-list{
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 40px;
			position: relative;
			@include sp{
				display: block;
				margin-bottom: 30px;
			}
			li{
				text-align: center;
				width: calc(100% / 3 - 10px);
				background: #fff;
				border: 1px solid #007130;
				position: relative;
				font-weight: bold;
				font-size: 24px;
				line-height: 40px;
				color: #007130;
				padding: 30px 20px;
				@include sp{
					width: 100%;
					margin-bottom: 30px;
					padding: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				.num-h{
					font-weight: bold;
					font-size: 24px;
					line-height: 32px;
					text-align: center;
					position: relative;
					padding-bottom: 50px;
					@include sp{
						font-size: 20px;
						line-height: 32px;
					}
					&::after{
						content: " ";
						position: absolute;
						bottom: 10px;
						right: 0;
						left: 0;
						margin: auto;
						height: 30px;
						width: 1px;
						background: #007130;
						@include sp{
							bottom: 13px;
						}
					}
					div{
						font-weight: bold;
						font-style: italic;
						font-size: 70px;
						line-height: 75px;
						@include sp{
							font-size: 60px;
							line-height: 65px;
						}
					}
				}
				.txt{
					font-weight: 500;
					font-size: 18px;
					line-height: 30px;
					text-align: left;
					@include sp{
						font-size: 16px;
						line-height: 24px;
					}
				}
			}
		}
	}
	.kuratop-conts02{
		padding: 60px 20px 0;
		margin-bottom: 100px;
		@include sp{
			padding: 40px 20px 0;
			margin-bottom: 60px;
		}
		.tb-wrap{
		}
		.tb-wrap01, .tb-wrap03{
			margin-bottom: 60px;
			@include sp{
				overflow: hidden;
				-webkit-overflow-scrolling: touch;
				overflow-x: scroll;
				margin-bottom: 40px;
				padding-bottom: 10px;
			}
		}
		.tb-wrap03{
			margin-bottom: 40px;
			@include sp{
				margin-bottom: 30px;
			}
		}
		.kc-tb{
			border-collapse:collapse;
			@include sp{
				width: 1080px;
			}
			tr{
				border-bottom: 2px dashed #B4B4B4;
				&:first-child,&:last-child{border: none;}
				th{
					color: #fff;
					font-weight: bold;
					font-size: 24px;
					line-height: 32px;
					text-align: center;
					color: #fff;
					position: relative;
					z-index: 0;
					height: 85px;
					padding: 0 20px;
					@include sp{
						height: 75px;
						font-size: 20px;
						line-height: 32px;
						overflow: hidden;
					}
					&.w45{
						width: 45%;
					}
					&.w30{
						width: 30%;
					}
					&::after{
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: auto;
						content: "";
						z-index: -1;
						width: calc(100% - 4px);
						height: 85px;
						border-radius: 8px 8px 0px 0px;
						background: #007130;
						@include sp{
							height: 2000px;
						}
					}
				}
				td{
					height: 120px;
					position: relative;
					text-align: center;
					padding: 0 20px;
					@include sp{
						height: 110px;
						font-size: 20px;
						line-height: 32px;
						overflow: hidden;
					}
					&::after{
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: auto;
						content: "";
						z-index: -1;
						width: calc(100% - 4px);
						height: 120px;
						background: #F8F8F8;
						@include sp{
							height: 2000px;
							display: block;
						}
					}
					&:nth-child(1){
						font-weight: bold;
						font-size: 22px;
						line-height: 32px;
						text-align: center;
						color: #007130;
						&::after{
							background: #EAF7F0;
						}
					}
					&:nth-child(2){
						font-weight: 500;
						font-size: 20px;
						line-height: 32px;
						text-align: left;
						color: #007130;
					}
				}
			}
		}
		.kc-tb02{
			border-collapse:collapse;
			text-align: left;
			position: relative;
			margin-bottom: 170px;
			@include sp{
				margin-bottom: 130px;
			}
			&::after, &::before{
				content: " ";
				position: absolute;
				bottom: -120px;
				left: 0;
				right: 0;
				margin: auto;
				width: 30px;
				height: 75px;
				background: #007130;
				@include sp{
					width: 20px;
					height: 50px;
					bottom: -90px;
				}
			}
			&::before{
				transform: rotate(90deg);
			}
			tr{
				border-bottom: 1px solid #007130;
				&:nth-child(1){
					border-top: 2px solid #007130;
				}
				th{
					width: 30%;
					padding: 20px;
					font-weight: bold;
					font-size: 22px;
					line-height: 24px;
					text-align: left;
					color: #007130;
					padding: 40px 30px;
					@include sp{
						display: block;
						width: 100%;
						font-weight: bold;
						font-size: 16px;
						line-height: 24px;
						padding: 25px 0 0;
					}
				}
				td{
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					text-align: left;
					padding: 40px 20px;
					@include sp{
						font-size: 16px;
						line-height: 24px;
						width: 100%;
						display: block;
						padding: 5px 0 25px;
					}
				}
			}
		}
		.bt-cap{
			text-align: center;
			font-weight: bold;
			font-size: 26px;
			line-height: 35px;
			color: #007130;
			margin-bottom: 30px;
			@include sp{
				font-size: 20px;
				line-height: 30px;
			}
		}
	}

	.detaile-area02{
		width: 70%;
		text-align: left;
		padding: 0 20px;
		@include sp{
			width: 100%;
		}
		.title{
			color: #007130;
			font-weight: bold;
			font-size: 40px;
			line-height: 56px;
			margin-bottom: 40px;
			@include sp{
				margin-bottom: 20px;
				font-size: 30px;
				line-height: 35px;
			}
		}
		.inner-conts{
			margin-top: -60px;
			padding-top: 120px;
			@include sp{
				margin-top: -40px;
				padding-top: 90px;
			}
			&.inner-conts06{ margin-bottom: 100px;  }
			.inner-ttl{
				color: #007130;
				font-weight: bold;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.tb01{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
				}
				th{
					vertical-align: top;
					text-align: right;
					color: #007130;
					font-weight: bold;
					font-size: 20px;
					line-height: 30px;
					width: 200px;
					padding: 30px 0;
					@include sp{
						text-align: left;
						width: 100%;
						display: block;
						padding: 30px 0 0 0;
					}
					span{
						display: block;
						font-size: 13px;
						margin-top: 20px;
						line-height: 25px;
						font-weight: 400;
						@include sp{ margin-top: 10px; }
					}
				}
				td{
					width: calc(100% - 200px);
					padding: 30px 0 30px 40px;
					text-align: left;
					vertical-align: top;
					@include sp{
						display: block;
						width: 100%;
						padding: 10px 0 30px 0;
					}
					.list-style{
						margin: 30px 0 10px 0;
					}
					img{
						margin-top: 30px;
					}

				}
			}
			.tb02{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
				}
				th{
					vertical-align: top;
					text-align: left;
					color: #007130;
					font-weight: 400;
					width: 200px;
					padding: 30px 0;
					@include sp{
						padding: 30px 0 0 0;
						display: block;
						width: 100%;
					}
				}
				td{
					width: calc(100% - 200px);
					padding: 30px 0 30px 40px;
					text-align: left;
					vertical-align: top;
					@include sp{
						padding: 10px 0 30px 0;
						display: block;
						width: 100%;
					}
					.list-style{
						margin: 30px 0 10px 0;
					}

				}
			}
			.tb03{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
					@include sp{
						&:nth-child(1){ display: none; }
					}
				}
				th{
					vertical-align: top;
					text-align: left;
					color: #007130;
					font-weight: 400;
					padding: 30px 0 30px 20px;
					&:nth-child(1){
						color: #007130;
						padding-left: 0;
						width: 18%;
					}
					&:nth-child(2){
						width: 30%;
					}
					&:nth-child(4),&:nth-child(5){
						text-align: right;
					}
				}
				td{
					padding: 30px 0 30px 20px;
					text-align: left;
					vertical-align: top;
					@include sp{
						display: block;
						width: 100%;
						padding: 5px 0 0 0;
						b.small{ font-weight: 400; }
						&:nth-child(1){ padding-top: 30px; }
						&:last-child{ padding-bottom: 30px; }
					}
					&:nth-child(1){
						color: #007130;
						padding-left: 0;
					}
					&:nth-child(4),&:nth-child(5){
						text-align: right;
						font-weight: bold;
						span{font-weight: 400;}
					}

				}
			}
			.tb-read{
				margin-bottom: 20px;
			}
			.tb-cap{
				margin: 20px 0 40px 0;
				font-size: 14px;
				line-height: 25px;
				@include sp{
					margin: 20px 0 30px 0;
				}
			}
			.list-style{
				li{
					padding-left: 15px;
					position: relative;
					margin-bottom: 10px;
					&:last-child{ margin-bottom: 0; }
					&::after{
						content: " ";
						position: absolute;
						top: 9px;
						left: 0;
						width: 7px;
						height: 7px;
						background: #000;
						border-radius: 100%;
					}
				}
			}
			.cap{
				font-size: 14px;
				line-height: 25px;
				margin-top: 15px;
			}
			.flow{
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 100%;
			    text-align: left;
			    position: relative;
			    z-index: 0;
			    @include sp{
			    	text-align: center;
			    	display: block;
					max-width: 270px;
					margin: 0 auto 30px auto;
			    }
			    &::after{
			    	content: " ";
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: calc(100% - 19px);
			    	height: 100%;
			    	border: 1px solid #007130;
			    	border-right: none;
			    	z-index: -1;
			    	@include sp{
			    		width: 100%;
			    	}
			    }
				li{
					position: relative;
					width: 20%;
					height: 64px;
					@include sp{
						width: 100%;
						height: auto;
						padding: 40px 0 20px 0;
						border-right: 1px solid #007130;
					}
					&:nth-child(1){
						border-left: 1px solid #007130;
						border-bottom: none;
						span{
							padding-left: 20px;
							@include sp{
								padding: 0;
							}
						}
					}
					&:nth-child(3),&:nth-child(5){
						background: #007130;
						span{
							color: #fff;
						}
						&::after{
							display: none;
						}
						&::before{
							left: 100%;
							@include sp{
								left: 0;
							}
						}
					}
					&::after,&::before{
						content: " ";
						position: absolute;
					    top: 50%;
					    left: 100%;
					    transform: translateY(-50%);
						width: 0;
						height: 0;
						border-style: solid;
						z-index: 10;
						border-width: 33px 0 33px 15px;
						border-color: transparent transparent transparent #fff;
						@include sp{
							left: 0;
							right: 0;
							top: calc(100% - 1px);
							transform: inherit;
							margin: auto;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 15px 135px 0 135px;
							border-color: #fff transparent transparent transparent;
						}
					}
					&::before{
						left: calc(100% + 1px);
						border-color: transparent transparent transparent #007130;
						@include sp{
							left: 0;
							top: calc( 100% );
							border-color: #007130 transparent transparent transparent;
						}
					}
					span{
						color: #007130;
						font-size: 13px;
						line-height: 20px;
						width: calc(100%);
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 50%;
						padding: 0 0 0 30px;
						transform: translateY(-50%) translateX(-50%);
						@include sp{
							font-size: 14px;
							padding: 20px 0 0 0;
						}
					}
				}
			}
			.gr-read{
				font-weight: bold;
				color: #007130;
				margin-bottom: 20px;
			}
			.w-clm{
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 100%;
			    text-align: left;
			    margin-bottom: 60px;
			    li:nth-child(1){
			    		width: 100%;
			    		img{
			    			max-width: 300px;
			    			margin: 0 auto;
			    			@include sp{
			    				max-width: 80%;
			    			}
			    		}
			    }
			    li:nth-child(2){
			    	text-align: left;
			    		width: 100%;
			    }
			}
		}
		.bt-contact{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    text-align: left;
		    padding: 30px 0;
		    border-top: 2px solid #007130;
		    border-bottom: 2px solid #007130;
			align-items: center;
		    @include sp{
		    	display: block;
		    }
		    .read{
		    	width: 60%;
		    	color: #007130;
		    	@include sp{
		    		width: 100%;
		    	}
		    }
		    .btn.btn02{
		    	width: 35%;
		    	max-width: 100%;
		    	margin: 0;
		    	@include sp{
		    		max-width: 315px;
		    		width: 100%;
		    		margin: 20px auto 0 auto;
		    	}
		    }
		}
	}
	.sr-btn{
		position: absolute;
		left: 0;
		bottom: 0;
		transition: 0.4s;
		color: #007130;
		@include sp{
			bottom: -50px;
			width: 100%;
			text-align: center;
		}
		&:hover{
			color: #02A848;
			opacity: 1;
			span::after{
				border-color: #02A848;
			}
		}
		span{
			display: inline-block;
			position: relative;
			font-weight: bold;
			padding-left: 20px;
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
				display: block;
				width: 10px;
				height: 10px;
				transition: 0.4s;
				border-right: 2px solid #007130;
				border-bottom: 2px solid #007130;
				transform: rotate(135deg);
				transition: 0.4s;
			}
		}
	}
}




.kuratopi-bt{
	position: relative;
	padding: 0 20px;
	@include sp{
		padding: 0;
	}
	.inner1080-wrap{
		position: relative;
		.absol{
		    position: absolute;
		    top: 0%;
		    left: 50%;
		    transform: translateX(-50%);
		    z-index: 10;
		    width: 100%;
		    @include sp{
		    	position: relative;
		    	top: inherit;
		    	left: inherit;
		    	transform: inherit;
				padding: 0 20px;
				z-index: 0;
		    }
		    .side-bar{
		    	background: #fff;
		    	border-radius: 5px;
		    }
		    &.active-side{
		    	animation: fadeIn 0.2s linear forwards;
		    	position: fixed;
		    	margin-top: 120px;
		    	height: 0;
		    	width: calc(100% - 40px);
		    	@include sp{
		    		animation: inherit;
		    		position: inherit;
		    		margin-top: inherit;
		    		height: auto;
		    		width: 100%;
		    	}
		    }
		}
	}
	.inner1080{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: flex-start;
	    width: 100%;
	    text-align: left;
	    position: relative;
	    padding-bottom: 60px;
	    @include sp{
	    	padding-bottom: 0;
	    }
	}
	.mv-img{
		width: 100%;
		height: auto;
		margin-bottom: 40px;
		@include sp{
			width: 100%;
			margin-bottom: 30px;
		}
	}
	.mvtxt{
		color: #007130;
		font-size: 24px;
		font-weight: bold;
		line-height: 45px;
		@include sp{
			font-size: 20px;
			line-height: 38px;
			margin-bottom: 30px;
		}
	}
	.side-bar{
		max-width: 240px;
		width: 25%;
		@include sp{
			max-width: 100%;
			width: 100%;
			margin-bottom: 20px;
			img{
				max-width: 60%;
				margin: 0 auto;
			}
		}
		.inner-nav{
			margin-top: 20px;
			padding-top: 20px;
			border-top: 2px solid #007130;
			li{
				margin-bottom: 10px;
				a{
					display: block;
					color: #007130;
					transition: 0.4s;
					font-size: 14px;
					position: relative;
					padding-left: 15px;
					&:hover{
						color: #02A848;
						&::after{ border-color: #02A848; }
					}
					&::after {
					    content: " ";
					    position: absolute;
					    top: -2px;
					    left: 0;
					    bottom: 0;
					    margin: auto;
					    display: block;
					    width: 8px;
					    height: 8px;
					    transition: 0.4s;
					    border-right: 1px solid #007130;
					    border-bottom: 1px solid #007130;
					    transform: rotate( -45deg );
					    transition: 0.4s;
					}
				}
			}
		}
	}
	.detaile-area{
		width: 70%;
		text-align: left;
		@include sp{
			width: 100%;
		}
		.title{
			color: #007130;
			font-weight: bold;
			font-size: 40px;
			line-height: 56px;
			margin-bottom: 40px;
			@include sp{
				margin-bottom: 20px;
				font-size: 30px;
				line-height: 35px;
			}
		}
		.inner-conts{
			margin-top: -60px;
			padding-top: 120px;
			@include sp{
				margin-top: -40px;
				padding-top: 90px;
			}
			&.inner-conts06{ margin-bottom: 100px;  }
			.inner-ttl{
				color: #007130;
				font-weight: bold;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.tb01{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
				}
				th{
					vertical-align: top;
					text-align: right;
					color: #007130;
					font-weight: bold;
					font-size: 20px;
					line-height: 30px;
					width: 200px;
					padding: 30px 0;
					@include sp{
						text-align: left;
						width: 100%;
						display: block;
						padding: 30px 0 0 0;
					}
					span{
						display: block;
						font-size: 13px;
						margin-top: 20px;
						line-height: 25px;
						font-weight: 400;
						@include sp{ margin-top: 10px; }
					}
				}
				td{
					width: calc(100% - 200px);
					padding: 30px 0 30px 40px;
					text-align: left;
					vertical-align: top;
					@include sp{
						display: block;
						width: 100%;
						padding: 10px 0 30px 0;
					}
					.list-style{
						margin: 30px 0 10px 0;
					}
					img{
						margin-top: 30px;
					}

				}
			}
			.tb02{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
				}
				th{
					vertical-align: top;
					text-align: left;
					color: #007130;
					font-weight: 400;
					width: 200px;
					padding: 30px 0;
					@include sp{
						padding: 30px 0 0 0;
						display: block;
						width: 100%;
					}
				}
				td{
					width: calc(100% - 200px);
					padding: 30px 0 30px 40px;
					text-align: left;
					vertical-align: top;
					@include sp{
						padding: 10px 0 30px 0;
						display: block;
						width: 100%;
					}
					.list-style{
						margin: 30px 0 10px 0;
					}

				}
			}
			.tb03{
				border-collapse:collapse;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
					@include sp{
						&:nth-child(1){ display: none; }
					}
				}
				th{
					vertical-align: top;
					text-align: left;
					color: #007130;
					font-weight: 400;
					padding: 30px 0 30px 20px;
					&:nth-child(1){
						color: #007130;
						padding-left: 0;
						width: 18%;
					}
					&:nth-child(2){
						width: 30%;
					}
					&:nth-child(4),&:nth-child(5){
						text-align: right;
					}
				}
				td{
					padding: 30px 0 30px 20px;
					text-align: left;
					vertical-align: top;
					@include sp{
						display: block;
						width: 100%;
						padding: 5px 0 0 0;
						b.small{ font-weight: 400; }
						&:nth-child(1){ padding-top: 30px; }
						&:last-child{ padding-bottom: 30px; }
					}
					&:nth-child(1){
						color: #007130;
						padding-left: 0;
					}
					&:nth-child(4),&:nth-child(5){
						text-align: right;
						font-weight: bold;
						span{font-weight: 400;}
					}

				}
			}
			.tb-read{
				margin-bottom: 20px;
			}
			.tb-cap{
				margin: 20px 0 40px 0;
				font-size: 14px;
				line-height: 25px;
				@include sp{
					margin: 20px 0 30px 0;
				}
			}
			.list-style{
				li{
					padding-left: 15px;
					position: relative;
					margin-bottom: 10px;
					&:last-child{ margin-bottom: 0; }
					&::after{
						content: " ";
						position: absolute;
						top: 9px;
						left: 0;
						width: 7px;
						height: 7px;
						background: #000;
						border-radius: 100%;
					}
				}
			}
			.cap{
				font-size: 14px;
				line-height: 25px;
				margin-top: 15px;
			}
			.flow{
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 100%;
			    text-align: left;
			    position: relative;
			    z-index: 0;
			    @include sp{
			    	text-align: center;
			    	display: block;
					max-width: 270px;
					margin: 0 auto 30px auto;
			    }
			    &::after{
			    	content: " ";
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: calc(100% - 19px);
			    	height: 100%;
			    	border: 1px solid #007130;
			    	border-right: none;
			    	z-index: -1;
			    	@include sp{
			    		width: 100%;
			    	}
			    }
				li{
					position: relative;
					width: 20%;
					height: 64px;
					@include sp{
						width: 100%;
						height: auto;
						padding: 40px 0 20px 0;
						border-right: 1px solid #007130;
					}
					&:nth-child(1){
						border-left: 1px solid #007130;
						border-bottom: none;
						span{
							padding-left: 20px;
							@include sp{
								padding: 0;
							}
						}
					}
					&:nth-child(3),&:nth-child(5){
						background: #007130;
						span{
							color: #fff;
						}
						&::after{
							display: none;
						}
						&::before{
							left: 100%;
							@include sp{
								left: 0;
							}
						}
					}
					&::after,&::before{
						content: " ";
						position: absolute;
					    top: 50%;
					    left: 100%;
					    transform: translateY(-50%);
						width: 0;
						height: 0;
						border-style: solid;
						z-index: 10;
						border-width: 33px 0 33px 15px;
						border-color: transparent transparent transparent #fff;
						@include sp{
							left: 0;
							right: 0;
							top: calc(100% - 1px);
							transform: inherit;
							margin: auto;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 15px 135px 0 135px;
							border-color: #fff transparent transparent transparent;
						}
					}
					&::before{
						left: calc(100% + 1px);
						border-color: transparent transparent transparent #007130;
						@include sp{
							left: 0;
							top: calc( 100% );
							border-color: #007130 transparent transparent transparent;
						}
					}
					span{
						color: #007130;
						font-size: 13px;
						line-height: 20px;
						width: calc(100%);
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 50%;
						padding: 0 0 0 30px;
						transform: translateY(-50%) translateX(-50%);
						@include sp{
							font-size: 14px;
							padding: 20px 0 0 0;
						}
					}
				}
			}
			.gr-read{
				font-weight: bold;
				color: #007130;
				margin-bottom: 20px;
			}
			.w-clm{
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    width: 100%;
			    text-align: left;
			    margin-bottom: 60px;
			    li:nth-child(1){
			    		width: 100%;
			    		img{
			    			max-width: 300px;
			    			margin: 0 auto;
			    			@include sp{
			    				max-width: 80%;
			    			}
			    		}
			    }
			    li:nth-child(2){
			    	text-align: left;
			    		width: 100%;
			    }
			}
		}
		.bt-contact{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    text-align: left;
		    padding: 30px 0;
		    border-top: 2px solid #007130;
		    border-bottom: 2px solid #007130;
		    @include sp{
		    	display: block;
		    }
		    .read{
		    	width: 60%;
		    	color: #007130;
		    	@include sp{
		    		width: 100%;
		    	}
		    }
		    .btn.btn02{
		    	width: 35%;
		    	max-width: 100%;
		    	margin: 0;
		    	@include sp{
		    		max-width: 315px;
		    		width: 100%;
		    		margin: 20px auto 0 auto;
		    	}
		    }
		}
	}
	.sr-btn{
		position: absolute;
		left: 0;
		bottom: 0;
		transition: 0.4s;
		color: #007130;
		@include sp{
			bottom: -50px;
			width: 100%;
			text-align: center;
		}
		&:hover{
			color: #02A848;
			opacity: 1;
			span::after{
				border-color: #02A848;
			}
		}
		span{
			display: inline-block;
			position: relative;
			font-weight: bold;
			padding-left: 20px;
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
				display: block;
				width: 10px;
				height: 10px;
				transition: 0.4s;
				border-right: 2px solid #007130;
				border-bottom: 2px solid #007130;
				transform: rotate(135deg);
				transition: 0.4s;
			}
		}
	}

}


} // service

@include sp{
	.tb-wrap::-webkit-scrollbar {
		width: 10px;
	}

	/*スクロールバーの軌道*/
	.tb-wrap::-webkit-scrollbar-track {
		border-radius: 10px;
		background: #F8F8F8;
	}

	/*スクロールバーの動く部分*/
	.tb-wrap::-webkit-scrollbar-thumb {
	background-color: #007130;
	border-radius: 10px;
	box-shadow:0 0 0 1px #007130;
	}
}