.pagetypeprivacypolicy{

/* =========================================================
 privacypolicy
========================================================= */
.read{
	font-weight: bold;
	margin-bottom: 80px;
	@include sp{
		margin-bottom: 40px;
	}
}

.inner760{
	max-width: 760px;
	width: 100%;
	margin: 0 auto;
	display: block;
	text-align: left;
}
.privacypolicy-conts{

	padding: 80px 20px 0 20px;
	margin-bottom: 120px;
	@include sp{
		padding: 60px 20px 0 20px;
		margin-bottom: 100px;
	}
}
.plist{
	
	li{
		margin-bottom: 40px;
		&:last-child{ margin-bottom: 0; }
		@include sp{ margin-bottom: 30px; }
		.midashi{
			color: #007130;
			font-size: 24px;
			line-height: 35px;
			font-weight: bold;
			display: block;
			margin-bottom: 10px;
		}
		a{
			color: #007130;
			text-decoration: underline;
			transition: 0.4s;
			&:hover{
				opacity: 1;
				color: #02A848;
				text-decoration: none;
			}
		}
	}
}


} // privacypolicy