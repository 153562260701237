.pagetypecase{

/* =========================================================
 case
========================================================= */
.case-conts01{
	padding: 70px 20px 0 20px;
	margin-bottom: 90px;
	@include sp{
		padding: 40px 20px 0 20px;
		margin-bottom: 50px;
	}
	.case-ttl{
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 50px;
		@include sp{
			margin-bottom: 30px;
		}
	}
}
.case-conts-list{
	display: block;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	@include sp{
		display: block;
	}
	li{
		width: 30%;
		margin-right: 5%;
		text-align: left;
		margin-bottom: 30px;
		&.w100{
			width: 100%;
			text-align: center;
		}
		@include sp{
			display: block;
			width: 100%;
			&:last-child{ margin-bottom: 0; }
		}
		&:nth-child(3n){ margin-right: 0; }
		a{
			display: block;
			transition: 0.4s;
			color: #007130!important;
			&:hover{
				opacity: 1;
				color: #02A848!important;
				.thum{
					border: 4px solid #02A848;
					box-shadow: 3px 3px 0px 0px #02A848;
				}
			}
		}
		.thum{
			position: relative;
			overflow: hidden;
			margin-bottom: 25px;
			border-radius: 8px;
			border: 4px solid #007130;
			box-shadow: 3px 3px 0px 0px #007130;
			transition: 0.4s;
    		.thum-img{
    			width: 100%;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    transform: translateY(-50%) translateX(-50%);
    		}
		}
		.title{
			font-weight: bold;
			font-size: 22px;
			line-height: 38px;
			margin-bottom: 15px;
		}
		.txt{
			margin-bottom: 15px;
		}
		.detail span{
			display: inline-block;
			position: relative;
			padding: 0 10px 0 0;
			transition: 0.4s;
			font-weight: bold;
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				display: block;
	    			width: 8px;
	    			height: 8px;
	    			transition: 0.4s;
	    			border-right: 2px solid #007130;
	    			border-bottom: 2px solid #007130;
	    			transform: rotate(-45deg);
	    		}
		}
	}
}


.case{
	margin-bottom: 140px;
	@include sp{
		margin-bottom: 50px;
	}
}
.case .case-list{
	max-width: 100%;
	margin-bottom: 0;
}
.case .case-list li{
	margin-bottom: 30px;
	@include sp{
		margin-bottom: 15px;
	}
}
.case .case-list li .title{
	font-size: 16px;
	line-height: 24px;
}




/* detail
--------------------------------------------- */

.case-detail{
	padding: 70px 20px 0 20px;
	margin-bottom: 150px;
	@include sp{
		padding: 0;
		margin-bottom: 50px;
	}
	.inner1080{
	    width: 100%;
	    text-align: left;
	    position: relative;
	}
	.thum-img{
		max-width: 520px;
		min-width: 400px;
		width: 48%;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
		@include sp{
			position: inherit;
			max-width: 100%;
			min-width: auto;
			width: 100%;
			margin-bottom: 30px;
		}
		.cap{
			margin-top: 30px;
			width: 250px;
			color: #007130;
			font-weight: 400;
			@include sp{
				width: 100%;
				margin-top: -20px;
				background: #fff;
				padding: 20px 20px 0 20px;
				position: relative;
				z-index: 0;
				width: calc(100% - 20px);
			}
		}
	}
	.detaile-area{
		width: 100%;
		text-align: left;
		margin-left: auto;
		@include sp{
			padding: 0 20px 50px 20px;
		}
		.title{
			color: #007130;
			font-weight: bold;
			font-size: 40px;
			line-height: 56px;
			max-width: 520px;
			width: calc(100% - 250px);
			margin-left: auto;
			height: 200px;
			position: relative;
			text-shadow: 0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff;
			@include sp{
				max-width: 100%;
				width: 100%;
				height: auto;
			}
			span{
			    position: absolute;
			    width: 100%;
			    top: 50%;
			    left: 50%;
			    transform: translateY(-50%) translateX(-50%);
				@include sp{
					position: inherit;
					top: inherit;
					left: inherit;
					transform: inherit;
				}
			}
		}
		.conts-wrap{
			background: #fff;
			padding: 60px 60px 0 60px;
			max-width: 824px;
			width: calc(100% - 250px);
			margin-left: auto;
			position: relative;
			z-index: 0;
			@include sp{
				max-width: 100%;
				width: 100%;
				padding: 0;
				margin-top: 30px;
				z-index: 0;
			}
		}
		.conts{
			color: #007130;
			margin-bottom: 40px;
			img{ height: auto; width: auto; max-width: 100%; }
			a{color: #007130; transition: 0.4s;}
			.link{
				padding-left: 20px;
				background: url('../images/case/icon_link.png') no-repeat top 3px left;
				background-size: 16px;
			}
			.list01{
				color: #000;
				padding-left: 20px;
				li{
					list-style: disc;
				}
			}
			.list02{
				li{
					padding-left: 20px;
					position: relative;
					a{ text-decoration: underline; }
					&::after{
						content: " ";
						position: absolute;
						top: 7px;
						left: 0;
						display: block;
						width: 6px;
						height: 6px;
						transition: 0.4s;
						border-right: 1px solid #007130;
						border-bottom: 1px solid #007130;
						transform: rotate(-45deg);
						transition: 0.4s;
					}
				}
			}
		}
		.bt-contact{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    text-align: left;
		    padding: 30px 0;
		    border-top: 2px solid #007130;
		    border-bottom: 2px solid #007130;
		    @include sp{
		    	display: block;
		    }
		    .read{
		    	width: 60%;
		    	@include sp{
		    		width: 100%;
		    	}
		    }
		    .btn.btn02{
		    	width: 35%;
		    	max-width: 100%;
		    	margin: 0;
		    	@include sp{
		    		max-width: 315px;
		    		width: 100%;
		    		margin: 20px auto 0 auto;
		    	}
		    }
		}
	}
	.sr-btn{
		position: absolute;
		left: 0;
		bottom: 0;
		transition: 0.4s;
		color: #007130;
		@include sp{

			width: 100%;
			text-align: center;
		}
		&:hover{
			color: #02A848;
			opacity: 1;
			span::after{
				border-color: #02A848;
			}
		}
		span{
			display: inline-block;
			position: relative;
			font-weight: bold;
			padding-left: 20px;
			&::after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
				display: block;
				width: 10px;
				height: 10px;
				transition: 0.4s;
				border-right: 2px solid #007130;
				border-bottom: 2px solid #007130;
				transform: rotate(135deg);
				transition: 0.4s;
			}
		}
	}

}



} // case