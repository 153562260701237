@charset "utf-8";

/* =========================================================
MEDIA
========================================================= */
$pc: 769px; // PC
$sp: 768px; // SP
$tab: 1080px; // tablet

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@include sp {
  .pc-visible {
    display: none;
  }
}

@include pc {
  .sp-visible {
    display: none;
  }
}




@keyframes fadeIn {
    0% { opacity: 0; }
    100%{ opacity: 1; }
}


@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -90px, 0);
    transform: translate3d(0, -90px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -45px, 0);
    transform: translate3d(0, -45px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}



/* =========================================================
RESET
========================================================= */
@import "_reset";

/* =========================================================
common
========================================================= */
@import "_common";


/* =========================================================
home
========================================================= */
@import "_home";


/* =========================================================
news
========================================================= */
@import "_news";


/* =========================================================
case
========================================================= */
@import "_case";


/* =========================================================
service
========================================================= */
@import "_service";


/* =========================================================
contents
========================================================= */
@import "_contents";


/* =========================================================
recruit
========================================================= */
@import "_recruit";


/* =========================================================
about
========================================================= */
@import "_about";


/* =========================================================
entry
========================================================= */
@import "_entry";



/* =========================================================
company
========================================================= */
@import "_company";

/* =========================================================
privacypolicy
========================================================= */
@import "_privacypolicy";

