.pagetypeabout{

.inner760{
	max-width: 760px;
	width: 100%;
	margin: 0 auto;
	display: block;
}

/* =========================================================
 about
========================================================= */

.about-conts{
	padding: 70px 20px 0 20px;
	margin-bottom: 120px;
	@include sp{
		padding-top: 60px;
	}
	.read{
		text-align: center;
		font-size: 24px;
		font-weight: bold;
		line-height: 38px;
		margin-bottom:70px;
	}
	.img-list{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    margin-bottom: 40px;
		@include sp{
			display: block;
		}
	    li{
	    	width: calc(100% / 3);
			@include sp{
				width: 100%;
			}
	    }
	}
	.txt{
		text-align: left;
		margin-bottom: 30px;
		@include sp{
			margin-bottom: 40px;
		}
	}
	.img-pc {
		display: block;
		@include sp{
			display: none;
		}
	}
	.img-sp {
		display: none;
		@include sp{
			display: block;
		}
	}
	.apn{
		border: 2px solid #007130;
		padding: 30px 40px;
		text-align: left;
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: flex-start;
	    width: 100%;
	    margin-bottom: 40px;
	    @include sp{
	    	padding: 30px 20px;
	    	display: block;
	    }
	    .side-img{
	    	width: 120px;
	    	@include sp{
	    		margin: 0 auto 30px auto;
	    	}
	    }
	    .side-txt{
	    	width: calc(100% - 150px);
	    	@include sp{
	    		width: 100%;
	    	}
	    	p{
	    		margin-bottom: 10px;
	    		span{
	    			color: #007130;
	    			display: block;
	    			font-size: 20px;
	    			line-height: 35px;
	    			font-weight: bold;
	    			@include sp{
	    				margin-bottom: 10px;
	    			}
	    		}
	    	}
	    	.bt-img{
	    		max-width: 280px;
	    		width: 100%;
	    		@include sp{
	    			margin: 20px auto 0 auto;
	    		}
	    	}
	    }
	}
	.w-clmn{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-items: flex-start;
	    width: 100%;
	    margin-bottom: 40px;
	    position: relative;
	    text-align: left;
	    @include sp{
	    	display: block;
	    	margin-bottom: 60px;
	    }
	    &::after{
	    	content: " ";
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	right: 0;
	    	bottom: 0;
	    	margin: auto;
	    	display: block;
	    	width: 1px;
	    	height: 100%;
	    	background: #007130;
	    	@include sp{
	    		display: none;
	    	}
	    }
	    li{
	    	width: calc(50% - 40px);
	    	@include sp{
	    		width: 100%;
	    		padding-bottom: 10px;
	    		margin-bottom: 20px;
	    		border-bottom: 1px solid #007130;
	    		&:last-child{
	    			padding-bottom: 0;
	    			margin-bottom: 0;
	    			border: none;
	    		}
	    	}
	    	span{
	    		color: #007130;
	    		font-weight: bold;
	    		display: block;
	    		font-size: 18px;
	    		line-height: 26px;
	    		margin-bottom: 10px;
	    	}
	    }
	}
	.w-clmn02{
		margin-bottom: 100px;
		@include sp{
			margin-bottom: 70px;
		}
	    li{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: flex-start;
		    width: 100%;
		    position: relative;
		    text-align: left;
	    	margin-bottom: 30px;
	    	&:last-child{ margin-bottom: 0; }
		    @include sp{
		    	display: block;
		    	margin-bottom: 20px;
		    }
		    img{
		    	width: 200px;
		    	@include sp{
		    		margin: 0 auto 20px auto;
		    	}
		    }
		    p{
		    	width: calc(100% - 240px);
		    	font-size: 14px;
		    	line-height: 20px;
		    	@include sp{
		    		text-align: left;
		    		width: 100%;
		    	}
		    	span{
		    		color: #007130;
		    		font-weight: bold;
		    		display: block;
		    		font-size: 18px;
		    		line-height: 26px;
		    		margin-bottom: 10px;
		    	}
		    }
	    }
	}

	.mvvss{
		padding: 0;
		margin-bottom: 100px;
		@include sp{ margin-bottom: 80px; }
	}

	.partner{
		padding: 0;
		@include sp{ margin-bottom: 40px; }
	}

	.case{
		padding: 0;
		@include sp{ margin-bottom: 40px; }
		.case-list li{
			width: 18%;
			margin-right: 2.5%!important;
			&:last-child,
			&:nth-child(5n){ margin-right: 0!important; }
			@include sp{
				width: 30%;
				margin-right: 5%!important;
				&:nth-child(5n){ margin-right: 5%!important; }
				&:last-child,
				&:nth-child(3n){ margin-right: 0!important; }
			}
		}
	}

	.worklife{
		margin-bottom: 100px;
		@include sp{
			margin-bottom: 80px;
		}
		.mini-txt{
			margin-bottom: 30px;
			@include sp{
				text-align: left;
			}
		}
		.w-clmns{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: flex-start;
		    width: 100%;
		    margin-bottom: 10px;
			@include sp{
				display: block;
			}
		    .side-img{
		    	width: calc(50% - 20px);
				@include sp{
					width: 100%;
				}
		    }
		    .txt-list{
		    	width: calc(50% - 20px);
			    display: -webkit-box;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    align-items: flex-start;
				@include sp{
					width: 100%;
					display: block;
					margin-bottom: 40px;
				}
			    li{
			    	border-top: 2px solid #007130;
			    	padding-top: 20px;
			    	text-align: left;
			    	width: calc(50% - 20px);
			    	margin-bottom: 20px;
					@include sp{
						width: 100%;
						margin: 20px 0 0 0;
						&:first-child{ margin-top: 30px; }
					}
			    	span{
			    		display: block;
			    		font-weight: bold;
			    		color: #007130;
			    		font-size: 18px;
			    		margin-bottom: 10px;
			    	}
			    }
		    }
		}
		.bt-txt{
			font-size: 14px;
			line-height: 20px;
			@include sp{
				text-align: left;
			}
		}
	}

	.blog{
		.mini-txt{
			margin-bottom: 40px;
		}
		.blog-list{
			width: 100%;
			margin-bottom: 20px;
			li{
				width: 30%;
				margin-right: 5%;
				@include sp{
					width: 100%;
					margin-right: 0;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
				a:hover .thum{
					border-color: #02A848;
				}
				.thum{
					border: 4px solid #007130;
					transition: 0.4s;
				}
			}
		}
	}


} // about-conts


} // about