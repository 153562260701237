@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;600;700&display=swap');

/* layout */
/*=================================
  initialization of style
===================================*/
html,
body {
  margin: 0;
  padding: 0;
  background:#ffffff;
  color: #000000;
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  font-feature-settings: "palt";
  word-wrap: break-word;
  // word-break: break-all;
  overflow-wrap: break-word;
}
.en{
  word-break: break-all;
  font-family: 'Bitter', serif;
  font-weight: 400;
}

a:link,
a:visited {
  text-decoration: none;
  color: #535252;
}
a:hover,
a:active {
  text-decoration: none;
  color: #535252;
  opacity: 0.7;
}

figure,figcaption,div,h1,h2,h3,h4,h5,h6,p,dl,dt,dd,ul,ol,li,form {
  margin: 0;
  padding: 0;
}
img, a img {
  border:none;
  vertical-align: bottom;
}
img{ display: block; width: 100%; }


/* List_group */
ul,ol,li { list-style: none;}
.wyswyg{
  ul,ol{
    padding-left: 20px;
    list-style-type: disc;
    li{
      list-style: inherit;
    }
  }
  ol {
    list-style-type: decimal;
  }

}


/* inline-style */
address {
  font-style: normal;
  font-size: 12px;
  color: #535252;
}
  address a,
  address a:link,
  address a:visited {
    text-decoration: none;
    color: #535252;
  }
  address a:hover,
  address a:active {
    text-decoration: underline;
    color: #535252;
  }

/* table */
table {
  width:100%;
  border-collapse:collapse;
}
tr,th,td {}

*, *:before, *:after {
  outline: none!important;
            box-sizing: border-box;
}

.tsn{ transition: 0.4s; }
.clear::after{ content: " "; clear: both; display: block; width: 100%; height: 0; }
