.pagetyperecruit{

/* =========================================================
 recruit
========================================================= */
.subttl.mb100{
	margin-bottom: 60px;
	@include sp{ margin-bottom: 40px; }
}
.rec-parts01-wrap{
	background: #007130;
	padding: 0 20px 60px 20px;
	margin: -35px 0 50px 0;
    position: relative;
    z-index: 10;
    @include sp{
    	padding-bottom: 40px;
    	margin: -45px 0 40px 0;
    }
	.rec-parts01{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    li{
	    	width: calc(50% - 20px);
	    	@include sp{
	    		width: calc(50% - 10px);
	    	}
	    	a{
	    		display: block;
	    		text-align: center;
	    		background: #fff;
	    		color: #007130;
	    		font-size: 20px;
	    		font-weight: bold;
	    		line-height: 56px;
	    		border-radius: 8px;
	    		transition: 0.4s;
	    		@include sp{
	    			font-size: 16px;
	    			line-height: 38px;
	    		}
	    		&:hover{
	    			background: #EDFFAF;
	    			opacity: 1;
	    		}
	    	}
	    }
	}
}
.rec-parts02-wrap{
	padding: 0 20px;
	margin-bottom: 80px;
	@include sp{
		padding: 0;
		margin-bottom: 50px;
	}
	.rec-parts02{
		text-align: center;
		a{
			display: inline-block;
			padding: 0 20px;
			border-right: 1px solid #007130;
			color: #007130;
			font-size: 18px;
			line-height: 26px;
			font-weight: bold;
			transition: 0.4s;
			@include sp{
				font-size: 14px;
				line-height: 24px;
				padding: 0 10px;
			}
			&:last-child{ border: none; }
			&:hover{
				color: #02A848;
			}
			&.current{
				position: relative;
				pointer-events: none;
				&::after{
					content: " ";
					position: absolute;
					bottom: -15px;
					left: 0;
					right: 0;
					margin: 0 auto;
					height: 8px;
					width: 8px!important;
					border-radius: 100%;
					background: #007130;
				}
			}
		}
	}
}

.rec-conts{
	padding: 0 20px;
	&.massage-wrap{
		margin-bottom: 120px;
	}
	.inner-link{
	    display: -webkit-box;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100%;
	    margin-bottom: 100px;
	    @include sp{
	    	margin-bottom: 60px;
	    }
	    li{
	    	width: 32%;
	    	@include sp{
	    		width: 100%;
	    		&:first-child a{ border-radius: 8px 8px 0 0; border-top: 2px solid #007130; }
	    		&:last-child a{ border-radius: 0 0 8px 8px; border-bottom: 2px solid #007130; }

	    	}
	    	&.no-press{
	    		opacity: 0.3;
	    		pointer-events: none;
	    	}
	    	a{
	    		display: block;
	    		position: relative;
	    		border-radius: 8px;
	    		border: 2px solid #007130;
	    		color: #007130;
	    		font-size: 20px;
	    		background: #fff;
	    		height: 90px;
	    		transition: 0.4s;
	    		font-weight: bold;
	    		@include sp{
	    			border-radius: 0;
	    			border-top: 1px solid #007130;
	    			border-bottom: 1px solid #007130;
	    		}
	    		&:hover{
	    			color: #02A848;
	    			border-color: #02A848;
	    			opacity: 1;
	    			&::after{
	    				border-color: #02A848;
	    			}
	    		}
	    		span{
	    			display: inline-block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					width: 100%;
					padding-bottom: 15px;
					&::after{
						content: " ";
						position: absolute;
						bottom: 0;
						right: 0;
						left: 0;
						margin: auto;
						display: block;
						width: 10px;
						height: 10px;
						border-left: 2px solid #007130;
						border-bottom: 2px solid #007130;
						transform: rotate(-45deg);
						transition: 0.4s;
					}
	    		}
	    	}
	    }
	}

	.clmn{
		margin-top: -100px;
		padding-top: 100px;
		margin-bottom: 120px;
		position: relative;
		&:nth-of-type(1) .wclmn{
			display: block;
		}
		&.active-w .wclmn{
			animation: fadeIn 0.2s linear forwards;
			position: fixed;
			top: 120px;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1080px;
			width: calc(100% - 40px);
			height: 0;
			z-index: 100;
			@include sp{
				animation: inherit;
				position: absolute;
				top: inherit;
				left: inherit;
				transform: inherit;
				max-width: 100%;
				width: 100%;
				height: auto;
			}
		}
		@include sp{
			margin-top: -60px;
			padding-top: 60px;
			margin-bottom: 60px;
			padding-bottom: 80px;
		}
		.wclmn{
		    width: 100%;
		    position: absolute;
		    top: 100px;
		    display: none;
		    @include sp{
		    	display: block;
	    		top: inherit;
	    		bottom: 0;
		    }
		    .btn{
		    	max-width: 280px;
		    	margin: 0 0 0 auto;
		    	@include sp{
		    		margin: 0 auto;
		    	}
		    }
		}
		.title{
			width: calc(50% - 20px);
			text-align: left;
			font-size: 40px;
			line-height: 56px;
			color: #007130;
			font-weight: bold;
			@include sp{
				width: 100%;
				margin-bottom: 20px;
				font-size: 35px;
				line-height: 45px;
			}
		}
		.no-press{
			text-align: left;
			font-weight: bold;
			font-size: 24px;
			line-height: 38px;
			color: #007130;
			margin-top: 30px;
		}
		.context{
			text-align: left;
			background: #fff;
			padding: 30px 50px 50px 0;
			width: calc(100% - 300px);
			z-index: 10;
			position: relative;
			@include sp{
				width: 100%;
				z-index: 0;
				padding: 0;
			}

		    .thum{
	    		position: relative;
	    		overflow: hidden;
	    		width: 100%;
	    		margin-bottom: 30px;
	    		.thum-img{
	    			width: 100%;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translateY(-50%) translateX(-50%);
	    		}
		    }
			.read{
				margin-bottom: 30px;
				@include sp{
					margin-bottom: 20px;
				}
			}
			.table-cap{
				font-weight: bold;
				font-size: 20px;
				line-height: 35px;
				margin-bottom: 10px;
			}
			.table-cap-sub{
				margin-bottom: 20px;
			}
			.tb01{
				border-collapse: collapse;
				font-size: 16px;
				text-align: left;
				border-top: 2px solid #007130;
				tr{
					border-bottom: 1px solid #007130;
					th{
						font-weight: bold;
						width: 160px;
						color: #007130;
						@include sp{
							display: block;
							width: 100%;
							padding: 20px 0 0 0!important;
						}
					}
					td{
						width: calc(100% - 160px);
						@include sp{
							display: block;
							width: 100%;
							padding: 10px 0 20px 0!important;
						}
					}
					th,td{
						vertical-align: top;
						padding: 20px 20px 20px 0;
					}
				}
			}
		}
	}
	.mass-conts{
		max-width: 760px;
		width: 100%;
		margin: 0 auto;
		.main-img{
			margin-bottom: 50px;
		}
		.txt{
			// color: #007130;
			text-align: left;
			margin-bottom: 80px;
			.read-ttl{
				color: #007130;
				font-weight: bold;
				margin-bottom: 20px;
				font-size: 24px;
			}
			.main-read{
				font-weight: bold;
				text-align: center;
				font-size: 24px;
				line-height: 38px;
				margin-bottom: 15px;
			}
		}
	}
	.inv-conts{
		max-width: 760px;
		width: 100%;
		margin: 0 auto 120px auto;
		.inv-list{
			margin-bottom: 80px;
			@include sp{
				margin-bottom: 60px;
			}
			li{
				margin-bottom: 40px;
				&:last-child{ margin-bottom: 0; }
				a{
					transition: 0.4s;
					color: #007130;
					text-align: left;
					display: -webkit-box;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;
					@include sp{
						display: block;
					}
					&:hover{
						opacity: 1;
						color: #02A848;
						.thum{
							border-color: #02A848;
							box-shadow: 3px 3px 0px 0px #02A848;
						}
						.contents .detail span::after{ border-color: #02A848; }
					}
					.thum{
						width: calc(50% - 20px);
						position: relative;
						overflow: hidden;
						margin-bottom: 25px;
						border-radius: 8px;
						border: 4px solid #007130;
						box-shadow: 3px 3px 0px 0px #007130;
						transition: 0.4s;
						@include sp{
							width: 100%;
						}
						.thum-img{
							width: auto;
							height: auto;
							min-width: 100%;
							min-height: 100%;
						    position: absolute;
						    top: 50%;
						    left: 50%;
						    transform: translateY(-50%) translateX(-50%);
						}
					}
					.contents{
						width: calc(50% - 20px);
						@include sp{
							width: 100%;
						}
						.job{ font-weight: bold; margin-bottom: 10px; }
						.name{
							font-size: 12px;
							line-height: 35px;
							span{
								font-weight: bold;
								font-size: 29px;
								padding-right: 10px;
								display: inline-block;
							}
						}
						.entr{
							margin-bottom: 20px;
						}
						.read{
							font-size: 12px;
							line-height: 20px;
						}
						.detail span{
							display: inline-block;
							position: relative;
							padding: 0 10px 0 0;
							font-weight: bold;
							&::after{
								content: " ";
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								margin: auto;
								display: block;
					    			width: 8px;
					    			height: 8px;
					    			transition: 0.4s;
					    			border-right: 2px solid #007130;
					    			border-bottom: 2px solid #007130;
					    			transform: rotate(-45deg);
					    			transition: 0.4s;
					    		}
						}

					}
				}
			}
		}
	}
	.inv-detail-conts{
		max-width: 760px;
		width: 100%;
		margin: 0 auto 120px auto;
		.thum{
			position: relative;
			padding-bottom: 35px;
			margin-bottom: 40px;
			@include sp{
				padding-bottom: 0;
			}
			.catch{
				display: inline-block;
				text-align: left;
				background: #007130;
				padding: 30px;
				color: #fff;
				font-weight: bold;
				font-size: 24px;
				line-height: 38px;
				position: absolute;
				bottom: 0;
				left: 0;
				@include sp{
					display: block;
					width: calc(100% - 40px);
					position: inherit;
					margin-top: -40px;
				}
			}
		}
		.wclmn{
		    display: -webkit-box;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    width: 100%;
		    text-align: left;
		    margin-bottom: 40px;
		    .thum-img{
		    	width: 280px;
		    	@include sp{ width: 100%; }
		    }
		    .contents{
		    	width: calc(100% - 300px);
		    	@include sp{
		    		width: 100%;
		    	}
		    	.job{ font-weight: bold; margin-bottom: 10px; }
		    	.name{
		    		font-size: 12px;
		    		line-height: 35px;
		    		color: #007130;
		    		span{
		    			font-weight: bold;
		    			font-size: 29px;
		    			padding-right: 10px;
		    			display: inline-block;
		    		}
		    	}
		    	.entr{
		    		margin-bottom: 20px;
		    	}
		    	.read{
		    		font-size: 12px;
		    		line-height: 20px;
		    		@include sp{ margin-bottom: 20px; }
		    	}
		    }
		}
		.wisy{
			text-align: left;
			margin-bottom: 100px;
			margin-bottom: 50px;
		}
        .sr-btn{
            text-align: left;
            padding-top: 40px;
            border-top: 2px solid #007130;
            margin-top: 80px;
            @include sp{
                width: 100%;
                margin-top: 50px;
            }
            a{
                display: inline-block;
                position: relative;
                font-weight: bold;
                padding-left: 20px;
                transition: 0.4s;
                color: #007130;
                &::after{
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    display: block;
                    width: 10px;
                    height: 10px;
                    transition: 0.4s;
                    border-right: 2px solid #007130;
                    border-bottom: 2px solid #007130;
                    transform: rotate(135deg);
                    transition: 0.4s;
                }
                &:hover{
                    color: #02A848;
                    opacity: 1;
                    &::after{
                        border-color: #02A848;
                    }
                }
            }
        }
	}
}


} // recruit